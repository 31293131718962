.sport-icon {
  display: inline-block;
  text-align: center;
  font-weight: normal;
  position: relative;
}

.sport-icon:before {
  font-family: Flaticon;
  font-style: normal;
  font-size: 20px;
  content: '\f10f';
  margin-left: 0;
}

.flag {
  margin-right: 5px;
}
