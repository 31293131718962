	/*
  	Flaticon icon font: Flaticon
  	Creation date: 23/02/2021 13:18
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-featured:before { content: "\f100"; }
.flaticon-soccer:before { content: "\f101"; }
.flaticon-tennis:before { content: "\f102"; }
.flaticon-basketball:before { content: "\f103"; }
.flaticon-football:before { content: "\f104"; }
.flaticon-rugby:before { content: "\f105"; }
.flaticon-aussie-rules:before { content: "\f106"; }
.flaticon-cricket:before { content: "\f107"; }
.flaticon-fighting:before { content: "\f108"; }
.flaticon-golf:before { content: "\f109"; }
.flaticon-baseball:before { content: "\f10a"; }
.flaticon-volleyball:before { content: "\f10b"; }
.flaticon-star:before { content: "\f10c"; }
.flaticon-hockey:before { content: "\f10d"; }
.flaticon-medal:before { content: "\f10e"; }
.flaticon-badminton:before { content: "\f10f"; }
.flaticon-curling:before { content: "\f110"; }
.flaticon-cycling:before { content: "\f111"; }
.flaticon-snooker:before { content: "\f112"; }
.flaticon-surfing:before { content: "\f113"; }
.flaticon-table-tennis:before { content: "\f114"; }
.flaticon-waterpolo:before { content: "\f115"; }
.flaticon-futsal:before { content: "\f116"; }
.flaticon-handball:before { content: "\f117"; }
.flaticon-bandy:before { content: "\f118"; }
.flaticon-basketball-alt:before { content: "\f119"; }
.flaticon-college-basketball:before { content: "\f11a"; }
.flaticon-darts:before { content: "\f11b"; }
.flaticon-e-sports:before { content: "\f11c"; }
.flaticon-horse-racing:before { content: "\f11d"; }
.flaticon-floorball:before { content: "\f11e"; }
.flaticon-squash:before { content: "\f11f"; }
.flaticon-field-hockey:before { content: "\f120"; }
.flaticon-beach-volleyball:before { content: "\f121"; }
.flaticon-motor-racing:before { content: "\f122"; }
.flaticon-simulations:before { content: "\f123"; }
.flaticon-sumo:before { content: "\f124"; }
.flaticon-politics:before { content: "\f125"; }
.flaticon-timer:before { content: "\f126"; }
.flaticon-entertainment:before { content: "\f127"; }