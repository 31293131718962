@charset "UTF-8";
/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
/**
  Variable file
  
  Goal
  This file is meant to include all the possible variable values
  currently in our platform.
  This file comes in handy when creating, defining a new skin.

  Variable Naming
  As for our classes we try to follow a BEM convention (check http://getbem.com/introduction/
  for further reference) we try to do the same for our variables.
  Variable names would constructed by: {{element-class}}__{{property}}--{{state}}

  EG: 
  - `.event-list`: // element class
    - --event-list__background-color: #aaaaaa
    - --event-list__background-color--hovered: #adadad
    - --event-list__background-color--active: #adadad
  - `.event-list__header`: // element class
    - --event-list__header__color: #aaaaaa
    - --event-list__header__color--hovered: #adadad
    - --event-list__header__color--active: #adadad
*/
@import url(./icons.css);
@import url(../fonts/flaticon.css);
:root {
  --alert__background-color--info: #d3e4f8;
  --alert__icon__color--info: #448be0;
  --alert__color--info: #464646;
  --alert__background-color--error: #ffcccc;
  --alert__icon__color--error: #b20000;
  --alert__color--error: #464646;
  --alert__background-color--warning: #f8eba5;
  --alert__icon__color--warning: #d9b911;
  --alert__color--warning: #464646;
  --body__font-family: 'Roboto', sans-serif;
  /*********** Multi event view - Event list ***********/
  --event-list--color: white;
  --event-list--border: none;
  --event-list--font-size: 0.875rem;
  --event-list--background-color: var(--background-color-lighten);
  --event-list__header--color: inherit;
  --event-list__footer--color: inherit;
  --event-list__header--background-color: var(--background-color-secondary);
  --event-list__header--border-top: 1px solid var(--background-color-tertiary);
  --event-list__footer--background-color: var(--background-color-secondary);
  --event-list__footer--border-top: 1px solid var(--background-color-tertiary);
  --event-list__footer__extra--color: var(--highlight-color-primary);
  --event-list__item-odd--background-color: var(--background-color-lighten);
  --event-list__item-even--background-color: var(--background-color-secondary);
  --event-list__item__details__teams__team--color: inherit;
  --event-list__item__details__date__live-period--color: inherit;
  --event-list__item__details__more--width: 35px;
  --event-scores--font-size: 0.625rem;
  --event-scores--color: inherit;
  --event-scores__team-period--background-color: var(--background-color-primary);
  --event-scores__team-period--border: 1px solid var(--background-color-secondary);
  --event-scores__team-period--border-radius: 3px;
  --event-scores__team-period-odd--border: var(--event-scores__team-period--border);
  --event-scores__team-period-even--border: var(--event-scores__team-period--border);
  /*********** BetSlip - Bet ***********/
  --bet-state__dark--tentatively-accepted: #448be0;
  --bet-state__light--tentatively-accepted: var(--alert__background-color--info);
  --bet__heading__background: var(--brand-color-primary);
  --bet__heading__color: var(--font-color-secondary);
  --bet__heading__margin-bottom: var(--bet__heading__margin);
  --bet__heading__margin: 0;
  --bet__heading__margin-left: var(--bet__heading__margin);
  --bet__heading__margin: 0;
  --bet__heading__margin-right: var(--bet__heading__margin);
  --bet__heading__margin: 0;
  --bet__heading__margin-top: var(--bet__heading__margin);
  --bet__heading__margin: 0;
  --bet__heading__padding-bottom: var(--bet__heading__padding);
  --bet__heading__padding: 0;
  --bet__heading__padding-left: var(--bet__heading__padding);
  --bet__heading__padding: 0;
  --bet__heading__padding-right: var(--bet__heading__padding);
  --bet__heading__padding: 0;
  --bet__heading__padding-top: var(--bet__heading__padding);
  --bet__heading__padding: 0;
  --bet__heading__padding-left: 10px;
  --bet__heading__padding-right: 10px;
  --bet__title__color: var(--bet__heading__color);
  --bet__title__color--hover: var(--bet__title__color);
  --bet__sport-icon__color: var(--bet__heading__color);
  --bet__close-icon__color: var(--bet__heading__color);
  --bet__title__font-size: 0.75rem;
  --bet__title__font-weight: normal;
  --bet__sport-icon__font-size: 16px;
  --bet__sport-icon__color--inverted: var(--font-color--secondary);
  --bet__sport-icon__margin-bottom: var(--bet__sport-icon__margin);
  --bet__sport-icon__margin: 0;
  --bet__sport-icon__margin-left: var(--bet__sport-icon__margin);
  --bet__sport-icon__margin: 0;
  --bet__sport-icon__margin-right: var(--bet__sport-icon__margin);
  --bet__sport-icon__margin: 0;
  --bet__sport-icon__margin-top: var(--bet__sport-icon__margin);
  --bet__sport-icon__margin: 0;
  --bet__sport-icon__margin-right: 10px;
  --bet__heading__background--tentatively-accepted: var(--bet-state__light--tentatively-accepted);
  --bet__heading__color--tentatively-accepted: #333;
  --bet__title__color--tentatively-accepted: var(--bet__heading__color--tentatively-accepted);
  --bet__sport-icon__color--tentatively-accepted: var(--bet__heading__color--tentatively-accepted);
  --bet__close-icon__color--tentatively-accepted: var(--bet__heading__color--tentatively-accepted);
  --bet-slip__notification__tentatively-accepted__color: var(--font-color-secondary);
  /*********** BetSlip - Bet - Alert ***********/
  --bet-alert__border-top--border-color--info: #448be0;
  --bet-alert__background-color--info: #cdcdcd;
  --bet-alert__icon__color--info: #448be0;
  --bet-alert__text__color--info: #464646;
  --bet-alert__border-top--border-color--error: #b20000;
  --bet-alert__background-color--error: #ffcccc;
  --bet-alert__icon__color--error: #b20000;
  --bet-alert__text__color--error: #464646;
  --bet-alert__border-top--border-color--warning: #d9b911;
  --bet-alert__background-color--warning: #f8eba5;
  --bet-alert__icon__color--warning: #d9b911;
  --bet-alert__text__color--warning: #464646;
  --bet-slip__premiums__border: 1px solid var(--bet-slip__premiums__border-color);
  --bet-slip__premiums__background: #fff;
  --bet-slip__premiums__opacity: 0.75;
  --bet-slip__premiums__border-color: transparent;
  --bet-slip__premiums__color: var(--font-color-secondary);
  --bet-slip__premiums__icon__color: #448be0;
  /*********** Free bets list ***********/
  --free-bets-list__margin-bottom: var(--free-bets-list__margin);
  --free-bets-list__margin-bottom: 0.625rem;
  --free-bets-list__margin-left: var(--free-bets-list__margin);
  --free-bets-list__margin-left: 1rem;
  --free-bets-list__margin-right: var(--free-bets-list__margin);
  --free-bets-list__margin-right: 1rem;
  --free-bets-list__margin-top: var(--free-bets-list__margin);
  --free-bets-list__margin-top: 0.625rem;
  --free-bets-list__header__margin-bottom: var(--free-bets-list__header__margin);
  --free-bets-list__header__margin-left: var(--free-bets-list__header__margin);
  --free-bets-list__header__margin-right: var(--free-bets-list__header__margin);
  --free-bets-list__header__margin-top: var(--free-bets-list__header__margin);
  --free-bets-list__header__padding-bottom: var(--free-bets-list__header__padding);
  --free-bets-list__header__padding-bottom: 0.5rem;
  --free-bets-list__header__padding-left: var(--free-bets-list__header__padding);
  --free-bets-list__header__padding-left: 0;
  --free-bets-list__header__padding-right: var(--free-bets-list__header__padding);
  --free-bets-list__header__padding-right: 0;
  --free-bets-list__header__padding-top: var(--free-bets-list__header__padding);
  --free-bets-list__header__padding-top: 0.5rem;
  --free-bets-list__close-button__background-color: var(--brand-color-primary);
  --free-bets-list__close-button__border-radius: 30px;
  --free-bets-list__close-button__color: var(--font-color-secondary);
  --free-bets-list__close-button__border: none;
  --free-bets-list__close-button__font-size: 0.85rem;
  --free-bet-item__separator__color: var(--background-color-primary);
  --free-bet-item__applicability: 0;
  --free-bet-item__border: none;
  --free-bet-item__footer__color: var(--font-color-secondary);
  --free-bet-item__margin-bottom: var(--free-bet-item__margin);
  --free-bet-item__margin: 0;
  --free-bet-item__margin-left: var(--free-bet-item__margin);
  --free-bet-item__margin: 0;
  --free-bet-item__margin-right: var(--free-bet-item__margin);
  --free-bet-item__margin: 0;
  --free-bet-item__margin-top: var(--free-bet-item__margin);
  --free-bet-item__margin: 0;
  --free-bet-item__footer__padding-bottom: var(--free-bet-item__footer__padding);
  --free-bet-item__footer__padding: 0;
  --free-bet-item__footer__padding-left: var(--free-bet-item__footer__padding);
  --free-bet-item__footer__padding: 0;
  --free-bet-item__footer__padding-right: var(--free-bet-item__footer__padding);
  --free-bet-item__footer__padding: 0;
  --free-bet-item__footer__padding-top: var(--free-bet-item__footer__padding);
  --free-bet-item__footer__padding: 0;
  --free-bet-item__footer__margin-bottom: var(--free-bet-item__footer__margin);
  --free-bet-item__footer__margin: 0;
  --free-bet-item__footer__margin-left: var(--free-bet-item__footer__margin);
  --free-bet-item__footer__margin: 0;
  --free-bet-item__footer__margin-right: var(--free-bet-item__footer__margin);
  --free-bet-item__footer__margin: 0;
  --free-bet-item__footer__margin-top: var(--free-bet-item__footer__margin);
  --free-bet-item__footer__margin: 0;
  --free-bet-item__footer__font-size: 0.85rem;
  --free-bet-item__header__padding-bottom: var(--free-bet-item__header__padding);
  --free-bet-item__header__padding: 0;
  --free-bet-item__header__padding-left: var(--free-bet-item__header__padding);
  --free-bet-item__header__padding: 0;
  --free-bet-item__header__padding-right: var(--free-bet-item__header__padding);
  --free-bet-item__header__padding: 0;
  --free-bet-item__header__padding-top: var(--free-bet-item__header__padding);
  --free-bet-item__header__padding: 0;
  --free-bet-item__header__margin-bottom: var(--free-bet-item__header__margin);
  --free-bet-item__header__margin: 0;
  --free-bet-item__header__margin-left: var(--free-bet-item__header__margin);
  --free-bet-item__header__margin: 0;
  --free-bet-item__header__margin-right: var(--free-bet-item__header__margin);
  --free-bet-item__header__margin: 0;
  --free-bet-item__header__margin-top: var(--free-bet-item__header__margin);
  --free-bet-item__header__margin: 0;
  --free-bet-item__header__color: var(--font-color-primary);
  --free-bet-item__header__font-size: 1rem;
  --free-bet-item__expiration-date__padding-bottom: var(--free-bet-item__expiration-date__padding);
  --free-bet-item__expiration-date__padding: 0;
  --free-bet-item__expiration-date__padding-left: var(--free-bet-item__expiration-date__padding);
  --free-bet-item__expiration-date__padding: 0;
  --free-bet-item__expiration-date__padding-right: var(--free-bet-item__expiration-date__padding);
  --free-bet-item__expiration-date__padding: 0;
  --free-bet-item__expiration-date__padding-top: var(--free-bet-item__expiration-date__padding);
  --free-bet-item__expiration-date__padding: 0;
  --free-bet-item__expiration-date__margin-bottom: var(--free-bet-item__expiration-date__margin);
  --free-bet-item__expiration-date__margin: 0;
  --free-bet-item__expiration-date__margin-left: var(--free-bet-item__expiration-date__margin);
  --free-bet-item__expiration-date__margin: 0;
  --free-bet-item__expiration-date__margin-right: var(--free-bet-item__expiration-date__margin);
  --free-bet-item__expiration-date__margin: 0;
  --free-bet-item__expiration-date__margin-top: var(--free-bet-item__expiration-date__margin);
  --free-bet-item__expiration-date__margin: 0;
  --free-bet-item__expiration-date__font-size: var(--free-bet-item__footer__font-size);
  --free-bet-item__expiration-date__color: rgba(255, 255, 255, 0.8);
  --free-bet-item__expander__icon__padding-bottom: var(--free-bet-item__expander__icon__padding);
  --free-bet-item__expander__icon__padding-left: var(--free-bet-item__expander__icon__padding);
  --free-bet-item__expander__icon__padding-right: var(--free-bet-item__expander__icon__padding);
  --free-bet-item__expander__icon__padding-top: var(--free-bet-item__expander__icon__padding);
  --free-bet-item__expander__icon__margin-bottom: var(--free-bet-item__expander__icon__margin);
  --free-bet-item__expander__icon__margin-bottom: 0;
  --free-bet-item__expander__icon__margin-left: var(--free-bet-item__expander__icon__margin);
  --free-bet-item__expander__icon__margin-left: 0;
  --free-bet-item__expander__icon__margin-right: var(--free-bet-item__expander__icon__margin);
  --free-bet-item__expander__icon__margin-right: 5px;
  --free-bet-item__expander__icon__margin-top: var(--free-bet-item__expander__icon__margin);
  --free-bet-item__expander__icon__margin-top: 0;
  --free-bet-item__name__icon__padding-bottom: var(--free-bet-item__name__icon__padding);
  --free-bet-item__name__icon__padding-left: var(--free-bet-item__name__icon__padding);
  --free-bet-item__name__icon__padding-right: var(--free-bet-item__name__icon__padding);
  --free-bet-item__name__icon__padding-top: var(--free-bet-item__name__icon__padding);
  --free-bet-item__name__icon__margin-bottom: var(--free-bet-item__name__icon__margin);
  --free-bet-item__name__icon__margin-bottom: 0;
  --free-bet-item__name__icon__margin-left: var(--free-bet-item__name__icon__margin);
  --free-bet-item__name__icon__margin-left: 0;
  --free-bet-item__name__icon__margin-right: var(--free-bet-item__name__icon__margin);
  --free-bet-item__name__icon__margin-right: 5px;
  --free-bet-item__name__icon__margin-top: var(--free-bet-item__name__icon__margin);
  --free-bet-item__name__icon__margin-top: 0;
  --free-bet-item__name__icon__display: inline-block;
  --free-bet-item__name__text-transform: unset;
  --free-bet-item__name__color: var(--free-bet-item__header__color);
  --free-bet-item__name__font-size: var(--free-bet-item__header__font-size);
  --free-bet-item__amount__font-weight: bold;
  --free-bet-item__amount__color: var(--free-bet-item__header__color);
  --free-bet-item__amount__font-size: var(--free-bet-item__header__font-size);
  --free-bet-item__applicability__padding-bottom: var(--free-bet-item__applicability__padding);
  --free-bet-item__applicability__padding: 0;
  --free-bet-item__applicability__padding-left: var(--free-bet-item__applicability__padding);
  --free-bet-item__applicability__padding: 0;
  --free-bet-item__applicability__padding-right: var(--free-bet-item__applicability__padding);
  --free-bet-item__applicability__padding: 0;
  --free-bet-item__applicability__padding-top: var(--free-bet-item__applicability__padding);
  --free-bet-item__applicability__padding: 0;
  --free-bet-item__applicability__margin-bottom: var(--free-bet-item__applicability__margin);
  --free-bet-item__applicability__margin: 0;
  --free-bet-item__applicability__margin-left: var(--free-bet-item__applicability__margin);
  --free-bet-item__applicability__margin: 0;
  --free-bet-item__applicability__margin-right: var(--free-bet-item__applicability__margin);
  --free-bet-item__applicability__margin: 0;
  --free-bet-item__applicability__margin-top: var(--free-bet-item__applicability__margin);
  --free-bet-item__applicability__margin: 0;
  --free-bet-item__applicability__font-size: 0.85rem;
  --free-bet-item__applicability__color: var(--font-color-);
  --free-bet-item__applicability-item__font-size: 0.85rem;
  --free-bet-item__applicability-item__color: rgb(235, 235, 235);
  --free-bet-item__applicability-item__color--hover: rgb(255, 255, 255);
  --free-bet-item__applicability__header__font-size: inherit;
  --free-bet-item__applicability__header__color: var(--font-color-lighten);
  --free-bets__expander__margin-bottom: var(--free-bets__expander__margin);
  --free-bets__expander__margin: 0;
  --free-bets__expander__margin-left: var(--free-bets__expander__margin);
  --free-bets__expander__margin: 0;
  --free-bets__expander__margin-right: var(--free-bets__expander__margin);
  --free-bets__expander__margin: 0;
  --free-bets__expander__margin-top: var(--free-bets__expander__margin);
  --free-bets__expander__margin: 0;
  --free-bets__expander__padding-bottom: var(--free-bets__expander__padding);
  --free-bets__expander__padding: 20px;
  --free-bets__expander__padding-left: var(--free-bets__expander__padding);
  --free-bets__expander__padding: 20px;
  --free-bets__expander__padding-right: var(--free-bets__expander__padding);
  --free-bets__expander__padding: 20px;
  --free-bets__expander__padding-top: var(--free-bets__expander__padding);
  --free-bets__expander__padding: 20px;
  --free-bets__expander__background-color: var(--background-color-darken);
  --free-bets__expander__font-size: 0.85rem;
  --free-bets__expander__border: 1px solid var(--background-color-darken);
  --free-bets__expander__count__font-size: var(--free-bet__expander__font-size);
  --free-bets__expander__count__highlighted__margin-bottom: var(--free-bets__expander__count__highlighted__margin);
  --free-bets__expander__count__highlighted__margin-bottom: 0;
  --free-bets__expander__count__highlighted__margin-left: var(--free-bets__expander__count__highlighted__margin);
  --free-bets__expander__count__highlighted__margin-left: 0.25rem;
  --free-bets__expander__count__highlighted__margin-right: var(--free-bets__expander__count__highlighted__margin);
  --free-bets__expander__count__highlighted__margin-right: 0;
  --free-bets__expander__count__highlighted__margin-top: var(--free-bets__expander__count__highlighted__margin);
  --free-bets__expander__count__highlighted__margin-top: 0;
  --free-bets__expander__count__highlighted__font-size: 1.25em;
  --free-bets__expander__count__highlighted__color: var(--brand-color-primary);
  --free-bets__expander__count__highlighted__font-weight: bold;
  --free-bets__expander__button__color: var(--background-color-primary);
  --free-bets__expander__button__background-color: var(--brand-color-primary);
  --free-bets__expander__button__border-radius: 3px;
  --free-bets__expander__button__font-weight: unset;
  --free-bets__expander__button__font-size: 0.85rem;
  --free-bets__expander__button__border: none;
  --free-bets__expander__button__border-radius--expanded: 30px;
  --free-bets__expander__button__padding-bottom: var(--free-bets__expander__button__padding);
  --free-bets__expander__button__padding-bottom: 5px;
  --free-bets__expander__button__padding-left: var(--free-bets__expander__button__padding);
  --free-bets__expander__button__padding-left: 10px;
  --free-bets__expander__button__padding-right: var(--free-bets__expander__button__padding);
  --free-bets__expander__button__padding-right: 10px;
  --free-bets__expander__button__padding-top: var(--free-bets__expander__button__padding);
  --free-bets__expander__button__padding-top: 5px;
  --free-bets__container__background-color: var(--background-color-darken);
  --free-bet-selector__margin-bottom: var(--free-bet-selector__margin);
  --free-bet-selector__margin-bottom: 5px;
  --free-bet-selector__margin-left: var(--free-bet-selector__margin);
  --free-bet-selector__margin-left: 0;
  --free-bet-selector__margin-right: var(--free-bet-selector__margin);
  --free-bet-selector__margin-right: 0;
  --free-bet-selector__margin-top: var(--free-bet-selector__margin);
  --free-bet-selector__margin-top: 5px;
  --free-bet-selector__item__border-bottom: var(--free-bet-selector__item__border);
  --free-bet-selector__item__border: 1px solid var(--background-color-secondary);
  --free-bet-selector__item__border-left: var(--free-bet-selector__item__border);
  --free-bet-selector__item__border: 1px solid var(--background-color-secondary);
  --free-bet-selector__item__border-right: var(--free-bet-selector__item__border);
  --free-bet-selector__item__border: 1px solid var(--background-color-secondary);
  --free-bet-selector__item__border-top: var(--free-bet-selector__item__border);
  --free-bet-selector__item__border: 1px solid var(--background-color-secondary);
  --free-bet-selector__item__padding-bottom: var(--free-bet-selector__item__padding);
  --free-bet-selector__item__padding: 5px;
  --free-bet-selector__item__padding-left: var(--free-bet-selector__item__padding);
  --free-bet-selector__item__padding: 5px;
  --free-bet-selector__item__padding-right: var(--free-bet-selector__item__padding);
  --free-bet-selector__item__padding: 5px;
  --free-bet-selector__item__padding-top: var(--free-bet-selector__item__padding);
  --free-bet-selector__item__padding: 5px;
  --free-bet-selector__item__border-radius: 0;
  --free-bet-selector__item__font-size: 1rem;
  --free-bet-selector__item__amount__color: var(--brand-color-primary);
  --free-bet-selector__item__label__color: var(--font-color-primary);
  --free-bet-selector__item__amount__font-size: 0.9em;
  --free-bet-selector__item__label__font-size: 0.75em;
  --free-bet-selector__item__label__color--selected: var(--brand-color-primary);
  --free-bet-selector__item__border-bottom--selected: var(--free-bet-selector__item__border--selected);
  --free-bet-selector__item__border--selected: 1px solid var(--background-color-tertiary);
  --free-bet-selector__item__border-left--selected: var(--free-bet-selector__item__border--selected);
  --free-bet-selector__item__border--selected: 1px solid var(--background-color-tertiary);
  --free-bet-selector__item__border-right--selected: var(--free-bet-selector__item__border--selected);
  --free-bet-selector__item__border--selected: 1px solid var(--background-color-tertiary);
  --free-bet-selector__item__border-top--selected: var(--free-bet-selector__item__border--selected);
  --free-bet-selector__item__border--selected: 1px solid var(--background-color-tertiary);
  --free-bet-selector__item__background-color--selected: var(--background-color-tertiary);
  --free-bet-selector__item__amount__color--selected: var(--free-bet-selector__item__amount__color);
  --free-bet-selector__item__label__color--selected: var(--free-bet-selector__item__label__color);
  --about__container--padding: 0.5rem 1rem;
  --about__container__extra--font-size: 0.75rem;
  --parlays-summarize__padding-bottom: var(--parlays-summarize__padding);
  --parlays-summarize__padding: 0.75rem;
  --parlays-summarize__padding-left: var(--parlays-summarize__padding);
  --parlays-summarize__padding: 0.75rem;
  --parlays-summarize__padding-right: var(--parlays-summarize__padding);
  --parlays-summarize__padding: 0.75rem;
  --parlays-summarize__padding-top: var(--parlays-summarize__padding);
  --parlays-summarize__padding: 0.75rem;
  --parlays-summarize__margin-bottom: var(--parlays-summarize__margin);
  --parlays-summarize__margin-bottom: 0.75rem;
  --parlays-summarize__margin-left: var(--parlays-summarize__margin);
  --parlays-summarize__margin-left: 0;
  --parlays-summarize__margin-right: var(--parlays-summarize__margin);
  --parlays-summarize__margin-right: 0;
  --parlays-summarize__margin-top: var(--parlays-summarize__margin);
  --parlays-summarize__margin-top: 0;
  --parlays-summarize__background-color: #202020;
  --parlays-summarize__background-color--with-premiums: #202020;
  --parlays-summarize__border: none;
  --parlays-summarize__color: var(--font-color-light);
  --parlays-summarize__font-size: 0.9rem;
  --parlays-summarize__border-radius: 3px;
  --parlays-summarize__top__font-size: 1em;
  --parlays-summarize__top__label__margin-bottom: var(--parlays-summarize__top__label__margin);
  --parlays-summarize__top__label__margin: 0;
  --parlays-summarize__top__label__margin-left: var(--parlays-summarize__top__label__margin);
  --parlays-summarize__top__label__margin: 0;
  --parlays-summarize__top__label__margin-right: var(--parlays-summarize__top__label__margin);
  --parlays-summarize__top__label__margin: 0;
  --parlays-summarize__top__label__margin-top: var(--parlays-summarize__top__label__margin);
  --parlays-summarize__top__label__margin: 0;
  --parlays-summarize__top__odds__margin-bottom: var(--parlays-summarize__top__odds__margin);
  --parlays-summarize__top__odds__margin: 0;
  --parlays-summarize__top__odds__margin-left: var(--parlays-summarize__top__odds__margin);
  --parlays-summarize__top__odds__margin: 0;
  --parlays-summarize__top__odds__margin-right: var(--parlays-summarize__top__odds__margin);
  --parlays-summarize__top__odds__margin: 0;
  --parlays-summarize__top__odds__margin-top: var(--parlays-summarize__top__odds__margin);
  --parlays-summarize__top__odds__margin: 0;
  --parlays-summarize__top__label__font-size: 1em;
  --parlays-summarize__top__odds__font-size: 1em;
  --parlays-summarize__top__odds__font-weight: normal;
  --parlays-summarize__top__odds__icon__background-color: var(--brand-color-primary);
  --parlays-summarize__top__odds__icon__color: var(--background-color-primary);
  --parlays-summarize__top__odds__number__color: var(--brand-color-primary);
  --parlays-summarize__top__odds__number__font-weight: normal;
  --parlays-summarize__top__odds__number__color--with-premiums: var(--brand-color-primary);
  --parlays-summarize__top__odds__number__font-weight--with-premiums: normal;
  --parlays-summarize__bottom__margin-bottom: var(--parlays-summarize__bottom__margin);
  --parlays-summarize__bottom__margin-bottom: 0;
  --parlays-summarize__bottom__margin-left: var(--parlays-summarize__bottom__margin);
  --parlays-summarize__bottom__margin-left: 0;
  --parlays-summarize__bottom__margin-right: var(--parlays-summarize__bottom__margin);
  --parlays-summarize__bottom__margin-right: 0;
  --parlays-summarize__bottom__margin-top: var(--parlays-summarize__bottom__margin);
  --parlays-summarize__bottom__margin-top: 0.45rem;
  --parlays-summarize__bottom__text__margin-bottom: var(--parlays-summarize__bottom__text__margin);
  --parlays-summarize__bottom__text__margin: 0;
  --parlays-summarize__bottom__text__margin-left: var(--parlays-summarize__bottom__text__margin);
  --parlays-summarize__bottom__text__margin: 0;
  --parlays-summarize__bottom__text__margin-right: var(--parlays-summarize__bottom__text__margin);
  --parlays-summarize__bottom__text__margin: 0;
  --parlays-summarize__bottom__text__margin-top: var(--parlays-summarize__bottom__text__margin);
  --parlays-summarize__bottom__text__margin: 0;
  --parlays-summarize__bottom__font-size: 0.9em;
  --parlays-summarize__bottom__color: var(--font-color-primary);
  --parlays-summarize__bottom__icon__background-color: var(--brand-color-primary);
  --parlays-summarize__bottom__icon__color: var(--background-color-primary);
  --parlays-summarize__bottom__text__color--highlighted: var(--brand-color-primary);
  --round-robins__toggler__border-radius: 0px;
  --round-robins__toggler__flex-direction: row;
  --round-robins__toggler__justify-content: space-between;
  --round-robins__toggler__i__margin-right: 0px;
  --round-robins__toggler__border: none;
  --round-robins__toggler__font-size: 0.875rem;
  --round-robins__toggler__text-transform: uppercase;
  --round-robins__toggler__background-color: var(--brand-color-primary);
  --round-robins__toggler__color: var(--font-color-secondary);
  --round-robins__toggler__i__color: var(--background-color-primary);
  --round-robins__sizes__size__label__color: var(--font-color-primary);
  --round-robins__sizes__size__label__font-size: 0.85rem;
  --round-robins__sizes__size__label__odds__color: var(--highlight-color-primary);
  --round-robins__sizes__size__label__odds__text-decoration: none;
  --round-robins__sizes__size__label__odds__font-weight: normal;
  --round-robins__sizes__size__label__odds__color--discounted: var(--font-color-primary);
  --round-robins__sizes__size__label__odds__text-decoration--discounted: line-through;
  --round-robins__sizes__size__label__odds__font-weight--discounted: normal;
  --round-robins__sizes__size__label__odds__color--premium: var(--highlight-color-primary);
  --round-robins__sizes__size__label__odds__text-decoration--premium: none;
  --round-robins__sizes__size__label__odds__font-weight--premium: normal;
  --round-robins-summarize__padding-bottom: var(--round-robins-summarize__padding);
  --round-robins-summarize__padding: 0.75rem;
  --round-robins-summarize__padding-left: var(--round-robins-summarize__padding);
  --round-robins-summarize__padding: 0.75rem;
  --round-robins-summarize__padding-right: var(--round-robins-summarize__padding);
  --round-robins-summarize__padding: 0.75rem;
  --round-robins-summarize__padding-top: var(--round-robins-summarize__padding);
  --round-robins-summarize__padding: 0.75rem;
  --round-robins-summarize__margin-bottom: var(--round-robins-summarize__margin);
  --round-robins-summarize__margin-bottom: 0.75rem;
  --round-robins-summarize__margin-left: var(--round-robins-summarize__margin);
  --round-robins-summarize__margin-left: 0;
  --round-robins-summarize__margin-right: var(--round-robins-summarize__margin);
  --round-robins-summarize__margin-right: 0;
  --round-robins-summarize__margin-top: var(--round-robins-summarize__margin);
  --round-robins-summarize__margin-top: 0;
  --round-robins-summarize__background-color: #202020;
  --round-robins-summarize__border: none;
  --round-robins-summarize__color: var(--font-color-primary);
  --round-robins-summarize__font-size: 0.9rem;
  --round-robins-summarize__border-radius: 3px;
  --round-robins-summarize__box-shadow: none;
  --round-robins-summarize__line__padding-bottom: var(--round-robins-summarize__line__padding);
  --round-robins-summarize__line__padding-bottom: 0.15rem;
  --round-robins-summarize__line__padding-left: var(--round-robins-summarize__line__padding);
  --round-robins-summarize__line__padding-right: var(--round-robins-summarize__line__padding);
  --round-robins-summarize__line__padding-top: var(--round-robins-summarize__line__padding);
  --round-robins-summarize__line__padding-top: 0.15rem;
  --round-robins-summarize__line__color: var(--font-color-primary);
  --round-robins-summarize__line__boost__icon__color: var(--background-color-primary);
  --round-robins-summarize__line__boost__icon__background-color: var(--brand-color-primary);
  --round-robins-summarize__line__boost__color: var(--highlight-color-primary);
  --round-robins-summarize__line__boost__font-weight: var(--highlight-color-primary);
  --round-robins-summarize__icon__color: var(--background-color-primary);
  --round-robins-summarize__icon__background-color: var(--brand-color-primary);
  --round-robins-summarize__icon__font-size: var(--background-color-primary);
  --round-robins-summarize__icon__size: 1rem;
  --round-robins-summarize__icon__border-radius: 1rem;
  --round-robins-summarize__icon__height: var(--round-robins-summarize__icon__size);
  --round-robins-summarize__icon__width: var(--round-robins-summarize__icon__size);
  --round-robins-summarize__icon__font-size: 0.65rem;
  --round-robins-summarize__icon__padding-bottom: var(--round-robins-summarize__icon__padding);
  --round-robins-summarize__icon__padding-left: var(--round-robins-summarize__icon__padding);
  --round-robins-summarize__icon__padding-right: var(--round-robins-summarize__icon__padding);
  --round-robins-summarize__icon__padding-top: var(--round-robins-summarize__icon__padding);
  --round-robins-summarize__icon__margin-bottom: var(--round-robins-summarize__icon__margin);
  --round-robins-summarize__icon__margin-bottom: 0;
  --round-robins-summarize__icon__margin-left: var(--round-robins-summarize__icon__margin);
  --round-robins-summarize__icon__margin-left: 0;
  --round-robins-summarize__icon__margin-right: var(--round-robins-summarize__icon__margin);
  --round-robins-summarize__icon__margin-right: 0.25rem;
  --round-robins-summarize__icon__margin-top: var(--round-robins-summarize__icon__margin);
  --round-robins-summarize__icon__margin-top: 0;
  --round-robins-summarize__line__explanation__color: var(--font-color-primary);
  --round-robins-summarize__line__explanation__emphasis__color: var(--brand-color-primary);
  --round-robins-summarize__line__explanation__emphasis__font-weight: bold;
  --round-robins-summarize__line__boost-to-win__color: var(--font-color-primary);
  --round-robins-summarize__line__boost-return__color: var(--brand-color-primary);
  --round-robins-summarize__line__boost-return__font-weight: bold;
  --round-robins-summarize__total-return__padding-bottom: var(--round-robins-summarize__total-return__padding);
  --round-robins-summarize__total-return__padding-bottom: 0.15rem;
  --round-robins-summarize__total-return__padding-left: var(--round-robins-summarize__total-return__padding);
  --round-robins-summarize__total-return__padding-left: 0;
  --round-robins-summarize__total-return__padding-right: var(--round-robins-summarize__total-return__padding);
  --round-robins-summarize__total-return__padding-right: 0;
  --round-robins-summarize__total-return__padding-top: var(--round-robins-summarize__total-return__padding);
  --round-robins-summarize__total-return__padding-top: 0.15rem;
  --round-robins-summarize__total-return__margin-bottom: var(--round-robins-summarize__total-return__margin);
  --round-robins-summarize__total-return__margin-left: var(--round-robins-summarize__total-return__margin);
  --round-robins-summarize__total-return__margin-right: var(--round-robins-summarize__total-return__margin);
  --round-robins-summarize__total-return__margin-top: var(--round-robins-summarize__total-return__margin);
  --round-robins-summarize__total-return__padding-bottom--with-premiums: var(--round-robins-summarize__total-return__padding--with-premiums);
  --round-robins-summarize__total-return__padding-bottom--with-premiums: 0;
  --round-robins-summarize__total-return__padding-left--with-premiums: var(--round-robins-summarize__total-return__padding--with-premiums);
  --round-robins-summarize__total-return__padding-left--with-premiums: 0;
  --round-robins-summarize__total-return__padding-right--with-premiums: var(--round-robins-summarize__total-return__padding--with-premiums);
  --round-robins-summarize__total-return__padding-right--with-premiums: 0;
  --round-robins-summarize__total-return__padding-top--with-premiums: var(--round-robins-summarize__total-return__padding--with-premiums);
  --round-robins-summarize__total-return__padding-top--with-premiums: 0.35rem;
  --round-robins-summarize__total-return__margin-bottom--with-premiums: var(--round-robins-summarize__total-return__margin--with-premiums);
  --round-robins-summarize__total-return__margin-bottom--with-premiums: 0.15rem;
  --round-robins-summarize__total-return__margin-left--with-premiums: var(--round-robins-summarize__total-return__margin--with-premiums);
  --round-robins-summarize__total-return__margin-left--with-premiums: 0;
  --round-robins-summarize__total-return__margin-right--with-premiums: var(--round-robins-summarize__total-return__margin--with-premiums);
  --round-robins-summarize__total-return__margin-right--with-premiums: 0;
  --round-robins-summarize__total-return__margin-top--with-premiums: var(--round-robins-summarize__total-return__margin--with-premiums);
  --round-robins-summarize__total-return__margin-top--with-premiums: 0.35rem;
  --round-robins-summarize__total-return__border-bottom--with-premiums: var(--round-robins-summarize__total-return__border--with-premiums);
  --round-robins-summarize__total-return__border-bottom--with-premiums: 0;
  --round-robins-summarize__total-return__border-left--with-premiums: var(--round-robins-summarize__total-return__border--with-premiums);
  --round-robins-summarize__total-return__border-left--with-premiums: 0;
  --round-robins-summarize__total-return__border-right--with-premiums: var(--round-robins-summarize__total-return__border--with-premiums);
  --round-robins-summarize__total-return__border-right--with-premiums: 0;
  --round-robins-summarize__total-return__border-top--with-premiums: var(--round-robins-summarize__total-return__border--with-premiums);
  --round-robins-summarize__total-return__border-top--with-premiums: 1px solid var(--font-color-secondary);
  --round-robins-summarize__line__font-size: 0.85rem;
  --round-robins-summarize__total-return__font-size: 0.85rem;
  --round-robins-summarize__total-return__color: var(--brand-color-primary);
  --round-robins-summarize__total-return__explanation__font-weight: bold;
  --round-robins-summarize__total-return__value__font-weight: bold;
  --round-robins-summarize__total-return__value__color: var(--brand-color-primary);
  --round-robins-summarize__total-return__explanation__color: inherit;
  --round-robins-summarize__total-return__value__font-size: inherit;
  --history-bet-group__font-size: 0.85rem;
  --history-bet-group__header__original-odds__color: var(--brand-color-primary);
  --history-bet-group__header__original-odds__font-weight: normal;
  --history-bet-group__header__original-odds__color--with-premiums: var(--font-color-tertiary);
  --history-bet-group__header__premium-odds__color: var(--brand-color-primary);
  --history-bet-group__header__premium-odds__font-weight: bold;
  --history-bet-group__header__premium-pctg__font-weight: bold;
  --history-bet-group__header__premium-pctg__color: var(--brand-color-primary);
  --history-bet-group__header__premium-pctg__icon__color: var(--background-color-primary);
  --history-bet-group__header__premium-pctg__icon__background-color: var(--brand-color-primary);
  --history-bet-group__header__premium-pctg__icon__size: 1rem;
  --history-bet-group__header__premium-pctg__icon__border-radius: 1rem;
  --history-bet-group__header__premium-pctg__icon__height: var(--round-robins-summarize__icon__size);
  --history-bet-group__header__premium-pctg__icon__width: var(--round-robins-summarize__icon__size);
  --history-bet-group__header__premium-pctg__icon__font-size: 0.65rem;
  --history-bet-group__header__to-win__color: var(--brand-color-primary);
  --history-bet-group__header__to-win__font-weight: normal;
  --history-bet-group__size-breakdown__selector__size__padding-bottom: var(--history-bet-group__size-breakdown__selector__size__padding);
  --history-bet-group__size-breakdown__selector__size__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size__padding-left: var(--history-bet-group__size-breakdown__selector__size__padding);
  --history-bet-group__size-breakdown__selector__size__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size__padding-right: var(--history-bet-group__size-breakdown__selector__size__padding);
  --history-bet-group__size-breakdown__selector__size__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size__padding-top: var(--history-bet-group__size-breakdown__selector__size__padding);
  --history-bet-group__size-breakdown__selector__size__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size__background-color: var(--background-color-primary);
  --history-bet-group__size-breakdown__selector__size__background-color--selected: var(--background-color-secondary);
  --history-bet-group__size-breakdown__selector__size__color: var(--font-color-primary);
  --history-bet-group__size-breakdown__selector__size__color--selected: var(--brand-color-primary);
  --history-bet-group__size-breakdown__selector__size-detail__padding-bottom: var(--history-bet-group__size-breakdown__selector__size-detail__padding);
  --history-bet-group__size-breakdown__selector__size-detail__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size-detail__padding-left: var(--history-bet-group__size-breakdown__selector__size-detail__padding);
  --history-bet-group__size-breakdown__selector__size-detail__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size-detail__padding-right: var(--history-bet-group__size-breakdown__selector__size-detail__padding);
  --history-bet-group__size-breakdown__selector__size-detail__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size-detail__padding-top: var(--history-bet-group__size-breakdown__selector__size-detail__padding);
  --history-bet-group__size-breakdown__selector__size-detail__padding: 0.5rem;
  --history-bet-group__size-breakdown__selector__size-detail__background-color: var(--background-color-secondary);
  --history-bet-group__size-breakdown__selector__size-detail__color: var(--font-color-primary);
  --history-bet-group__size-breakdown__selector__size-detail__bet__background-color--odd: var(
    --background-color-primary
  );
  /*Process Actions*/
  --process-actions__flex-direction: column;
  --process-actions__btn__border-radius: 3px;
  --process-actions__btn__font-size: 0.75rem;
  --process-actions__btn__height: 2.5rem;
  --process-actions__btn__text-transform: uppercase;
  --process-actions__btn__transition: opacity 0.25s;
  --process-actions__btn__border: none;
  --process-actions__btn__opacity--hover: 0.8;
  --process-actions__btn-primary__background-color: var(--brand-color-primary);
  --process-actions__btn-primary__color: var(--font-color-secondary);
  --process-actions__btn-primary__icon__color: var(--font-color-secondary);
  --process-actions__btn-secondary__background-color: var(--theme-color-danger);
  --process-actions__btn-secondary__color: black;
  --process-actions__btn-secondary__icon__display: inline-block;
  --process-actions__btn-secondary__icon__font-size: 12px;
  --process-actions__btn-secondary__icon__color: var(--font-color-primary);
  --process-actions__btn__margin-bottom: var(--process-actions__btn__margin);
  --process-actions__btn__margin: 0;
  --process-actions__btn__margin-left: var(--process-actions__btn__margin);
  --process-actions__btn__margin: 0;
  --process-actions__btn__margin-right: var(--process-actions__btn__margin);
  --process-actions__btn__margin: 0;
  --process-actions__btn__margin-top: var(--process-actions__btn__margin);
  --process-actions__btn__margin: 0;
  --process-actions__btn__icon__margin-bottom: var(--process-actions__btn__icon__margin);
  --process-actions__btn__icon__margin-bottom: 0;
  --process-actions__btn__icon__margin-left: var(--process-actions__btn__icon__margin);
  --process-actions__btn__icon__margin-left: 0.5rem;
  --process-actions__btn__icon__margin-right: var(--process-actions__btn__icon__margin);
  --process-actions__btn__icon__margin-right: 0;
  --process-actions__btn__icon__margin-top: var(--process-actions__btn__icon__margin);
  --process-actions__btn__icon__margin-top: 0;
  --main-menu__header-row__height: 42px;
  --main-menu__header-row__width: 100%;
  --main-menu__header-row__width--minimized: 100%;
  --main-menu__header-row__line-height: 30px;
  --main-menu__header-row__border-top: 1px solid #00010d;
  --main-menu__header-row__border-right: none;
  --main-menu__header-row__border-bottom: 1px solid #00010d;
  --main-menu__header-row__border-left: none;
  --main-menu__header-row__background-color: var(--background-color-secondary);
  --main-menu__header-row__btn__border-radius: 0;
  --main-menu__header-row__btn__height: 42px;
  --main-menu__header-row__btn__min-width: 42px;
  --main-menu__header-row__btn__margin: -1px -1px 0 0;
  --main-menu__toggle-bet-slip__background-color: var(--brand-color-primary);
  --main-menu__toggle-bet-slip__width: 50px;
  --main-menu__toggle-bet-slip__color: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__margin-right: 3px;
  --main-menu__toggle-bet-slip__color--hover: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__icon__color: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__icon__color--hover: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__bet-slip-badge__background-color: var(--background-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-badge__color: var(--font-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-badge__border-radius: 3px;
  --main-menu__toggle-bet-slip__bet-slip-badge__margin-right: 4px;
  --main-menu__toggle-bet-slip__bet-slip-badge__padding: 1px 6px;
  --main-menu__toggle-bet-slip__bet-slip-badge__animation--bet-added: bet-added linear 750ms;
  --main-menu__time-range-selector__background-color: var(--background-color-secondary);
  --main-menu__time-range-selector__width: 50px;
  --main-menu__time-range-selector__outline: none;
  --main-menu__time-range-selector__border-top: 1px solid var(--button-color-secondary);
  --main-menu__time-range-selector__border-right: 1px solid var(--button-color-secondary);
  --main-menu__time-range-selector__border-bottom: 1px solid var(--button-color-secondary);
  --main-menu__time-range-selector__border-left: 1px solid var(--button-color-secondary);
  --main-menu__time-range-selector__gs-dropdown__items__top: 1.8rem;
  --main-menu__time-range-selector__gs-dropdown__items__left: -1.15rem;
  --main-menu__dropdown__right: 0px;
  --main-menu__dropdown__top: 0px;
  --main-menu__dropdown__margin-top: 0px;
  --main-menu__dropdown__margin-right: 0px;
  --main-menu__dropdown__margin-bottom: 0px;
  --main-menu__dropdown__margin-left: 0px;
  --main-menu__dropdown-menu__top: 41px;
  --main-menu__dropdown-menu__right: -1px;
  --main-menu__dropdown-menu__border-radius: 0px;
  --main-menu__dropdown-menu__border: 1px solid var(--background-color-darken);
  --main-menu__dropdown-menu__item__color: var(--font-color-quaternary);
  --main-menu__dropdown-menu__item__border-bottom: 1px solid var(--background-color-darken);
  --main-menu__dropdown-menu__item__background-color: var(--background-color-lighter);
  --main-menu__dropdown-menu__item__background-color--active: var(--brand-color-primary);
  --main-menu__dropdown-menu__item__color--active: var(--font-color-secondary);
  --main-menu__dropdown-menu__overflow: hidden;
  --main-menu__breadcrumb__background: none;
  --main-menu__breadcrumb__overflow: hidden;
  --main-menu__breadcrumb__border-radius: 0;
  --main-menu__breadcrumb__height: 40px;
  --main-menu__breadcrumb__margin: 0;
  --main-menu__breadcrumb__padding: 0 0 0 12px;
  --main-menu__breadcrumb__flex: 1 4 auto;
  --main-menu__breadcrumb__separator__color: var(--button-default-color);
  --main-menu__breadcrumb__separator__content: '\203A';
  --main-menu__breadcrumb__separator__padding-top: 0px;
  --main-menu__breadcrumb__separator__padding-right: 0px;
  --main-menu__breadcrumb__separator__padding-bottom: 0px;
  --main-menu__breadcrumb__separator__padding-left: 0px;
  --main-menu__breadcrumb__separator__font-size: 1rem;
  --main-menu__breadcrumb-icon__background: transparent;
  --main-menu__breadcrumb-icon__box-shadow: none;
  --main-menu__breadcrumb-icon__font-size: 13px;
  --main-menu__breadcrumb-icon__color: var(--font-color-primary);
  --main-menu__breadcrumb__element__line-height: 1;
  --main-menu__breadcrumb__element__height: 20px;
  --main-menu__breadcrumb__element__white-space: nowrap;
  --main-menu__breadcrumb__element__overflow: hidden;
  --main-menu__breadcrumb__element__text-overflow: ellipsis;
  --main-menu__breadcrumb__element__text-align: center;
  --main-menu__breadcrumb__element__a__text-shadow: none;
  --main-menu__breadcrumb__element__a__height: 20px;
  --main-menu__breadcrumb__element__a__line-height: 20px;
  --main-menu__breadcrumb__element__a__font-size: 0.8rem;
  --main-menu__breadcrumb__badge__background-color: var(--button-default-color);
  --main-menu__breadcrumb__badge__border-radius: 5px;
  --main-menu__breadcrumb__badge__height: 22px;
  --main-menu__breadcrumb__badge__margin-right: 7px;
  --main-menu__breadcrumb__badge__padding-top: 0;
  --main-menu__breadcrumb__badge__padding-right: 4px;
  --main-menu__breadcrumb__badge__padding-bottom: 0;
  --main-menu__breadcrumb__badge__padding-left: 2px;
  --main-menu__breadcrumb__badge__text-align: left;
  --main-menu__breadcrumb__badge__overflow: visible;
  --main-menu__breadcrumb__badge__color: var(--background-color-secondary);
  --main-menu__breadcrumb__count__font-size: 12px;
  --main-menu__breadcrumb__count__font-weight: 700;
  --main-menu__header-bottom-progress__bottom: -2px;
  --main-menu__header-bottom-progress__height: 2px;
  --main-menu__header-bottom-progress__width: 100%;
  --main-menu__toggle-bet-slip__background-color--minimized: var(--background-color-secondary);
  --main-menu__toggle-bet-slip__background-color--minimized--hover: var(--brand-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-badge__background-color--minimized--hover: var(--background-color-tertiary);
  --main-menu__toggle-bet-slip__bet-slip-badge__color--minimized--hover: var(--font-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-badge__background-color--minimized: var(--brand-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-badge__border-radius--minimized: 3px;
  --main-menu__toggle-bet-slip__bet-slip-badge__color--minimized: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__bet-slip-badge__margin-right--minimized: 4px;
  --main-menu__toggle-bet-slip__bet-slip-badge__padding: 0px;
  --main-menu__toggle-bet-slip__bet-slip-badge__width: 16px;
  --main-menu__toggle-bet-slip__bet-slip-badge__height: 20px;
  --main-menu__toggle-bet-slip__bet-slip-item__color--minimized: var(--font-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-item__color--minimized--hover: var(--font-color-secondary);
  --main-menu__toggle-bet-slip__bet-slip-item__font-size--minimized: 0.75rem;
  --main-menu__toggle-bet-slip__bet-slip-item__icon__color--minimized: var(--font-color-primary);
  --main-menu__toggle-bet-slip__bet-slip-item__icon__margin-right--minimized: 4px;
  --header-row__toggle-left-sidebar__icon__color: var(--font-color-primary);
  --header-row__toggle-left-sidebar__background-color: var(--background-color-secondary);
  --event-list__item__details__circled__padding: 0.15rem 0.25rem;
  --event-list__item__details__circled__border-width: 1px;
  --event-list__item__details__circled__border-color: var(--font-color-primary);
  --event-list__item__details__circled__border-radius: 1rem;
  --event-list__item__details__circled__background-color: transparent;
  --event-list__item__details__circled__color: var(--font-color-primary);
  --event-list__item__details__circled__font-size: 0.5rem;
  --event-list__item__details__circled__text-transform: uppercase;
  --modal__background-color: rgba(0, 0, 0, 0.6);
  --modal__box-sizing: border-box;
  --modal-content__background-color: var(--background-color-primary);
  --modal-content__border-radius: 0;
  --modal-dialog__margin-bottom: var(--modal-dialog__margin);
  --modal-dialog__margin-bottom: 0;
  --modal-dialog__margin-left: var(--modal-dialog__margin);
  --modal-dialog__margin-left: auto;
  --modal-dialog__margin-right: var(--modal-dialog__margin);
  --modal-dialog__margin-right: auto;
  --modal-dialog__margin-top: var(--modal-dialog__margin);
  --modal-dialog__margin-top: -50px;
  --modal-content__border-bottom: var(--modal-content__border);
  --modal-content__border: 0;
  --modal-content__border-left: var(--modal-content__border);
  --modal-content__border: 0;
  --modal-content__border-right: var(--modal-content__border);
  --modal-content__border: 0;
  --modal-content__border-top: var(--modal-content__border);
  --modal-content__border: 0;
  --modal-header__align-items: center;
  --modal-header__background-color: var(--brand-color-primary);
  --modal-header__color: var(--font-color-secondary);
  --modal-header__padding-bottom: var(--modal-header__padding);
  --modal-header__padding-bottom: 0.3rem;
  --modal-header__padding-left: var(--modal-header__padding);
  --modal-header__padding-left: 0.75rem;
  --modal-header__padding-right: var(--modal-header__padding);
  --modal-header__padding-right: 0.75rem;
  --modal-header__padding-top: var(--modal-header__padding);
  --modal-header__padding-top: 0.3rem;
  --modal-header__border-bottom: var(--modal-header__border);
  --modal-header__border-bottom: 0;
  --modal-header__border-left: var(--modal-header__border);
  --modal-header__border-left: none;
  --modal-header__border-right: var(--modal-header__border);
  --modal-header__border-right: none;
  --modal-header__border-top: var(--modal-header__border);
  --modal-header__border-top: 0;
  --modal-header__close__color: var(--font-color-secondary);
  --modal-header__close__float: none;
  --modal-header__close__text-shadow: none;
  --modal-header__close__opacity: 1;
  --modal-header__close__outline: none;
  --modal-header__close__margin-bottom: var(--modal-header__close__margin);
  --modal-header__close__margin-bottom: 0;
  --modal-header__close__margin-left: var(--modal-header__close__margin);
  --modal-header__close__margin-left: auto;
  --modal-header__close__margin-right: var(--modal-header__close__margin);
  --modal-header__close__margin-right: 0;
  --modal-header__close__margin-top: var(--modal-header__close__margin);
  --modal-header__close__margin-top: 0;
  --modal-body__background-color: var(--background-color-primary);
  --modal-body__background-color: var(--background-color-primary);
  --modal-body__height: calc(100% - 56px);
  --modal-body__padding-bottom: var(--modal-body__padding);
  --modal-body__padding-bottom: 0;
  --modal-body__padding-left: var(--modal-body__padding);
  --modal-body__padding-left: 0;
  --modal-body__padding-right: var(--modal-body__padding);
  --modal-body__padding-right: 0;
  --modal-body__padding-top: var(--modal-body__padding);
  --modal-body__padding-top: 0;
  --modal-lg__width: 80vw;
  --modal-lg__height: 80vh;
  --modal-lg__margin: 10vh auto;
  --modal-main__background-color: var(--background-color-primary);
  --modal-main__height: calc(78vh - 7rem);
  --modal-main__overflow: auto;
  --modal-main__position: relative;
  --modal-section__background-color: rgba(0, 0, 0, 0.2);
  --modal-section__display: none;
  --modal-section-padded__padding: 1.5rem;
  --modal-main__title__padding-bottom: var(--modal-main__title__padding);
  --modal-main__title__padding-bottom: 8px;
  --modal-main__title__padding-left: var(--modal-main__title__padding);
  --modal-main__title__padding-left: 0;
  --modal-main__title__padding-right: var(--modal-main__title__padding);
  --modal-main__title__padding-right: 0;
  --modal-main__title__padding-top: var(--modal-main__title__padding);
  --modal-main__title__padding-top: 0;
  --modal-main__title__margin-bottom: var(--modal-main__title__margin);
  --modal-main__title__margin-bottom: 2rem;
  --modal-main__title__margin-left: var(--modal-main__title__margin);
  --modal-main__title__margin-left: 0;
  --modal-main__title__margin-right: var(--modal-main__title__margin);
  --modal-main__title__margin-right: 0;
  --modal-main__title__margin-top: var(--modal-main__title__margin);
  --modal-main__title__margin-top: 0;
  --modal-main__title__border-bottom: var(--modal-main__title__border);
  --modal-main__title__border-bottom: 7px solid var(--brand-color-primary);
  --modal-main__title__border-left: var(--modal-main__title__border);
  --modal-main__title__border-left: 0;
  --modal-main__title__border-right: var(--modal-main__title__border);
  --modal-main__title__border-right: 0;
  --modal-main__title__border-top: var(--modal-main__title__border);
  --modal-main__title__border-top: 0;
  --modal-main__title__font-size: 1rem;
  --modal-main__title__display: inline-block;
  --modal-main__title__text-transform: uppercase;
  --modal-main__subtitle__background-color: var(--brand-color-primary);
  --modal-main__subtitle__color: #000;
  --modal-main__subtitle__display: inline-block;
  --modal-main__subtitle--num__color: var(--font-color-secondary);
  --modal-main__subtitle__font-size: 1rem;
  --modal-main__subtitle__text-transform: uppercase;
  --modal-main__subtitle__padding-bottom: var(--modal-main__subtitle__padding);
  --modal-main__subtitle__padding-bottom: 5px;
  --modal-main__subtitle__padding-left: var(--modal-main__subtitle__padding);
  --modal-main__subtitle__padding-left: 7px;
  --modal-main__subtitle__padding-right: var(--modal-main__subtitle__padding);
  --modal-main__subtitle__padding-right: 7px;
  --modal-main__subtitle__padding-top: var(--modal-main__subtitle__padding);
  --modal-main__subtitle__padding-top: 5px;
  --modal-main__subtitle__margin-bottom: var(--modal-main__subtitle__margin);
  --modal-main__subtitle__margin-bottom: 1rem;
  --modal-main__subtitle__margin-left: var(--modal-main__subtitle__margin);
  --modal-main__subtitle__margin-left: 0;
  --modal-main__subtitle__margin-right: var(--modal-main__subtitle__margin);
  --modal-main__subtitle__margin-right: 0;
  --modal-main__subtitle__margin-top: var(--modal-main__subtitle__margin);
  --modal-main__subtitle__margin-top: 0;
  --modal-main__text__font-size: 0.85rem;
  --modal-main__bold__font-weight: bold;
  --modal-main__bold__color: var(--brand-color-primary);
  --modal-main__bold__margin-bottom: var(--modal-main__bold__margin);
  --modal-main__bold__margin-bottom: 0;
  --modal-main__bold__margin-left: var(--modal-main__bold__margin);
  --modal-main__bold__margin-left: 0;
  --modal-main__bold__margin-right: var(--modal-main__bold__margin);
  --modal-main__bold__margin-right: 0.4rem;
  --modal-main__bold__margin-top: var(--modal-main__bold__margin);
  --modal-main__bold__margin-top: 0;
  --modal-main__table__font-size: 0.8rem;
  --modal-main__table__header__background-color: rgba(255, 255, 255, 0.04);
  --modal-main__table__header__border-bottom: var(--modal-main__table__header__border);
  --modal-main__table__header__border-bottom: 1px solid #666;
  --modal-main__table__header__border-left: var(--modal-main__table__header__border);
  --modal-main__table__header__border-left: none;
  --modal-main__table__header__border-right: var(--modal-main__table__header__border);
  --modal-main__table__header__border-right: none;
  --modal-main__table__header__border-top: var(--modal-main__table__header__border);
  --modal-main__table__header__border-top: none;
  --modal-main__table__header__padding-bottom: var(--modal-main__table__header__padding);
  --modal-main__table__header__padding-bottom: 0.5rem;
  --modal-main__table__header__padding-left: var(--modal-main__table__header__padding);
  --modal-main__table__header__padding-left: 1rem;
  --modal-main__table__header__padding-right: var(--modal-main__table__header__padding);
  --modal-main__table__header__padding-right: 0.5rem;
  --modal-main__table__header__padding-top: var(--modal-main__table__header__padding);
  --modal-main__table__header__padding-top: 1rem;
  --modal-main__table__data__border-bottom: var(--modal-main__table__data__border);
  --modal-main__table__data__border-bottom: 1px solid #444;
  --modal-main__table__data__border-left: var(--modal-main__table__data__border);
  --modal-main__table__data__border-left: none;
  --modal-main__table__data__border-right: var(--modal-main__table__data__border);
  --modal-main__table__data__border-right: none;
  --modal-main__table__data__border-top: var(--modal-main__table__data__border);
  --modal-main__table__data__border-top: none;
  --modal-main__table__data__padding-bottom: var(--modal-main__table__data__padding);
  --modal-main__table__data__padding-bottom: 0.5rem;
  --modal-main__table__data__padding-left: var(--modal-main__table__data__padding);
  --modal-main__table__data__padding-left: 0.5rem;
  --modal-main__table__data__padding-right: var(--modal-main__table__data__padding);
  --modal-main__table__data__padding-right: 0.5rem;
  --modal-main__table__data__padding-top: var(--modal-main__table__data__padding);
  --modal-main__table__data__padding-top: 0.5rem;
  --modal-main__table__data__overflow-wrap: break-word;
  --modal-main__table__border-collapse: separate;
  --modal-main__table__border-spacing: 0;
  --modal-main__table__text-align: left;
  --modal-main__table__width: 100%;
  --modal-main__table__margin-bottom: var(--modal-main__table__margin);
  --modal-main__table__margin-bottom: 2rem;
  --modal-main__table__margin-left: var(--modal-main__table__margin);
  --modal-main__table__margin-left: 0;
  --modal-main__table__margin-right: var(--modal-main__table__margin);
  --modal-main__table__margin-right: 0;
  --modal-main__table__margin-top: var(--modal-main__table__margin);
  --modal-main__table__margin-top: 2rem;
  --modal-dialog__height: 100vh;
  --modal-dialog__position: static; }

.bet__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--bet__heading__border-bottom);
  border-left: var(--bet__heading__border-left);
  border-right: var(--bet__heading__border-right);
  border-top: var(--bet__heading__border-top);
  margin-bottom: var(--bet__heading__margin-bottom);
  margin-left: var(--bet__heading__margin-left);
  margin-right: var(--bet__heading__margin-right);
  margin-top: var(--bet__heading__margin-top);
  padding-bottom: var(--bet__heading__padding-bottom);
  padding-left: var(--bet__heading__padding-left);
  padding-right: var(--bet__heading__padding-right);
  padding-top: var(--bet__heading__padding-top);
  background: var(--bet__heading__background);
  height: 28px;
  position: relative; }

.bet__title {
  flex-grow: 1;
  color: var(--bet__title__color);
  font-size: var(--bet__title__font-size);
  font-weight: var(--bet__title__font-weight);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 2px; }
  .bet__title:hover {
    color: var(--bet__title__color--hover); }
  .bet__title a {
    color: inherit;
    text-decoration: none; }
    .bet__title a:hover {
      color: inherit; }

.bet__sport-icon {
  --sport-icon__font-size: var(--bet__sport-icon__font-size);
  flex-shrink: 0;
  color: var(--bet__sport-icon__color);
  margin-bottom: var(--bet__sport-icon__margin-bottom);
  margin-left: var(--bet__sport-icon__margin-left);
  margin-right: var(--bet__sport-icon__margin-right);
  margin-top: var(--bet__sport-icon__margin-top);
  line-height: 1; }
  .bet__sport-icon:before {
    margin-left: 0; }
  .bet__sport-icon[class*='rugby-league']::after, .bet__sport-icon[class*='rugby-union']::after {
    top: 3px;
    left: 4px;
    font-size: 10px;
    color: var(--bet__sport-icon__color--inverted); }

.bet__remove {
  color: var(--bet__close-icon__color);
  background-color: transparent;
  flex-shrink: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s; }
  .bet__remove:hover {
    opacity: 0.5; }

.bet--tentatively-accepted {
  --bet__heading__background: var(--bet__heading__background--tentatively-accepted);
  --bet__title__color: var(--bet__title__color--tentatively-accepted);
  --bet__sport-icon__color: var(--bet__sport-icon__color--tentatively-accepted);
  --bet__close-icon__color: var(--bet__close-icon__color--tentatively-accepted); }

.bet__input__free-bets {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }
  .bet__input__free-bets__list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .bet__input__free-bets .toggler {
    width: 100%;
    margin: .5rem 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between; }
    .bet__input__free-bets .toggler__label {
      margin: 0; }
      .bet__input__free-bets .toggler__label__text {
        text-transform: unset; }

.bet-slip__premiums {
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: var(--bet-slip__premiums__border);
  background: var(--bet-slip__premiums__background);
  opacity: var(--bet-slip__premiums__opacity);
  color: var(--bet-slip__premiums__color);
  padding: 0.5rem; }
  .bet-slip__premiums .fa {
    display: inline-block;
    margin-right: 0.25rem;
    color: var(--bet-slip__premiums__icon__color); }

.process-actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.5rem; }
  .process-actions .btn {
    transition: background-color opacity 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--process-actions__btn__border-radius);
    border: var(--process-actions__btn__border);
    font-size: var(--process-actions__btn__font-size);
    font-weight: var(--process-actions__btn__font-weight);
    height: var(--process-actions__btn__height);
    text-transform: var(--process-actions__btn__text-transform);
    transition: var(--process-actions__btn__transition);
    flex-direction: var(--process-actions__btn__flex-direction);
    box-shadow: var(--process-actions__btn__box-shadow); }
    .process-actions .btn:hover {
      opacity: var(--process-actions__btn__opacity--hover, var(--process-actions__btn__opacity));
      box-shadow: var(--process-actions__btn__box-shadow--hover, var(--process-actions__btn__box-shadow)); }
    .process-actions .btn i {
      margin-bottom: var(--process-actions__btn__icon__margin-bottom);
      margin-left: var(--process-actions__btn__icon__margin-left);
      margin-right: var(--process-actions__btn__icon__margin-right);
      margin-top: var(--process-actions__btn__icon__margin-top); }
    .process-actions .btn.btn-primary {
      background-color: var(--process-actions__btn-primary__background-color);
      color: var(--process-actions__btn-primary__color);
      border: var(--process-actions__btn-primary__border);
      flex-grow: 1; }
      .process-actions .btn.btn-primary i {
        color: var(--process-actions__btn-primary__icon__color); }
    .process-actions .btn.btn-secondary {
      background-color: var(--process-actions__btn-secondary__background-color);
      color: var(--process-actions__btn-secondary__color);
      margin-bottom: var(--process-actions__btn-secondary__margin-bottom);
      margin-left: var(--process-actions__btn-secondary__margin-left);
      margin-right: var(--process-actions__btn-secondary__margin-right);
      margin-top: var(--process-actions__btn-secondary__margin-top);
      flex-grow: 1; }
      .process-actions .btn.btn-secondary .fa-times,
      .process-actions .btn.btn-secondary span {
        display: var(--process-actions__btn-secondary__icon__display);
        font-size: var(--process-actions__btn-secondary__icon__font-size);
        color: var(--process-actions__btn-secondary__icon__color); }

.bet-slip-top .bet-slip-badge {
  background-color: var(--background-color-primary);
  color: var(--font-color-primary);
  border-radius: 3px;
  display: inline-block;
  margin-right: 4px;
  padding: 1px 6px; }

.parlays-summarize__top__odds__icon, .parlays-summarize__bottom__icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--icon__background-color);
  color: var(--icon__color);
  height: 1.15rem;
  width: 1.15rem;
  border-radius: 100%; }
  .parlays-summarize__top__odds__icon i, .parlays-summarize__bottom__icon i {
    font-size: 0.75rem;
    color: inherit; }

.parlays-summarize {
  border: var(--parlays-summarize__border);
  color: var(--parlays-summarize__color);
  background-color: var(--parlays-summarize__background-color);
  border-radius: var(--parlays-summarize__border-radius);
  font-size: var(--parlays-summarize__font-size);
  box-shadow: var(--parlays-summarize__box-shadow);
  margin-bottom: var(--parlays-summarize__margin-bottom);
  margin-left: var(--parlays-summarize__margin-left);
  margin-right: var(--parlays-summarize__margin-right);
  margin-top: var(--parlays-summarize__margin-top);
  padding-bottom: var(--parlays-summarize__padding-bottom);
  padding-left: var(--parlays-summarize__padding-left);
  padding-right: var(--parlays-summarize__padding-right);
  padding-top: var(--parlays-summarize__padding-top);
  line-height: 1; }
  .parlays-summarize--with-premiums {
    border: var(--parlays-summarize__border--with-premiums, var(--parlays-summarize__border));
    color: var(--parlays-summarize__color--with-premiums, var(--parlays-summarize__color));
    background-color: var(--parlays-summarize__background-color--with-premiums, var(--parlays-summarize__background-color));
    border-radius: var(--parlays-summarize__border-radius--with-premiums, var(--parlays-summarize__border-radius)); }
  .parlays-summarize__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--parlays-summarize__top__font-size); }
    .parlays-summarize__top__odds {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      font-weight: var(--parlays-summarize__top__odds__font-weight);
      font-size: var(--parlays-summarize__top__odds__font-size);
      margin-bottom: var(--parlays-summarize__top__odds__margin-bottom);
      margin-left: var(--parlays-summarize__top__odds__margin-left);
      margin-right: var(--parlays-summarize__top__odds__margin-right);
      margin-top: var(--parlays-summarize__top__odds__margin-top); }
      .parlays-summarize--with-premiums .parlays-summarize__top__odds {
        font-weight: var(--parlays-summarize__top__odds__font-weight--with-premiums, var(--parlays-summarize__top__odds__font-weight)); }
      .parlays-summarize__top__odds__icon {
        --icon__background-color: var(--parlays-summarize__top__odds__icon__background-color);
        --icon__color: var(--parlays-summarize__top__odds__icon__color);
        margin-left: 0.25rem; }
      .parlays-summarize__top__odds__number {
        color: var(--parlays-summarize__top__odds__number__color);
        font-weight: var(--parlays-summarize__top__odds__number__font-weight); }
        .parlays-summarize--with-premiums .parlays-summarize__top__odds__number {
          color: var(--parlays-summarize__top__odds__number__color--with-premiums, var(--parlays-summarize__top__odds__number__color));
          font-weight: var(--parlays-summarize__top__odds__number__font-weight--with-premiums, var(--parlays-summarize__top__odds__number__font-weight)); }
    .parlays-summarize__top__label {
      font-weight: var(--parlays-summarize__top__label__font-weight);
      font-size: var(--parlays-summarize__top__label__font-size);
      margin-bottom: var(--parlays-summarize__top__label__margin-bottom);
      margin-left: var(--parlays-summarize__top__label__margin-left);
      margin-right: var(--parlays-summarize__top__label__margin-right);
      margin-top: var(--parlays-summarize__top__label__margin-top); }
  .parlays-summarize__bottom {
    font-size: var(--parlays-summarize__bottom__font-size);
    color: var(--parlays-summarize__bottom__color);
    margin-bottom: var(--parlays-summarize__bottom__margin-bottom);
    margin-left: var(--parlays-summarize__bottom__margin-left);
    margin-right: var(--parlays-summarize__bottom__margin-right);
    margin-top: var(--parlays-summarize__bottom__margin-top);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .parlays-summarize__bottom__icon {
      --icon__background-color: var(--parlays-summarize__bottom__icon__background-color);
      --icon__color: var(--parlays-summarize__bottom__icon__color);
      margin-right: 0.5rem; }
    .parlays-summarize__bottom__text {
      margin-bottom: var(--parlays-summarize__bottom__text__margin-bottom);
      margin-left: var(--parlays-summarize__bottom__text__margin-left);
      margin-right: var(--parlays-summarize__bottom__text__margin-right);
      margin-top: var(--parlays-summarize__bottom__text__margin-top); }
      .parlays-summarize__bottom__text .emphasis {
        color: var(--parlays-summarize__bottom__text__color--highlighted); }
      .parlays-summarize__bottom__text span {
        line-height: 1.2;
        display: flex; }
        .parlays-summarize__bottom__text span span {
          margin: 0 0.15rem; }

.round-robins-summarize {
  border: var(--round-robins-summarize__border);
  color: var(--round-robins-summarize__color);
  background-color: var(--round-robins-summarize__background-color);
  border-radius: var(--round-robins-summarize__border-radius);
  font-size: var(--round-robins-summarize__font-size);
  box-shadow: var(--round-robins-summarize__box-shadow);
  padding-bottom: var(--round-robins-summarize__padding-bottom);
  padding-left: var(--round-robins-summarize__padding-left);
  padding-right: var(--round-robins-summarize__padding-right);
  padding-top: var(--round-robins-summarize__padding-top);
  margin-bottom: var(--round-robins-summarize__margin-bottom);
  margin-left: var(--round-robins-summarize__margin-left);
  margin-right: var(--round-robins-summarize__margin-right);
  margin-top: var(--round-robins-summarize__margin-top);
  line-height: 1; }
  .round-robins-summarize__line {
    margin: 0;
    line-height: 1.4;
    font-size: var(--round-robins-summarize__line__font-size);
    color: var(--round-robins-summarize__line__color);
    padding-bottom: var(--round-robins-summarize__line__padding-bottom);
    padding-left: var(--round-robins-summarize__line__padding-left);
    padding-right: var(--round-robins-summarize__line__padding-right);
    padding-top: var(--round-robins-summarize__line__padding-top);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between; }
    .round-robins-summarize__line__explanation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--round-robins-summarize__line__explanation__color); }
      .round-robins-summarize__line__explanation .emphasis {
        color: var(--round-robins-summarize__line__explanation__emphasis__color);
        font-weight: var(--round-robins-summarize__line__explanation__emphasis__font-weight); }
    .round-robins-summarize__line__boost-return {
      color: var(--round-robins-summarize__line__boost-return__color);
      font-weight: var(--round-robins-summarize__line__boost-return__font-weight); }
    .round-robins-summarize__line__to-win {
      color: var(--round-robins-summarize__line__to-win__color);
      font-weight: var(--round-robins-summarize__line__to-win__font-weight); }
  .round-robins-summarize__total-return {
    font-size: var(--round-robins-summarize__total-return__font-size);
    color: var(--round-robins-summarize__total-return__color);
    padding-bottom: var(--round-robins-summarize__total-return__padding-bottom);
    padding-left: var(--round-robins-summarize__total-return__padding-left);
    padding-right: var(--round-robins-summarize__total-return__padding-right);
    padding-top: var(--round-robins-summarize__total-return__padding-top);
    margin-bottom: var(--round-robins-summarize__total-return__margin-bottom);
    margin-left: var(--round-robins-summarize__total-return__margin-left);
    margin-right: var(--round-robins-summarize__total-return__margin-right);
    margin-top: var(--round-robins-summarize__total-return__margin-top);
    border-bottom: var(--round-robins-summarize__total-return__border-bottom);
    border-left: var(--round-robins-summarize__total-return__border-left);
    border-right: var(--round-robins-summarize__total-return__border-right);
    border-top: var(--round-robins-summarize__total-return__border-top);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between; }
    .round-robins-summarize__total-return--with-premiums {
      padding-bottom: var(--round-robins-summarize__total-return__padding-bottom--with-premiums);
      padding-left: var(--round-robins-summarize__total-return__padding-left--with-premiums);
      padding-right: var(--round-robins-summarize__total-return__padding-right--with-premiums);
      padding-top: var(--round-robins-summarize__total-return__padding-top--with-premiums);
      margin-bottom: var(--round-robins-summarize__total-return__margin-bottom--with-premiums);
      margin-left: var(--round-robins-summarize__total-return__margin-left--with-premiums);
      margin-right: var(--round-robins-summarize__total-return__margin-right--with-premiums);
      margin-top: var(--round-robins-summarize__total-return__margin-top--with-premiums);
      border-bottom: var(--round-robins-summarize__total-return__border-bottom--with-premiums);
      border-left: var(--round-robins-summarize__total-return__border-left--with-premiums);
      border-right: var(--round-robins-summarize__total-return__border-right--with-premiums);
      border-top: var(--round-robins-summarize__total-return__border-top--with-premiums); }
    .round-robins-summarize__total-return__explanation {
      color: var(--round-robins-summarize__total-return__explanation__color);
      font-weight: var(--round-robins-summarize__total-return__explanation__font-weight); }
    .round-robins-summarize__total-return__value {
      font-size: var(--round-robins-summarize__total-return__value__font-size);
      color: var(--round-robins-summarize__total-return__value__color);
      font-weight: var(--round-robins-summarize__total-return__value__font-weight); }
  .round-robins-summarize__icon {
    color: var(--round-robins-summarize__icon__color);
    background-color: var(--round-robins-summarize__icon__background-color);
    height: var(--round-robins-summarize__icon__height);
    width: var(--round-robins-summarize__icon__width);
    border-radius: var(--round-robins-summarize__icon__border-radius);
    font-size: var(--round-robins-summarize__icon__font-size);
    margin-bottom: var(--round-robins-summarize__icon__margin-bottom);
    margin-left: var(--round-robins-summarize__icon__margin-left);
    margin-right: var(--round-robins-summarize__icon__margin-right);
    margin-top: var(--round-robins-summarize__icon__margin-top);
    line-height: var(--round-robins-summarize__icon__height);
    text-align: center; }

.round-robins {
  margin-bottom: var(--round-robins__margin-bottom);
  margin-left: var(--round-robins__margin-left);
  margin-right: var(--round-robins__margin-right);
  margin-top: var(--round-robins__margin-top);
  margin-bottom: 0.75rem; }
  .round-robins__toggler {
    margin-bottom: var(--round-robins__toggler__margin-bottom);
    margin-left: var(--round-robins__toggler__margin-left);
    margin-right: var(--round-robins__toggler__margin-right);
    margin-top: var(--round-robins__toggler__margin-top);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--round-robins__toggler__font-size);
    background-color: var(--round-robins__toggler__background-color);
    border: var(--round-robins__toggler__border);
    border-radius: var(--round-robins__toggler__border-radius);
    color: var(--round-robins__toggler__color);
    text-transform: var(--round-robins__toggler__text-transform);
    flex-direction: var(--round-robins__toggler__flex-direction);
    justify-content: var(--round-robins__toggler__justify-content);
    margin-bottom: 0.75rem;
    width: 100%;
    min-height: 2rem;
    outline: none;
    line-height: 1; }
    .round-robins__toggler i {
      font-size: 1.2em;
      margin-right: var(--round-robins__toggler__i__margin-right);
      color: var(--round-robins__toggler__i__color); }
    .round-robins__toggler:hover {
      opacity: 0.75; }
  .round-robins__sizes__size {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    margin-bottom: 0.5rem;
    cursor: pointer; }
    .round-robins__sizes__size__label {
      font-size: var(--round-robins__sizes__size__label__font-size);
      color: var(--round-robins__sizes__size__label__color);
      line-height: 1; }
      .round-robins__sizes__size__label__odds {
        color: var(--round-robins__sizes__size__label__odds__color);
        text-decoration: var(--round-robins__sizes__size__label__odds__text-decoration);
        font-weight: var(--round-robins__sizes__size__label__odds__font-weight);
        font-size: var(--round-robins__sizes__size__label__odds__font-size); }
        .round-robins__sizes__size__label__odds--premium {
          color: var(--round-robins__sizes__size__label__odds__color--premium, var(--round-robins__sizes__size__label__odds__color));
          text-decoration: var(--round-robins__sizes__size__label__odds__text-decoration--premium, var(--round-robins__sizes__size__label__odds__text-decoration));
          font-weight: var(--round-robins__sizes__size__label__odds__font-weight--premium, var(--round-robins__sizes__size__label__odds__font-weight));
          margin-left: 0.25rem; }
        .round-robins__sizes__size__label__odds--discounted {
          color: var(--round-robins__sizes__size__label__odds__color--discounted, var(--round-robins__sizes__size__label__odds__color));
          text-decoration: var(--round-robins__sizes__size__label__odds__text-decoration--discounted, var(--round-robins__sizes__size__label__odds__text-decoration));
          font-weight: var(--round-robins__sizes__size__label__odds__font-weight--discounted, var(--round-robins__sizes__size__label__odds__font-weight)); }
    .round-robins__sizes__size input {
      margin: 0;
      line-height: 1;
      margin-right: 0.6rem;
      position: relative;
      top: 1px; }
  .round-robins__toggle-all {
    display: none;
    margin-left: auto;
    float: none; }
  .round-robins--open .round-robins__sizes {
    display: block; }
  .round-robins--open .round-robins__toggle-all {
    display: flex; }
  .round-robins--close .round-robins__sizes {
    display: none; }

.free-bets__expander {
  flex-shrink: 0;
  margin-bottom: var(--free-bets__expander__margin-bottom);
  margin-left: var(--free-bets__expander__margin-left);
  margin-right: var(--free-bets__expander__margin-right);
  margin-top: var(--free-bets__expander__margin-top);
  padding-bottom: var(--free-bets__expander__padding-bottom);
  padding-left: var(--free-bets__expander__padding-left);
  padding-right: var(--free-bets__expander__padding-right);
  padding-top: var(--free-bets__expander__padding-top);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: var(--free-bets__expander__border);
  font-size: var(--free-bets__expander__font-size);
  background-color: var(--free-bets__expander__background-color);
  border-radius: var(--free-bets__expander__border-radius); }
  .free-bets__expander__count {
    margin-bottom: 0;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: var(--free-bets__expander__count__flex-direction);
    font-size: var(--free-bets__expander__count__font-size);
    flex-direction: var(--free-bets__expander__count__flex-direction, row); }
    .free-bets__expander__count span {
      display: block;
      text-transform: uppercase;
      margin-bottom: var(--free-bets__expander__count__highlighted__margin-bottom);
      margin-left: var(--free-bets__expander__count__highlighted__margin-left);
      margin-right: var(--free-bets__expander__count__highlighted__margin-right);
      margin-top: var(--free-bets__expander__count__highlighted__margin-top);
      font-size: var(--free-bets__expander__count__highlighted__font-size);
      color: var(--free-bets__expander__count__highlighted__color);
      font-weight: var(--free-bets__expander__count__highlighted__font-weight); }
  .free-bets__expander__button {
    appearance: none;
    outline: none;
    background-color: var(--free-bets__expander__button__background-color);
    color: var(--free-bets__expander__button__color);
    font-weight: var(--free-bets__expander__button__font-weight);
    border: var(--free-bets__expander__button__border);
    border-radius: var(--free-bets__expander__button__border-radius);
    font-size: var(--free-bets__expander__button__font-size);
    padding-bottom: var(--free-bets__expander__button__padding-bottom);
    padding-left: var(--free-bets__expander__button__padding-left);
    padding-right: var(--free-bets__expander__button__padding-right);
    padding-top: var(--free-bets__expander__button__padding-top);
    transition: border-radius, 0.3s, background-color 0.3s, color 0.3s; }
    .free-bets__expander__button:hover {
      background-color: var(--free-bets__expander__button__background-color--hover, var(--free-bets__expander__button__background-color));
      color: var(--free-bets__expander__button__color--hover, var(--free-bets__expander__button__color));
      font-weight: var(--free-bets__expander__button__font-weight--hover, var(--free-bets__expander__button__font-weight));
      border: var(--free-bets__expander__button__border--hover, var(--free-bets__expander__button__border));
      border-radius: var(--free-bets__expander__button__border-radius--hover, var(--free-bets__expander__button__border-radius));
      font-size: var(--free-bets__expander__button__font-size--hover, var(--free-bets__expander__button__font-size)); }
    .free-bets__expander__button--expanded {
      --free-bets__expander__button__border-radius: var(--free-bets__expander__button__border-radius--expanded); }

.free-bets__container {
  background-color: var(--free-bets__container__background-color);
  position: absolute;
  z-index: 2;
  width: 100%;
  overflow: auto; }
  .free-bets__container.expanded {
    height: 100% !important; }

.free-bets-list {
  margin-bottom: var(--free-bets-list__margin-bottom);
  margin-left: var(--free-bets-list__margin-left);
  margin-right: var(--free-bets-list__margin-right);
  margin-top: var(--free-bets-list__margin-top); }
  .free-bets-list__header {
    padding-bottom: var(--free-bets-list__header__padding-bottom);
    padding-left: var(--free-bets-list__header__padding-left);
    padding-right: var(--free-bets-list__header__padding-right);
    padding-top: var(--free-bets-list__header__padding-top);
    margin-bottom: var(--free-bets-list__header__margin-bottom);
    margin-left: var(--free-bets-list__header__margin-left);
    margin-right: var(--free-bets-list__header__margin-right);
    margin-top: var(--free-bets-list__header__margin-top);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .free-bets-list__header__text {
      margin-bottom: 0; }
    .free-bets-list__header__close-button {
      border-radius: var(--free-bets-list__close-button__border-radius);
      background-color: var(--free-bets-list__close-button__background-color);
      color: var(--free-bets-list__close-button__color);
      border: var(--free-bets-list__close-button__border);
      font-size: var(--free-bets-list__close-button__font-size); }

.free-bet-item {
  overflow: hidden;
  border: var(--free-bet-item__border);
  border-radius: var(--free-bet-item__border-radius);
  background-color: var(--free-bet-item__background-color);
  padding-bottom: var(--free-bet-item__padding-bottom);
  padding-left: var(--free-bet-item__padding-left);
  padding-right: var(--free-bet-item__padding-right);
  padding-top: var(--free-bet-item__padding-top);
  margin-bottom: var(--free-bet-item__margin-bottom);
  margin-left: var(--free-bet-item__margin-left);
  margin-right: var(--free-bet-item__margin-right);
  margin-top: var(--free-bet-item__margin-top);
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: min-content 1fr;
  column-gap: 0.75rem;
  padding: 1rem 0; }
  .free-bet-item + .free-bet-item {
    border-top: 1px solid var(--free-bet-item__separator__color); }
  .free-bet-item aside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem;
    grid-row: 1 / 3;
    color: var(--free-bet-item__aside__color); }
    .free-bet-item aside i {
      font-size: 1.5rem; }
  .free-bet-item header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: var(--free-bet-item__header__padding-bottom);
    padding-left: var(--free-bet-item__header__padding-left);
    padding-right: var(--free-bet-item__header__padding-right);
    padding-top: var(--free-bet-item__header__padding-top);
    margin-bottom: var(--free-bet-item__header__margin-bottom);
    margin-left: var(--free-bet-item__header__margin-left);
    margin-right: var(--free-bet-item__header__margin-right);
    margin-top: var(--free-bet-item__header__margin-top);
    background-color: var(--free-bet-item__header__background-color); }
  .free-bet-item__display-name {
    color: var(--free-bet-item__display-name__color); }
  .free-bet-item article {
    overflow: hidden; }
  .free-bet-item__footer {
    color: var(--free-bet-item__footer__color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
  .free-bet-item__expiration-date {
    padding-bottom: var(--free-bet-item__expiration-date__padding-bottom);
    padding-left: var(--free-bet-item__expiration-date__padding-left);
    padding-right: var(--free-bet-item__expiration-date__padding-right);
    padding-top: var(--free-bet-item__expiration-date__padding-top);
    margin-bottom: var(--free-bet-item__expiration-date__margin-bottom);
    margin-left: var(--free-bet-item__expiration-date__margin-left);
    margin-right: var(--free-bet-item__expiration-date__margin-right);
    margin-top: var(--free-bet-item__expiration-date__margin-top);
    color: inherit;
    margin-bottom: 0;
    color: var(--free-bet-item__expiration-date__color);
    font-size: var(--free-bet-item__expiration-date__font-size);
    flex-grow: 1;
    text-align: left;
    opacity: 0.8; }
    .free-bet-item__expiration-date i {
      display: inline-block;
      margin-right: 0.25rem; }
  .free-bet-item__display-name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .free-bet-item__name {
    color: var(--free-bet-item__name__color);
    font-size: var(--free-bet-item__name__font-size);
    text-transform: var(--free-bet-item__name__text-transform); }
    .free-bet-item__name i {
      display: var(--free-bet-item__name__icon__display);
      padding-bottom: var(--free-bet-item__name__icon__padding-bottom);
      padding-left: var(--free-bet-item__name__icon__padding-left);
      padding-right: var(--free-bet-item__name__icon__padding-right);
      padding-top: var(--free-bet-item__name__icon__padding-top);
      margin-bottom: var(--free-bet-item__name__icon__margin-bottom);
      margin-left: var(--free-bet-item__name__icon__margin-left);
      margin-right: var(--free-bet-item__name__icon__margin-right);
      margin-top: var(--free-bet-item__name__icon__margin-top); }
  .free-bet-item__amount {
    color: var(--free-bet-item__amount__color);
    font-size: var(--free-bet-item__amount__font-size);
    font-weight: var(--free-bet-item__amount__font-weight);
    margin-right: 0.5rem; }
  .free-bet-item__applicability {
    padding-bottom: var(--free-bet-item__applicability__padding-bottom);
    padding-left: var(--free-bet-item__applicability__padding-left);
    padding-right: var(--free-bet-item__applicability__padding-right);
    padding-top: var(--free-bet-item__applicability__padding-top);
    margin-bottom: var(--free-bet-item__applicability__margin-bottom);
    margin-left: var(--free-bet-item__applicability__margin-left);
    margin-right: var(--free-bet-item__applicability__margin-right);
    margin-top: var(--free-bet-item__applicability__margin-top);
    font-size: var(--free-bet-item__applicability__font-size);
    color: var(--free-bet-item__applicability__color); }
    .free-bet-item__applicability > span {
      margin-right: 0.25rem;
      opacity: 0.75; }
    .free-bet-item__applicability-item {
      font-size: var(--free-bet-item__applicability-item__font-size);
      color: var(--free-bet-item__applicability-item__color);
      cursor: pointer;
      transition: color 0.3s; }
      .free-bet-item__applicability-item + .free-bet-item__applicability-item:before {
        content: ', '; }
      .free-bet-item__applicability-item:hover {
        color: var(--free-bet-item__applicability-item__color--hover, var(--free-bet-item__applicability-item__color)); }

.free-bet-selector {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-template-rows: auto;
  gap: 0.25rem;
  margin-bottom: var(--free-bet-selector__margin-bottom);
  margin-left: var(--free-bet-selector__margin-left);
  margin-right: var(--free-bet-selector__margin-right);
  margin-top: var(--free-bet-selector__margin-top); }
  .free-bet-selector__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: var(--free-bet-selector__item__border-bottom);
    border-left: var(--free-bet-selector__item__border-left);
    border-right: var(--free-bet-selector__item__border-right);
    border-top: var(--free-bet-selector__item__border-top);
    padding-bottom: var(--free-bet-selector__item__padding-bottom);
    padding-left: var(--free-bet-selector__item__padding-left);
    padding-right: var(--free-bet-selector__item__padding-right);
    padding-top: var(--free-bet-selector__item__padding-top);
    color: var(--free-bet-selector__item__color);
    font-size: var(--free-bet-selector__item__font-size);
    background-color: var(--free-bet-selector__item__background-color);
    border-radius: var(--free-bet-selector__item__border-radius);
    box-shadow: var(--free-bet-selector__item__box-shadow);
    transition: background-color, 0.3s, color 0.3s;
    flex-grow: 1;
    line-height: 1;
    cursor: pointer; }
    .free-bet-selector__item__amount {
      color: var(--free-bet-selector__item__amount__color);
      font-size: var(--free-bet-selector__item__amount__font-size);
      transition: color, 0.3s, font-size 0.3s;
      margin: 0;
      margin-bottom: 0.25em; }
    .free-bet-selector__item__label {
      color: var(--free-bet-selector__item__label__color);
      font-size: var(--free-bet-selector__item__label__font-size);
      transition: color, 0.3s, font-size 0.3s;
      margin: 0;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden; }
      .free-bet-selector__item__label__icon {
        margin-right: 0.25rem; }
    .free-bet-selector__item--selected {
      border-bottom: var(--free-bet-selector__item__border-bottom--selected);
      border-left: var(--free-bet-selector__item__border-left--selected);
      border-right: var(--free-bet-selector__item__border-right--selected);
      border-top: var(--free-bet-selector__item__border-top--selected);
      color: var(--free-bet-selector__item__color--selected, var(--free-bet-selector__item__color));
      background-color: var(--free-bet-selector__item__background-color--selected, var(--free-bet-selector__item__background-color));
      box-shadow: var(--free-bet-selector__item__box-shadow--selected, var(--free-bet-selector__item__box-shadow)); }
      .free-bet-selector__item--selected__amount {
        color: var(--free-bet-selector__item__amount__color--selected, var(--free-bet-selector__item__amount__color));
        font-size: var(--free-bet-selector__item__amount__font-size--selected, var(--free-bet-selector__item__amount__font-size)); }
      .free-bet-selector__item--selected__label {
        color: var(--free-bet-selector__item__label__color--selected, var(--free-bet-selector__item__label__color));
        font-size: var(--free-bet-selector__item__label__font-size--selected, var(--free-bet-selector__item__label__font-size)); }
    .free-bet-selector__item--disabled {
      position: relative;
      cursor: not-allowed; }
      .free-bet-selector__item--disabled:not(.free-bet-selector__item--selected):after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: '';
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3); }

.history-bet-group {
  width: 100%;
  font-size: var(--history-bet-group__font-size); }
  @media screen and (min-width: 768px) {
    .history-bet-group {
      min-width: 300px;
      width: 30vw; } }
  .history-bet-group * {
    margin: unset; }
  .history-bet-group__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.5rem; }
    .history-bet-group__header .label {
      margin-left: auto; }
    .history-bet-group__header__main {
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: 0.5rem;
      flex-basis: 50%; }
    .history-bet-group__header > p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
    .history-bet-group__header__risk {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0.25rem; }
    .history-bet-group__header__to-win {
      color: var(--history-bet-group__header__to-win__color);
      font-weight: var(--history-bet-group__header__to-win__font-weight);
      margin-left: 0.25rem; }
    .history-bet-group__header__original-odds {
      color: var(--history-bet-group__header__original-odds__color);
      font-weight: var(--history-bet-group__header__original-odds__font-weight);
      margin-left: 0.25rem; }
      .history-bet-group__header__original-odds--with-premiums {
        color: var(--history-bet-group__header__original-odds__color--with-premiums, var(--history-bet-group__header__original-odds__color));
        text-decoration: line-through;
        margin-right: 0.25rem; }
    .history-bet-group__header__premium-odds {
      color: var(--history-bet-group__header__premium-odds__color);
      font-weight: var(--history-bet-group__header__premium-odds__font-weight); }
    .history-bet-group__header__premium-pctg {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: var(--history-bet-group__header__premium-pctg__color);
      font-weight: var(--history-bet-group__header__premium-pctg__font-weight); }
      .history-bet-group__header__premium-pctg__icon {
        color: var(--history-bet-group__header__premium-pctg__icon__color);
        background-color: var(--history-bet-group__header__premium-pctg__icon__background-color);
        width: var(--history-bet-group__header__premium-pctg__icon__width);
        height: var(--history-bet-group__header__premium-pctg__icon__height);
        font-size: var(--history-bet-group__header__premium-pctg__icon__font-size);
        border-radius: var(--history-bet-group__header__premium-pctg__icon__border-radius);
        margin-right: 0.25rem;
        text-align: center;
        line-height: var(--history-bet-group__header__premium-pctg__icon__height); }
  .history-bet-group__size-breakdown__selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .history-bet-group__size-breakdown__selector > p {
      padding-right: 0.25rem; }
    .history-bet-group__size-breakdown__selector__size {
      color: var(--history-bet-group__size-breakdown__selector__size__color);
      background-color: var(--history-bet-group__size-breakdown__selector__size__background-color);
      padding-bottom: var(--history-bet-group__size-breakdown__selector__size__padding-bottom);
      padding-left: var(--history-bet-group__size-breakdown__selector__size__padding-left);
      padding-right: var(--history-bet-group__size-breakdown__selector__size__padding-right);
      padding-top: var(--history-bet-group__size-breakdown__selector__size__padding-top);
      min-width: unset;
      cursor: pointer; }
      .history-bet-group__size-breakdown__selector__size--selected {
        color: var(--history-bet-group__size-breakdown__selector__size__color--selected, var(--history-bet-group__size-breakdown__selector__size__color));
        background-color: var(--history-bet-group__size-breakdown__selector__size__background-color--selected, var(--history-bet-group__size-breakdown__selector__size__background-color)); }
    .history-bet-group__size-breakdown__selector__size-detail {
      color: var(--history-bet-group__size-breakdown__selector__size-detail__color);
      background-color: var(--history-bet-group__size-breakdown__selector__size-detail__background-color);
      padding-bottom: var(--history-bet-group__size-breakdown__selector__size-detail__padding-bottom);
      padding-left: var(--history-bet-group__size-breakdown__selector__size-detail__padding-left);
      padding-right: var(--history-bet-group__size-breakdown__selector__size-detail__padding-right);
      padding-top: var(--history-bet-group__size-breakdown__selector__size-detail__padding-top);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start; }
      .history-bet-group__size-breakdown__selector__size-detail__bet {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0.25rem; }
        .history-bet-group__size-breakdown__selector__size-detail__bet:nth-child(2n + 1) {
          background-color: var(--history-bet-group__size-breakdown__selector__size-detail__bet__background-color--odd, var(--history-bet-group__size-breakdown__selector__size-detail__bet__background-color)); }
        .history-bet-group__size-breakdown__selector__size-detail__bet p {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          margin-left: 0.25rem; }

.header-row {
  height: var(--main-menu__header-row__height);
  width: var(--main-menu__header-row__width);
  line-height: var(--main-menu__header-row__line-height);
  background-color: var(--main-menu__header-row__background-color);
  padding: var(--main-menu__header-row__padding);
  border-bottom: var(--main-menu__header-row__border-bottom);
  border-left: var(--main-menu__header-row__border-left);
  border-right: var(--main-menu__header-row__border-right);
  border-top: var(--main-menu__header-row__border-top);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 4; }
  .header-row .btn, .header-row__schedule.btn {
    color: var(--main-menu__header-row__btn__color);
    border-radius: var(--main-menu__header-row__btn__border-radius);
    height: var(--main-menu__header-row__btn__height);
    min-width: var(--main-menu__header-row__btn__min-width);
    margin: var(--main-menu__header-row__btn__margin); }
    .header-row .btn.btn-default:hover, .header-row__schedule.btn.btn-default:hover {
      color: var(--main-menu__header-row__btn__color--hover, var(--main-menu__header-row__btn__color)); }
    .header-row .btn.btn-default:focus, .header-row__schedule.btn.btn-default:focus {
      color: var(--main-menu__header-row__btn__color--focus, var(--main-menu__header-row__btn__color));
      background-color: var(--main-menu__header-row__btn__background-color--focus, var(--main-menu__header-row__btn__background-color)); }
    .header-row .btn.btn-default:active, .header-row__schedule.btn.btn-default:active {
      color: var(--main-menu__header-row__btn__color--active, var(--main-menu__header-row__btn__color));
      background-color: var(--main-menu__header-row__btn__background-color--active, var(--main-menu__header-row__btn__background-color)); }
  .header-row__toggle-left-sidebar.btn {
    background-color: var(--header-row__toggle-left-sidebar__background-color);
    border-radius: var(--header-row__toggle-left-sidebar__border-radius); }
    .header-row__toggle-left-sidebar.btn i {
      color: var(--header-row__toggle-left-sidebar__icon__color); }
  .header-row .toggle-bet-slip {
    background-color: var(--main-menu__toggle-bet-slip__background-color);
    width: var(--main-menu__toggle-bet-slip__width);
    z-index: 4; }
    @media screen and (min-width: 1250px) {
      .header-row .toggle-bet-slip {
        width: 100px; } }
    .header-row .toggle-bet-slip:hover {
      color: var(--main-menu__toggle-bet-slip__color--hover, var(--main-menu__toggle-bet-slip__color)); }
    .header-row .toggle-bet-slip:hover .header-row .toggle-bet-slip__icon {
      color: var(--main-menu__toggle-bet-slip__icon__color--hover, var(--main-menu__toggle-bet-slip__icon__color)); }
    .header-row .toggle-bet-slip__icon {
      color: var(--main-menu__toggle-bet-slip__icon__color); }
    .header-row .toggle-bet-slip .bet-slip-badge {
      background-color: var(--main-menu__toggle-bet-slip__bet-slip-badge__background-color);
      color: var(--main-menu__toggle-bet-slip__bet-slip-badge__color);
      border-radius: var(--main-menu__toggle-bet-slip__bet-slip-badge__border-radius);
      margin-right: var(--main-menu__toggle-bet-slip__bet-slip-badge__margin-right);
      padding: var(--main-menu__toggle-bet-slip__bet-slip-badge__padding);
      width: var(--main-menu__toggle-bet-slip__bet-slip-badge__width);
      height: var(--main-menu__toggle-bet-slip__bet-slip-badge__height);
      display: inline-block; }
      .header-row .toggle-bet-slip .bet-slip-badge.-bet-added {
        animation: var(--main-menu__toggle-bet-slip__bet-slip-badge__animation--bet-added, var(--main-menu__toggle-bet-slip__bet-slip-badge__animation)); }
    .header-row .toggle-bet-slip .bet-slip-item {
      color: var(--main-menu__toggle-bet-slip__color);
      margin-right: var(--main-menu__toggle-bet-slip__margin-right);
      display: none; }
      @media screen and (min-width: 1250px) {
        .header-row .toggle-bet-slip .bet-slip-item {
          display: inline-block; } }
  .header-row__time-range-selector {
    background-color: var(--main-menu__time-range-selector__background-color);
    width: var(--main-menu__time-range-selector__width);
    outline: var(--main-menu__time-range-selector__outline);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: var(--main-menu__time-range-selector__border-bottom);
    border-left: var(--main-menu__time-range-selector__border-left);
    border-right: var(--main-menu__time-range-selector__border-right);
    border-top: var(--main-menu__time-range-selector__border-top); }
    .header-row__time-range-selector .gs-dropdown__items {
      top: var(--main-menu__time-range-selector__gs-dropdown__items__top);
      left: var(--main-menu__time-range-selector__gs-dropdown__items__left); }
    .header-row__time-range-selector .gs-dropdown__chevron {
      display: none; }
  .header-row__dropdown {
    right: var(--main-menu__dropdown__right);
    top: var(--main-menu__dropdown__top);
    margin-bottom: var(--main-menu__dropdown__margin-bottom);
    margin-left: var(--main-menu__dropdown__margin-left);
    margin-right: var(--main-menu__dropdown__margin-right);
    margin-top: var(--main-menu__dropdown__margin-top);
    float: left; }
    .header-row__dropdown .dropdown-menu {
      right: var(--main-menu__dropdown-menu__right);
      top: var(--main-menu__dropdown-menu__top);
      border-radius: var(--main-menu__dropdown-menu__border-radius);
      overflow: var(--main-menu__dropdown-menu__overflow);
      border: var(--main-menu__dropdown-menu__border); }
      .header-row__dropdown .dropdown-menu__item a {
        background-color: var(--main-menu__dropdown-menu__item__background-color);
        color: var(--main-menu__dropdown-menu__item__color);
        border-bottom: var(--main-menu__dropdown-menu__item__border-bottom); }
      .header-row__dropdown .dropdown-menu__item.active a {
        background-color: var(--main-menu__dropdown-menu__item__background-color--active, var(--main-menu__dropdown-menu__item__background-color));
        color: var(--main-menu__dropdown-menu__item__color--active, var(--main-menu__dropdown-menu__item__color)); }
  .header-row__breadcrumb {
    background: var(--main-menu__breadcrumb__background);
    overflow: var(--main-menu__breadcrumb__overflow);
    border-radius: var(--main-menu__breadcrumb__border-radius);
    height: var(--main-menu__breadcrumb__height);
    margin: var(--main-menu__breadcrumb__margin);
    padding: var(--main-menu__breadcrumb__padding);
    flex: var(--main-menu__breadcrumb__flex);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .header-row__breadcrumb > li:before {
      display: none; }
    .header-row__breadcrumb > li + li:before {
      color: var(--main-menu__breadcrumb__separator__color);
      content: var(--main-menu__breadcrumb__separator__content);
      font-size: var(--main-menu__breadcrumb__separator__font-size);
      padding-bottom: var(--main-menu__breadcrumb__separator__padding-bottom);
      padding-left: var(--main-menu__breadcrumb__separator__padding-left);
      padding-right: var(--main-menu__breadcrumb__separator__padding-right);
      padding-top: var(--main-menu__breadcrumb__separator__padding-top);
      display: inline; }
      @media screen and (min-width: 300px) {
        .header-row__breadcrumb > li + li:before {
          --main-menu__breadcrumb__separator__padding-right: 6px;
          --main-menu__breadcrumb__separator__padding-left: 6px; } }
    .header-row__breadcrumb .breadcrumb-icon {
      background: var(--main-menu__breadcrumb-icon__background);
      box-shadow: var(--main-menu__breadcrumb-icon__box-shadow); }
      .header-row__breadcrumb .breadcrumb-icon:before {
        color: var(--main-menu__breadcrumb-icon__color);
        font-size: var(--main-menu__breadcrumb-icon__font-size); }
    .header-row__breadcrumb .count {
      font-size: var(--main-menu__breadcrumb__count__font-size);
      font-weight: var(--main-menu__breadcrumb__count__font-weight);
      display: inline; }
      @media screen and (min-width: 768px) {
        .header-row__breadcrumb .count {
          display: none; } }
    .header-row__breadcrumb li {
      line-height: var(--main-menu__breadcrumb__element__line-height);
      height: var(--main-menu__breadcrumb__element__height);
      white-space: var(--main-menu__breadcrumb__element__white-space);
      overflow: var(--main-menu__breadcrumb__element__overflow);
      text-overflow: var(--main-menu__breadcrumb__element__text-overflow);
      text-align: var(--main-menu__breadcrumb__element__text-align);
      display: inline; }
      .header-row__breadcrumb li:not(:last-child) {
        display: none; }
        @media screen and (min-width: 768px) {
          .header-row__breadcrumb li:not(:last-child) {
            display: inline; } }
      .header-row__breadcrumb li:not(:nth-last-child(2)) {
        display: none; }
        @media screen and (min-width: 768px) {
          .header-row__breadcrumb li:not(:nth-last-child(2)) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center; } }
      .header-row__breadcrumb li:first-child {
        background-color: var(--main-menu__breadcrumb__badge__background-color);
        border-radius: var(--main-menu__breadcrumb__badge__border-radius);
        height: var(--main-menu__breadcrumb__badge__height);
        text-align: var(--main-menu__breadcrumb__badge__text-align);
        overflow: var(--main-menu__breadcrumb__badge__overflow);
        padding-bottom: var(--main-menu__breadcrumb__badge__padding-bottom);
        padding-left: var(--main-menu__breadcrumb__badge__padding-left);
        padding-right: var(--main-menu__breadcrumb__badge__padding-right);
        padding-top: var(--main-menu__breadcrumb__badge__padding-top);
        margin-bottom: var(--main-menu__breadcrumb__badge__margin-bottom);
        margin-left: var(--main-menu__breadcrumb__badge__margin-left);
        margin-right: var(--main-menu__breadcrumb__badge__margin-right);
        margin-top: var(--main-menu__breadcrumb__badge__margin-top);
        display: block; }
        @media screen and (min-width: 768px) {
          .header-row__breadcrumb li:first-child {
            --main-menu__breadcrumb__badge__background-color: transparent;
            --main-menu__breadcrumb__badge__padding: 0 ;
            --main-menu__breadcrumb__badge__margin-right: 0; } }
        .header-row__breadcrumb li:first-child a {
          color: var(--main-menu__breadcrumb__badge__color); }
      .header-row__breadcrumb li:nth-child(5) {
        display: none; }
    .header-row__breadcrumb a {
      text-shadow: var(--main-menu__breadcrumb__element__a__text-shadow);
      height: var(--main-menu__breadcrumb__element__a__height);
      line-height: var(--main-menu__breadcrumb__element__a__line-height);
      font-size: var(--main-menu__breadcrumb__element__a__font-size); }
      .header-row__breadcrumb a:hover {
        color: var(--brand-color-primary); }

.minimized-right-sidebar .header-row {
  width: var(--main-menu__header-row__width--minimized, var(--main-menu__header-row__width));
  line-height: var(--main-menu__header-row__line-height--minimized, var(--main-menu__header-row__line-height)); }

.minimized-right-sidebar .toggle-bet-slip {
  background-color: var(--main-menu__toggle-bet-slip__background-color--minimized, var(--main-menu__toggle-bet-slip__background-color)); }
  .minimized-right-sidebar .toggle-bet-slip .bet-slip-badge {
    background-color: var(--main-menu__toggle-bet-slip__bet-slip-badge__background-color--minimized, var(--main-menu__toggle-bet-slip__bet-slip-badge__background-color));
    border-radius: var(--main-menu__toggle-bet-slip__bet-slip-badge__border-radius--minimized, var(--main-menu__toggle-bet-slip__bet-slip-badge__border-radius));
    color: var(--main-menu__toggle-bet-slip__bet-slip-badge__color--minimized, var(--main-menu__toggle-bet-slip__bet-slip-badge__color));
    margin-right: var(--main-menu__toggle-bet-slip__bet-slip-badge__margin-right--minimized, var(--main-menu__toggle-bet-slip__bet-slip-badge__margin-right));
    padding: var(--main-menu__toggle-bet-slip__bet-slip-badge__padding--minimized, var(--main-menu__toggle-bet-slip__bet-slip-badge__padding));
    display: inline-block; }
  .minimized-right-sidebar .toggle-bet-slip .bet-slip-item {
    color: var(--main-menu__toggle-bet-slip__bet-slip-item__color--minimized, var(--main-menu__toggle-bet-slip__bet-slip-item__color));
    font-size: var(--main-menu__toggle-bet-slip__bet-slip-item__font-size--minimized, var(--main-menu__toggle-bet-slip__bet-slip-item__font-size)); }
  .minimized-right-sidebar .toggle-bet-slip__icon {
    color: var(--main-menu__toggle-bet-slip__bet-slip-item__icon__color--minimized, var(--main-menu__toggle-bet-slip__bet-slip-item__icon__color));
    font-size: var(--main-menu__toggle-bet-slip__bet-slip-item__icon__font-size--minimized, var(--main-menu__toggle-bet-slip__bet-slip-item__icon__font-size)); }
  .minimized-right-sidebar .toggle-bet-slip:hover {
    background-color: var(--main-menu__toggle-bet-slip__background-color--minimized--hover, var(--main-menu__toggle-bet-slip__background-color)); }
    .minimized-right-sidebar .toggle-bet-slip:hover .bet-slip-badge {
      background-color: var(--main-menu__toggle-bet-slip__bet-slip-badge__background-color--minimized--hover, var(--main-menu__toggle-bet-slip__bet-slip-badge__background-color));
      color: var(--main-menu__toggle-bet-slip__bet-slip-badge__color--minimized--hover, var(--main-menu__toggle-bet-slip__bet-slip-badge__color)); }
    .minimized-right-sidebar .toggle-bet-slip:hover .bet-slip-item, .minimized-right-sidebar .toggle-bet-slip:hover i {
      color: var(--main-menu__toggle-bet-slip__bet-slip-item__color--minimized--hover, var(--main-menu__toggle-bet-slip__bet-slip-item__color)); }

.header-bottom-progress {
  bottom: var(--main-menu__header-bottom-progress__bottom);
  height: var(--main-menu__header-bottom-progress__height);
  width: var(--main-menu__header-bottom-progress__width);
  position: absolute;
  z-index: 9999; }

@keyframes bet-added {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.25); }
  100% {
    transform: scale(1); } }

.about .about__container, .about .about__container__extra {
  display: flex;
  flex-direction: column;
  align-items: center; }

.about__container {
  padding: var(--about__container--padding); }
  .about__container__logo {
    margin: 10px 0 20px;
    height: 49px;
    width: 175px; }
  .about__container__title {
    line-height: 1.3;
    text-align: center; }
  .about__container__extra {
    font-size: var(--about__container__extra--font-size); }
    .about__container__extra__line2 {
      display: flex;
      justify-content: center; }
      .about__container__extra__line2__list {
        display: flex; }
        .about__container__extra__line2__list div {
          margin-left: 4px; }
      .about__container__extra__line2 a {
        text-decoration: underline; }

.modal {
  background-color: var(--modal__background-color);
  box-sizing: var(--modal__box-sizing); }
  .modal-dialog {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: var(--modal-dialog__height);
    position: var(--modal-dialog__position);
    margin-bottom: var(--modal-dialog__margin-bottom);
    margin-left: var(--modal-dialog__margin-left);
    margin-right: var(--modal-dialog__margin-right);
    margin-top: var(--modal-dialog__margin-top); }
  .modal-content {
    background-color: var(--modal-content__background-color);
    border-radius: var(--modal-content__border-radius);
    border-bottom: var(--modal-content__border-bottom);
    border-left: var(--modal-content__border-left);
    border-right: var(--modal-content__border-right);
    border-top: var(--modal-content__border-top); }
  .modal-header {
    align-items: var(--modal-header__align-items);
    background-color: var(--modal-header__background-color);
    color: var(--modal-header__color);
    border-bottom: var(--modal-header__border-bottom);
    border-left: var(--modal-header__border-left);
    border-right: var(--modal-header__border-right);
    border-top: var(--modal-header__border-top);
    padding-bottom: var(--modal-header__padding-bottom);
    padding-left: var(--modal-header__padding-left);
    padding-right: var(--modal-header__padding-right);
    padding-top: var(--modal-header__padding-top);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center; }
    .modal-header i:not(.close) {
      position: relative;
      top: 1px;
      margin-right: 0.5rem; }
    .modal-header:before, .modal-header:after {
      display: none; }
    .modal-header__close {
      color: var(--modal-header__close__color);
      float: var(--modal-header__close__float);
      text-shadow: var(--modal-header__close__text-shadow);
      opacity: var(--modal-header__close__opacity);
      outline: var(--modal-header__close__outline);
      margin-bottom: var(--modal-header__close__margin-bottom);
      margin-left: var(--modal-header__close__margin-left);
      margin-right: var(--modal-header__close__margin-right);
      margin-top: var(--modal-header__close__margin-top); }
  .modal-body {
    background-color: var(--modal-body__background-color);
    height: var(--modal-body__height);
    padding-bottom: var(--modal-body__padding-bottom);
    padding-left: var(--modal-body__padding-left);
    padding-right: var(--modal-body__padding-right);
    padding-top: var(--modal-body__padding-top); }
  .modal-lg {
    width: var(--modal-lg__width);
    height: var(--modal-lg__height);
    margin: var(--modal-lg__margin); }
  .modal-main {
    height: var(--modal-main__height);
    overflow: var(--modal-main__overflow);
    position: var(--modal-main__position);
    background-color: var(--modal-main__background-color); }
    .modal-main .modal-section {
      background-color: var(--modal-section__background-color);
      display: var(--modal-section__display); }
      .modal-main .modal-section-padded {
        padding: var(--modal-section-padded__padding); }
      .modal-main .modal-section.sports-rules-tabs {
        display: none; }
      .modal-main .modal-section.active, .modal-main .modal-section.sports-rules-tabs.active {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center; }
      .modal-main .modal-section.rules__sports-section {
        flex-direction: row; }
    .modal-main h3 {
      display: var(--modal-main__title__display);
      font-size: var(--modal-main__title__font-size);
      text-transform: var(--modal-main__title__text-transform);
      border-bottom: var(--modal-main__title__border-bottom);
      border-left: var(--modal-main__title__border-left);
      border-right: var(--modal-main__title__border-right);
      border-top: var(--modal-main__title__border-top);
      margin-bottom: var(--modal-main__title__margin-bottom);
      margin-left: var(--modal-main__title__margin-left);
      margin-right: var(--modal-main__title__margin-right);
      margin-top: var(--modal-main__title__margin-top);
      padding-bottom: var(--modal-main__title__padding-bottom);
      padding-left: var(--modal-main__title__padding-left);
      padding-right: var(--modal-main__title__padding-right);
      padding-top: var(--modal-main__title__padding-top); }
    .modal-main h4 {
      background-color: var(--modal-main__subtitle__background-color);
      color: var(--modal-main__subtitle__color);
      display: var(--modal-main__subtitle__display);
      font-size: var(--modal-main__subtitle__font-size);
      text-transform: var(--modal-main__subtitle__text-transform);
      margin-bottom: var(--modal-main__subtitle__margin-bottom);
      margin-left: var(--modal-main__subtitle__margin-left);
      margin-right: var(--modal-main__subtitle__margin-right);
      margin-top: var(--modal-main__subtitle__margin-top);
      padding-bottom: var(--modal-main__subtitle__padding-bottom);
      padding-left: var(--modal-main__subtitle__padding-left);
      padding-right: var(--modal-main__subtitle__padding-right);
      padding-top: var(--modal-main__subtitle__padding-top); }
    .modal-main p, .modal-main li {
      font-size: var(--modal-main__text__font-size); }
    .modal-main p span, .modal-main h3 span, .modal-main h4 span {
      font-weight: var(--modal-main__bold__font-weight);
      color: var(--modal-main__bold__color);
      margin-bottom: var(--modal-main__bold__margin-bottom);
      margin-left: var(--modal-main__bold__margin-left);
      margin-right: var(--modal-main__bold__margin-right);
      margin-top: var(--modal-main__bold__margin-top); }
    .modal-main h4 span {
      color: var(--modal-main__subtitle--num__color); }
    .modal-main table {
      font-size: var(--modal-main__table__font-size);
      border-collapse: var(--modal-main__table__border-collapse);
      border-spacing: var(--modal-main__table__border-spacing);
      text-align: var(--modal-main__table__text-align);
      width: var(--modal-main__table__width);
      margin-bottom: var(--modal-main__table__margin-bottom);
      margin-left: var(--modal-main__table__margin-left);
      margin-right: var(--modal-main__table__margin-right);
      margin-top: var(--modal-main__table__margin-top); }
      .modal-main table th {
        background-color: var(--modal-main__table__header__background-color);
        border-bottom: var(--modal-main__table__header__border-bottom);
        border-left: var(--modal-main__table__header__border-left);
        border-right: var(--modal-main__table__header__border-right);
        border-top: var(--modal-main__table__header__border-top);
        padding-bottom: var(--modal-main__table__header__padding-bottom);
        padding-left: var(--modal-main__table__header__padding-left);
        padding-right: var(--modal-main__table__header__padding-right);
        padding-top: var(--modal-main__table__header__padding-top); }
      .modal-main table td {
        overflow-wrap: var(--modal-main__table__data__overflow-wrap);
        border-bottom: var(--modal-main__table__data__border-bottom);
        border-left: var(--modal-main__table__data__border-left);
        border-right: var(--modal-main__table__data__border-right);
        border-top: var(--modal-main__table__data__border-top);
        padding-bottom: var(--modal-main__table__data__padding-bottom);
        padding-left: var(--modal-main__table__data__padding-left);
        padding-right: var(--modal-main__table__data__padding-right);
        padding-top: var(--modal-main__table__data__padding-top); }

.no-header .modal-header {
  display: none; }

@media (max-width: 480px) {
  .modal-lg {
    width: 100%;
    height: 100vh;
    margin: 0; }
  .modal-main {
    padding: 1.5rem;
    height: calc(100vh - 8rem);
    overflow: auto; }
  .modal-tabs-list li {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.1; }
  .modal-main .modal-section.rules__sports-section {
    flex-direction: column; }
  .modal-main .modal-section.active {
    width: 100%; } }

@media only screen and (max-width: 48rem) {
  .modal-body {
    width: 100vw; } }

/* used by ngPrint directive */
.receipt-container {
  color: var(--font-color-primary);
  text-align: left;
  padding: 1rem 4rem;
  box-sizing: border-box;
  width: 100%; }
  .receipt-container__qr {
    margin: 2rem; }
  .receipt-container__date {
    margin-bottom: 1.5rem; }
  .receipt-container__header__separator {
    width: 100%;
    height: 6px;
    margin: 12px 0 24px 0;
    background-color: var(--font-color-primary); }
  .receipt-container__header__event {
    color: var(--font-color-primary);
    font-size: 1.1rem;
    font-weight: bold;
    width: 100%; }
  .receipt-container__header__time {
    font-size: 0.8rem; }
  .receipt-container__header__bet {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    width: 100%; }
    .receipt-container__header__bet__odds {
      color: var(--font-color-primary);
      margin-left: 2rem; }
  .receipt-container__footer {
    font-size: 0.65rem; }

@media print {
  body * {
    display: none; }
  #printSection {
    box-sizing: border-box;
    padding: 4mm 8mm;
    display: block;
    max-width: 57mm; }
    #printSection * {
      word-break: break-all;
      display: unset; }
  .receipt-container * {
    font-size: 6pt !important;
    display: block !important;
    padding: 0 !important;
    margin: 0 auto !important; }
  .receipt-container__qr {
    margin: 2mm; }
  .receipt-container__date {
    margin-bottom: 1.1rem !important;
    font-size: 0.85rem; }
  .receipt-container__header__event {
    color: black;
    font-size: 1.1rem;
    margin-bottom: 1rem; }
  .receipt-container__header__bet {
    font-size: 0.9rem; }
  .receipt-container__footer {
    font-size: 0.45rem; } }

html {
  position: absolute;
  width: 100%;
  overflow: hidden; }

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  box-shadow: none;
  font-size: 13px;
  height: 30px;
  padding: 5px 10px;
  line-height: 19px;
  transition: none;
  border-radius: 3px;
  outline: none !important; }

.btn.btn-xs {
  height: 20px;
  min-width: 20px;
  padding: 1px 4px 1px 4px;
  font-size: 10px;
  line-height: 16px; }

.btn.btn-sm {
  height: 24px;
  min-width: 26px;
  padding: 3px 5px 2px 5px;
  font-size: 11px;
  line-height: 17px; }

.btn.btn-sm .fa {
  font-size: 11px;
  line-height: 10px; }

.btn,
.btn-group {
  position: relative;
  float: left;
  min-width: 32px; }

.btn-default,
.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus,
.btn.small-toggle-btn:hover,
.dropdown-menu > li > a:hover {
  box-shadow: none; }

.btn.small-toggle-btn {
  width: 32px;
  padding: 0;
  cursor: pointer; }

.dropdown.open,
.dropdown:hover {
  z-index: 10; }

.dropdown-menu {
  font-size: 13px;
  text-align: left;
  margin: -1px 0 0;
  box-shadow: none;
  border-radius: 0;
  padding: 0; }

.sidebar-right .dropdown-menu {
  max-height: 300px;
  overflow: auto;
  right: initial; }

.bet-contents .dropdown-menu {
  top: 20px; }

.bet-line-dropdown,
.bet-type-dropdown {
  position: relative;
  display: inline; }

.dropdown-menu > li > a {
  width: 100%;
  padding: 5px 20px;
  line-height: 20px; }

.dropdown-menu .divider {
  background-color: #00010d;
  overflow: visible; }

.dropdown-menu .divider .divider-label {
  float: left;
  margin: -6px 0 0 15px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 12px;
  background-color: var(--button-default-color);
  color: var(--font-color-secondary); }

.daterangepicker.dropdown-menu {
  padding: 5px;
  font-size: 14px; }

.daterangepicker td.off {
  background: none; }

.daterangepicker td,
.daterangepicker th {
  border-radius: 0; }

.daterangepicker td.start-date,
.daterangepicker td.end-date {
  border-radius: 0; }

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background: none; }

.ext-tabs {
  float: left;
  height: 30px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none; }

.ext-tabs > li {
  float: left; }

.ext-tabs > li > a {
  float: left;
  height: 30px;
  width: auto;
  padding: 0 20px;
  line-height: 29px;
  border-left: 0; }

.bet-type-dropdown .btn-default,
.bet-type-dropdown .btn-default:focus {
  line-height: 35px;
  border: 0; }

.tab-content {
  display: block;
  width: 100%; }

.tab-content .title {
  font-weight: bold; }

.tab-content .tab {
  margin-left: 20px; }

.tab-content .texts p {
  font-size: 13px;
  line-height: 16px; }

.stacked-labels {
  margin-bottom: 20px; }

.stacked-labels,
.stacked-labels > label {
  width: auto;
  display: block; }

.stacked-labels label {
  width: auto;
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 0;
  position: relative;
  margin: 0;
  cursor: pointer; }

.stacked-labels p {
  font-size: 13px; }

label {
  position: relative; }

label input[type='radio'],
label input[type='checkbox'] {
  height: 14px;
  width: 14px;
  margin: 0 5px 0 0;
  padding: 0;
  opacity: 0;
  border: 0;
  outline: 0;
  filter: alpha(opacity=0);
  z-index: 2;
  position: relative;
  cursor: pointer; }

label input[type='radio'] + span,
label input[type='checkbox'] + span {
  position: absolute;
  left: 0;
  top: 8px;
  height: 14px;
  width: 14px;
  margin: 0;
  background-repeat: no-repeat;
  z-index: 1; }

label input[type='radio'] + span {
  border-radius: 10px; }

.inner-padding {
  padding: 0 20px 20px; }

.inner-padding__container {
  display: contents; }

.inner-padding .inner-padding__loader {
  display: none;
  justify-content: center;
  padding: 15px 0; }

.loading-next-page .inner-padding__loader {
  display: flex; }

.event--container .inner-padding {
  padding: 20px; }

.sidebar-module {
  height: auto;
  width: 100%;
  padding: 20px; }

.separator .separator-label {
  font-size: 12px; }

.spinner {
  background-color: #193441;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  display: none; }

.spinner.active {
  display: block; }

.spinner:after {
  background-color: #ffcc33;
  content: '';
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: auto;
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out; }

@keyframes sk-scaleout {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }

.input-group-addon {
  min-height: 30px;
  padding: 4px 10px;
  border-radius: 0; }

.input-group-btn > .btn {
  height: 100%;
  margin: 0;
  width: 100%; }

.form-control {
  clear: both;
  height: 30px;
  font-size: 13px;
  box-shadow: none;
  transition: none; }

.form-control,
.form-control:focus {
  box-shadow: none; }

input.form-control {
  padding: 0 5px; }

input.form-control.ng-invalid {
  border-color: red; }

.form-control + .input-group-addon {
  border-left: 0; }

.link {
  white-space: nowrap; }

.search-box {
  top: -5.5rem; }

.schedule-box {
  min-height: 10.625rem;
  top: -12rem; }

.drop-down-box.-open {
  top: 2.5625rem;
  max-height: calc(100vh - 41px); }

.scheduled-events--header,
.search-box--header {
  padding: 5px 15px 0; }

.scheduled-events--desc {
  padding: 0 15px;
  font-size: smaller; }

.scheduled-events--container .scrollbar {
  height: calc(100% - 100px); }

.scheduled-events--container .input-group {
  width: 100%; }

.scheduled-events--container .cancel {
  position: absolute;
  top: 7px;
  right: 11px;
  font-size: 16px;
  z-index: 11;
  color: inherit; }

.drop-down-box.schedule-box,
.drop-down-box.search-box {
  padding: 0; }

.drop-down-box.schedule-box.-open {
  min-height: 195px; }

.event-container {
  height: auto; }

.event-container .scrollbar {
  padding: 15px; }

.schedule-box:not(.-open) .event-container {
  margin-top: -110px; }

/* Drop down box */
.drop-down-box {
  left: 0;
  padding: 10px 15px;
  position: absolute;
  background: var(--background-color-primary);
  border-bottom: 1px solid #00010d;
  transition: top 0.2s;
  width: 100%;
  z-index: 3;
  overflow: auto; }

.drop-down-box .search-text {
  text-align: center;
  margin: 20px 80px;
  padding-bottom: 5px;
  font-size: 14px; }

/* list group */
.list-group-top,
.list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px; }

.list-group-item-top,
.list-group-item {
  background-color: transparent;
  border: 0 none;
  border-radius: 0 !important;
  padding: 0; }

.list-group-item .list-group-item {
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 3px;
  padding: 5px; }

.list-group-item > a {
  display: block; }

.list-group-item .date {
  float: left;
  width: 95px; }

.list-group-item .score {
  float: left;
  width: 30px; }

.list-group-item .teams {
  float: left;
  width: calc(100% - 150px); }

.list-group-item .star {
  float: right;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  top: 15px;
  right: 5px; }

.search-item-header {
  margin: 12px 0 7px;
  padding: 7px 3px 0; }

.search-item-header .star {
  color: var(--brand-color-primary);
  position: relative;
  top: 5px;
  right: 7px; }

.search-bar-container {
  display: block;
  transition: top 0.2s ease-out;
  position: sticky;
  top: 0;
  padding: 15px;
  background-color: inherit;
  z-index: 1; }

.search-bar-container input {
  font-size: 13px; }

.search-bar {
  height: 36px;
  border-radius: 3px;
  font-size: 1.4rem;
  padding: 5px 10px; }

.schedule-box .search-bar-container .btn-link,
.search-box .search-bar-container .btn-link {
  position: absolute;
  right: 4px;
  top: 17px;
  color: inherit; }

.input-group-addon-full-width {
  width: 100%;
  text-align: left;
  white-space: inherit; }

.input-group-btn {
  vertical-align: bottom; }

.form-control.inline {
  display: inline; }

.big {
  font-size: 15px !important; }

html {
  height: 100%;
  min-height: 100%; }

body {
  position: relative;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  line-height: 1.6; }

.main {
  height: 100%;
  min-height: 100%;
  width: 100%;
  overflow: hidden; }

.sidebar-left {
  float: left;
  min-height: 100%;
  position: relative;
  z-index: 50; }

::placeholder {
  font-size: 12px; }

.sidebar-main .sidebar-include > .sidebar-module {
  padding: 0 20px;
  min-height: 50px; }

.tab-content .tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative; }

.tab-content .inner-padding {
  padding: 20px 10px;
  height: 100%; }

.sidebar-main {
  width: 100%;
  height: 100%; }

.sidebar-right .sidebar-main {
  overflow: hidden; }

.tab-content .alert-danger {
  border-radius: 0;
  padding: 5px 10px;
  color: #132731;
  text-shadow: 1px 1px #fff;
  margin: 5px 0 15px; }

/* right side bar */
.tab-content .panel {
  border-radius: 0;
  position: relative;
  border: 1px solid var(--brand-color-primary);
  margin-bottom: 10px; }

.tab-content .panel.inactive:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5; }

.tab-content .panel.panel-parlay {
  margin-bottom: 0; }

.tab-content .panel .panel-heading {
  padding: 0 10px; }

.tab-content .panel .panel-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 2px; }

.tab-content .panel .panel-body {
  font-size: 13px;
  padding: 5px 10px;
  border-top-width: 0;
  border-bottom-width: 0;
  margin: 0; }

.tab-content .stacked-labels {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 0.75rem; }

.tab-content .panel.inactive:after {
  background: rgba(25, 52, 65, 0.5); }

.input-group {
  width: 100%; }

.input-group input[type='number']::-webkit-outer-spin-button,
.input-group input[type='number']::-webkit-inner-spin-button {
  margin: 0; }

.bet-footer {
  margin: 5px -10px -5px; }

.bet-footer .input-group + .input-group {
  display: none; }

.bet-footer .input-group {
  display: flex; }

.bet-footer .input-group-addon {
  flex-grow: 1; }

.bet-footer .input-group-btn {
  flex-shrink: 0;
  width: 25%; }

.tab-content .listed-bet {
  padding-top: 6px; }

.sidebar-left .sidebar-top {
  align-items: center;
  border-bottom: 1px solid var(--background-color-primary);
  display: flex;
  height: 2.625rem;
  justify-content: center;
  flex-shrink: 0; }

.badge {
  line-height: 13px;
  height: 17px;
  margin-left: 2px;
  margin-bottom: 4px;
  text-shadow: none; }

.live-message {
  font-size: 13px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-weight: bold;
  top: 2px;
  height: 24px;
  padding: 0 20px; }

.sidebar-top .badge {
  font-size: 12px;
  position: relative;
  top: 2px;
  margin-left: 4px; }

.wrapper {
  position: relative;
  min-height: 100%; }

.header-container {
  position: relative;
  height: 42px;
  transition: height 0.2s ease; }

.sidebar-right .sidebar-top .dropdown {
  margin: -1px -1px 0 0;
  z-index: 999;
  max-width: 230px; }

#loading-bar .bar {
  position: relative; }

.header-bottom-bar {
  height: 100%;
  overflow: hidden;
  width: 41.8369%; }

.content {
  height: 100%;
  min-height: 100%;
  margin: 0;
  position: relative; }

.footer {
  width: 100%;
  padding: 5px 10px;
  background-color: var(--background-color-secondary);
  border-top: 1px solid #00010d;
  font-size: 11px; }

.history {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1041; }

.history .scrollbar {
  padding: 15px;
  height: calc(100vh - 40px);
  overflow: auto; }

.toolbar {
  font-size: 13px;
  float: left;
  width: 100%;
  padding: 10px 10px 0; }

.toolbar form {
  margin: 0; }

.toolbar-field {
  width: auto;
  margin: 0 1rem 10px 0;
  padding: 0;
  float: left; }

.toolbar-field p {
  display: inline-block;
  color: var(--font-color-primary); }

.toolbar-submit {
  margin-top: 30px; }

.btn.toolbar-close {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  float: right;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto; }

.toolbar-close.btn-default:hover,
.toolbar-close.btn-default:active:hover,
.toolbar-close.btn-default:active:focus {
  background-color: transparent;
  border: 0 none;
  box-shadow: none; }

.toolbar-close:hover i::before {
  color: var(--brand-color-primary); }

.toolbar-field .form-control {
  background-color: transparent;
  border: 1px solid var(--font-color-primary);
  color: var(--font-color-primary);
  width: 200px; }

.toolbar-field .glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto; }

.toolbar-field .glyphicon-arrow-left:before {
  content: '\f0d9'; }

.toolbar-field .glyphicon-arrow-right:before {
  content: '\f0da'; }

.toolbar-field .datetimepicker {
  margin: 0 10px; }

.toolbar-field a.dropdown-toggle {
  width: 150px;
  text-align: left;
  margin: 0; }

.toolbar-field a.dropdown-toggle .fa {
  line-height: 20px;
  float: right; }

.toolbar-field a.dropdown-toggle > span {
  float: left; }

.toolbar-field .dropdown-menu {
  background-color: var(--background-color-secondary); }

.toolbar-field .dropdown-menu > li > a {
  background-color: transparent;
  color: var(--font-color-primary); }

.toolbar-field:nth-of-type(2) .dropdown-menu > li:nth-child(odd):last-child {
  width: 100%;
  text-align: center; }

.sport-dropdown li {
  float: left;
  width: 50%; }

.sport-dropdown {
  width: 330px; }

.datetimepicker .table td,
.datetimepicker .table th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: none; }

.datetimepicker {
  width: auto; }

.datetimepicker .active,
.datetimepicker .active:hover,
.datetimepicker .active.disabled,
.datetimepicker .active.disabled:hover {
  text-shadow: none; }

.table-wrapper table p {
  margin: 0; }

.btn-group > .btn {
  margin: 0; }

.btn-group .btn + .btn {
  margin-left: -1px; }

.table-wrapper table {
  border-top: 0;
  font-size: 13px; }

.table-wrapper .table-bordered tr th {
  white-space: nowrap;
  padding: 5px 8px; }

.table-wrapper .table tbody tr td {
  vertical-align: middle;
  padding: 5px 8px; }

.table-wrapper tr td.child3 {
  width: 47%; }

.table-wrapper tr td.child4 {
  width: 47%; }

.table-wrapper tr td p {
  margin: 0;
  white-space: nowrap; }

.table-wrapper tr td.child3 p,
.table-wrapper tr td.child4 p {
  white-space: inherit; }

.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.overflow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2; }

.content-inner .spinner {
  background: none; }

.content-inner .time {
  height: 25px;
  padding: 3px 15px; }

/* the single event page */
.panel {
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 1px; }

.panel .panel-heading {
  align-items: center;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  height: 28px;
  padding: 0 0.75rem; }

.panel .close {
  color: var(--font-color-secondary);
  float: none;
  font-size: 1rem;
  line-height: 0;
  margin-right: 0.5rem;
  opacity: 1;
  outline: none;
  text-shadow: none; }

.panel .close:hover {
  opacity: 0.5;
  transition: opacity 0.2s; }

.panel .panel-title {
  font-size: 0.75rem;
  text-transform: uppercase; }

.panel .panel-title .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: -5px -2px; }

.panel .panel-title > span {
  vertical-align: top;
  line-height: 16px; }

.panel .panel-favorite {
  margin-left: auto;
  position: relative;
  top: 1px; }

.group .panel-body .inner-padding {
  padding: 5px; }

.offerings-header-container {
  display: flex;
  width: 100%; }

.offerings-header-3way {
  flex: 1 1 33%;
  width: 33%; }

.offerings {
  border: 0; }

.offerings + .offerings {
  border-left: 0; }

.offerings > div,
.offerings > div > div {
  text-align: center;
  padding: 0; }

.offerings .link {
  transition: all 0.2s ease; }

.offerings .link.inactive {
  cursor: default; }

.offerings .link.disabled::after {
  content: '';
  height: 100%;
  width: 100%;
  background-color: var(--odds-background-color--disabled);
  opacity: var(--odds-opacity--disabled);
  position: absolute;
  top: 0;
  left: 0;
  cursor: default; }

.link.disabled .emphasis,
.link.disabled .odds {
  color: var(--odds-color--disabled);
  text-decoration: inherit; }

.offerings .link.disabled,
.offerings .link.inactive {
  text-decoration: line-through; }

.offerings .link span:last-child {
  white-space: nowrap;
  overflow: hidden; }

.offerings .horizontal .link {
  min-height: 54px;
  line-height: 52px; }

.offerings .vertical .link > span.single {
  width: 77px; }

.offerings .link .odds-description {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* when the price is alone in the offerings box, it needs to be centred. */
.offerings .emphasis:first-child,
.offerings .odds-locked:first-child {
  margin: auto; }

.more .fa-lock {
  font-size: 18px;
  vertical-align: middle; }

.more .link {
  display: block;
  margin: 5px 0; }

.message-priority > div + div {
  display: none; }

/* Offerings in Event Summary */
.offerings .two-way,
.offerings .three-way {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between; }

.offerings .two-way .odds {
  flex: 1 1 50%;
  width: 50%;
  flex-wrap: nowrap; }

.offerings .three-way .odds {
  flex: 1 1 33%;
  width: 33%; }

.odds .link {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-around;
  white-space: normal;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer; }

.two-way .odds .link,
.three-way .odds .link {
  padding: 4px; }

.two-way.show-more .btn-link {
  background: inherit;
  color: inherit;
  line-height: 0;
  width: 100%;
  text-align: left;
  font-size: x-small;
  padding-top: 5px; }

.two-way--top-line .link {
  font-weight: bold; }

.odds .link .emphasis {
  flex: 0 0 auto;
  text-align: right; }

.sidebar-include {
  width: 350px;
  height: 100%; }

/* Offerings in single event */
.single-event-body .odds .link {
  justify-content: space-between; }

.single-event-body .odds .link span:first-child {
  flex-basis: 60.5%;
  text-align: left;
  margin-right: 3px;
  flex-grow: 1; }

.offerings.offerings--two-way-contest,
.offerings.offerings--three-way-contest,
.offerings--two-way-contest-inline {
  display: grid;
  grid-template-columns: repeat(var(--offerings--contest-type-columns), minmax(150px, 1fr));
  gap: 0.4rem; }

.offerings--two-way-contest show-more,
.offerings--three-way-contest show-more {
  grid-column: 1 / -1; }

.offerings--two-way-contest-inline {
  --offerings--contest-type-columns: 1; }

.offerings.offerings--two-way-contest-inline .two-way .odds {
  flex: 1 1 100%;
  width: 100%; }

.offerings--two-way-contest-inline .two-way {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  border-radius: 3px;
  overflow: hidden; }

.offerings--two-way-contest .two-way,
.offerings--three-way-contest .three-way {
  flex-flow: column; }

.offerings--two-way-contest .two-way .odds,
.offerings--three-way-contest .three-way .odds {
  width: 100%;
  flex: 1 1 100%; }

.offerings--two-way-contest .two-way .odds .link,
.offerings--three-way-contest .three-way .odds .link {
  border-radius: 0;
  border-color: transparent; }

.offerings--two-way-contest-inline .two-way .odds .link {
  border: none;
  border-radius: 0; }

.offerings--two-way-contest .two-way .odds:first-child .link,
.offerings--three-way-contest .three-way .odds:first-child .link {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.offerings--two-way-contest .two-way .odds:last-child .link,
.offerings--three-way-contest .three-way .odds:last-child .link {
  border-top-color: var(--background-color-secondary);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }

.offerings--three-way-contest .three-way .odds:nth-child(2) .link {
  border-top-color: var(--background-color-secondary); }

/**
 ******************* LEFT SIDEBAR ********************
 */
.sidebar-left {
  transition: 0.3s ease-in-out width;
  width: 240px; }

.minimized-left-sidebar .sidebar-left {
  width: 0px; }

.sidebar-left {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 30px; }

@media only screen and (max-width: 768px) {
  .drop-down-box {
    left: 59px;
    width: calc(100vw - 59px); } }

/**
 ******************* RIGHT SIDEBAR ********************
 */
@media only screen and (max-width: 768px) {
  .main .sidebar-right {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 0;
    z-index: 101; }
  .sidebar-left:not(.sidebar-left--additional) .sidebar-top {
    display: none; } }

/**
 ******************* SIDEBAR ********************
 */
.minimized-left-sidebar .sidebar-left .sidebar-top {
  justify-content: center;
  padding: 0; }

@media only screen and (max-width: 768px) {
  .sidebar-top {
    margin-top: -1px; }
  .sidebar-top .live-message {
    top: 44px; } }

/**
 ******************* WRAPPER ********************
 */
.wrapper {
  transition: 0.3s ease-in-out all;
  margin: 0 350px 0 240px; }

.minimized-left-sidebar .wrapper {
  margin-left: 60px; }

.minimized-right-sidebar .wrapper {
  margin-right: 0; }

.header-row .btn.small-toggle-btn {
  width: 30px; }

.header-row .btn.small-toggle-btn .fa {
  margin-right: 0; }

/* max-width 366px */
@media only screen and (max-width: 1366px) {
  body:not(.minimized-right-sidebar) .toolbar-field:first-child {
    width: 100%; } }

/* max-width 992px */
@media only screen and (max-width: 992px) {
  .wrapper {
    transition: none; }
  .content-inner .inner-padding {
    padding: 0 2px; }
  .content-inner .panel-body .inner-padding {
    padding: 5px; }
  .offerings .link {
    padding: 0 5px; }
  .offerings .vertical .link {
    min-height: 27px; }
  .more .fa-lock {
    font-size: inherit; }
  .history {
    z-index: 15; }
  .history table td,
  .history table th {
    padding: 5px; }
  body:not(.minimized-left-sidebar) .toolbar-field:first-child,
  body:not(.minimized-right-sidebar) .toolbar-field,
  body:not(.minimized-right-sidebar) .toolbar-field .form-control,
  body:not(.minimized-right-sidebar) .toolbar-field a.dropdown-toggle,
  body:not(.minimized-right-sidebar) .toolbar-field .dropdown {
    width: 100%; }
  body:not(.minimized-right-sidebar) .toolbar-field .dropdown {
    margin: 0; }
  body:not(.minimized-right-sidebar) .toolbar-field .mobHidden {
    display: none; }
  body:not(.minimized-right-sidebar) .toolbar-field:last-child button {
    margin: 10px 0;
    width: 100%; }
  .offerings .link span:last-child {
    overflow-wrap: break-word;
    white-space: normal; }
  .offerings .link .odds-description {
    font-size: 10px; }
  .search-box .search-text {
    margin: 20px 5px; } }

/* Converts "Score" to "S" on small screens */
.-collapsable {
  display: inherit; }

.-uncollapsable {
  display: none; }

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-left: 60px; }
  .minimized-left-sidebar .wrapper {
    margin-right: 0; }
  .wrapper .header-container {
    z-index: 100; }
  .wrapper .content {
    position: absolute;
    top: 0;
    padding-top: 30px;
    width: 100%; }
  .window {
    position: static; }
  .tab-content .inner-padding {
    padding: 10px; }
  .tab-content .inner-padding .btn-group {
    margin: 0;
    width: 100%; }
  .header-row .btn.small-toggle-btn {
    width: 42px; }
  .header-row .btn.small-toggle-btn .fa {
    margin-right: 0; }
  .header-row .dropdown-menu {
    top: 42px; }
  .sidebar-left,
  .wrapper .content {
    padding-top: 42px; }
  .panel .panel-heading {
    padding: 2px 7px; }
  .toolbar-field a.dropdown-toggle {
    width: 100px; }
  .header-row .main-dropdown .btn {
    cursor: pointer; }
  .btn.dropdown-toggle .fa {
    position: relative;
    top: 2px; }
  .dropdown-menu-right {
    width: 100%;
    display: none;
    position: fixed;
    top: 39px;
    height: 100vh; }
  .open > .dropdown-menu-right {
    display: block; }
  .dropdown-menu-right > li > a {
    padding: 10px 20px; }
  .dropdown-menu-right a .fa {
    position: relative;
    top: 1px;
    margin-right: 7px; }
  .-collapsable {
    display: none; }
  .-uncollapsable {
    display: block; }
  .single-event-body .panel .panel-body .odds .link {
    padding: 0 0.5rem; } }

@media only screen and (max-width: 640px) {
  .toolbar-field {
    width: 45%;
    margin: 0 5% 10px 0; }
  .toolbar-field p {
    margin-bottom: 0; }
  .toolbar div:nth-child(3) {
    margin-right: 0; }
  .toolbar-field .form-control, .toolbar-field a.dropdown-toggle {
    width: 100%; }
  .toolbar-submit {
    margin-top: 80px; }
  .single-event-body .panel .panel-body .odds .link {
    padding: 0 5px; } }

@media only screen and (max-width: 400px) {
  .toolbar {
    padding: 0 10px 10px; }
  .toolbar-field {
    width: 100%;
    display: block;
    margin: 0 0 10px 0; } }

@media only screen and (max-width: 480px) {
  .wrapper,
  .minimized-left-sidebar .wrapper,
  .wrapper .header-container {
    margin-left: 0; }
  .drop-down-box {
    left: 0;
    width: 100%; }
  .schedule-box {
    top: -500px; } }

@media only screen and (max-width: 370px) {
  #event-stat-score,
  #event-stat-score-top {
    display: none; } }

@media only screen and (max-width: 350px) {
  .sidebar-right,
  .sidebar-include {
    width: 320px; }
  #event-stat-score {
    display: none; } }

.btn.history--close:hover {
  color: inherit; }

@media (max-width: 480px) {
  .sidebar-left {
    width: 240px; }
  .minimized-left-sidebar .search-open .search-box {
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    top: 42px; }
  .minimized-left-sidebar .search-box .input-group {
    display: block;
    height: 30px; }
  .minimized-left-sidebar .search-box .scrollbar {
    height: calc(100% - 30px); }
  .minimized-left-sidebar .search-box .fa-times {
    position: absolute;
    top: 7px;
    right: 11px;
    font-size: 16px;
    z-index: 11; }
  .dropdown .btn.main-nav-mobile__live-button {
    background-color: #FFF;
    border: 0 none;
    border-radius: 3px;
    color: #666;
    height: auto;
    line-height: 1;
    margin: 9px 14px 0 0;
    min-width: 0;
    padding: 4px 6px;
    width: auto; } }

@media (min-width: 480px) {
  .main-nav-mobile__live-button {
    display: none; } }

/****************/
/*  Stat Score  */
/****************/
#event-stat-score .STATSCORE__Tracker {
  text-align: center;
  background-color: black; }

#iframe-event-tracker-top, #iframe-event-tracker {
  max-width: 880px; }

:root {
  /* Odds */
  --odds-opacity--disabled: .4;
  --odds-color--disabled: antiquewhite;
  --odds-background-color--disabled: black; }

::selection {
  background-color: #bbb;
  color: #ddd; }

::-webkit-scrollbar {
  border-left: 1px solid #00010D;
  width: 9px; }

::-webkit-scrollbar-track-piece {
  background-color: #333333;
  border-left: 1px solid #00010D; }

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-top: 1px solid #00010D;
  border-left: 1px solid #00010D;
  width: 8px; }

.emphasis {
  color: var(--highlight-color-primary); }

a,
a:active,
a:focus,
a:hover,
a:visited {
  color: var(--button-default-color); }

.sidebar .demo-grid > div,
.sidebar-demo-raster > div,
.small-toggle-btn {
  background: #444 none; }

/* Buttons */
.btn-default,
.btn-default:focus,
.btn-default.focus {
  background-color: transparent;
  border: 1px solid #00010D;
  color: var(--button-default-color); }

.btn-default[disabled]:hover,
.btn-default[disabled] {
  background-color: transparent; }

.btn-default:hover,
.btn-default:active,
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default,
.open > .dropdown-toggle.btn-default:hover,
.open > .dropdown-toggle.btn-default:focus,
.header-row .btn.active {
  background-color: var(--brand-color-primary);
  border-color: #000;
  color: var(--button-color-secondary); }

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: var(--button-color-secondary); }

.btn-primary {
  background-color: var(--brand-color-primary);
  border: 1px solid #000;
  color: var(--button-color-secondary); }

.btn-primary:hover {
  background-color: var(--brand-color-secondary);
  border: 1px solid #000;
  color: var(--button-color-secondary); }

/* Dropdown menu */
.dropdown-menu {
  background-color: var(--background-color-secondary);
  border: 1px solid #00010D; }

.dropdown-menu li {
  cursor: pointer; }

/* TODO add variables to this background */
.dropdown-menu > li > a {
  background-color: #AAA;
  border-bottom: 1px solid #000;
  cursor: pointer; }

.dropdown-menu > li > a:hover {
  color: #000;
  background-color: #FFF; }

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #000;
  background-color: var(--brand-color-primary); }

.dropdown-menu > .active > a:hover {
  cursor: not-allowed; }

.dropdown-menu > li:last-child > a {
  border-bottom: 0 none; }

.ext-tabs {
  border-left: 1px solid #00010D;
  border-bottom: 1px solid #00010D; }

.ext-tabs > li {
  background-color: #193441; }

.ext-tabs > li > a {
  border: 1px solid #00010D;
  color: #bbb; }

.ext-tabs > li.active > a,
.ext-tabs > li > a:hover {
  background-color: #3E606F;
  border-bottom: 1px solid #3E606F; }

.label-white {
  background-color: #ccc; }

.input-group-btn,
.input-group-addon {
  background-color: #222;
  color: #FFF; }

.search-bar-container .search-bar {
  padding: 0 12px; }

.warning {
  color: #dff0d8; }

.danger {
  color: #f2dede; }

.success {
  color: #3c763d; }

#loading-bar .bar {
  background-color: var(--brand-color-primary); }

.header-bottom-bar {
  background-color: #FCFFF5; }

.history {
  background-color: var(--background-color-primary); }

.toolbar {
  background-color: var(--background-color-tertiary);
  border: 1px solid #000;
  margin-bottom: 1rem; }

.table-wrapper > header {
  background-color: #193441;
  border: 1px solid #00010D; }

.table-bordered {
  border: 1px solid #00010D; }

.table-wrapper .table-bordered th {
  background-color: #23414E; }

.table-wrapper .table-bordered tr th {
  border: 1px solid #00010D; }

.table-wrapper tr td {
  background-color: #2E4A56;
  border-bottom: 2px solid #00010d; }

.table-wrapper tr td .label {
  color: #132731; }

.table-striped > tbody:nth-child(2n) > tr td {
  background-color: #3E606F; }

.overflow {
  background-color: rgba(0, 0, 0, 0.8); }

.content-inner .time {
  background-color: #193441;
  border-bottom: 1px solid #0A151F;
  color: #b4bcc8; }

.-change-up {
  color: #5cb85c !important; }

.-change-up.active {
  color: #77FF77 !important; }

.-change-down {
  color: #d9534f !important; }

.-change-down.active {
  color: #DC3631 !important; }

label input[type='radio'] + span,
label input[type='checkbox'] + span {
  border: 1px solid #CCCCCC; }

label input[type="radio"]:checked + span,
label input[type="checkbox"]:checked + span {
  background-color: #fff; }

label input[type="radio"]:checked + span,
label input[type="checkbox"]:checked + span {
  background-color: var(--brand-color-secondary); }

/* Variables */
:root {
  /* Toggler related variables */
  --toggler-controller-size: 35px;
  --toggler-controller-border-width: 1px;
  /* Event Summary related variables */
  --event-summary-row-height: 3.3125rem;
  /* Inline search */
  --inline-search__border: none;
  --inline-search__border-bottom: 1px solid var(--background-color-tertiary);
  --inline-search__color: var(--font-color-primary);
  --inline-search__font-size: 0.85rem;
  --inline-search__background-color: var(--background-color-secondary);
  --inline-search__background-color--2n: var(--background-color-lighten);
  --inline-search__background-color--hover: #aaa;
  --inline-search__item__background-color--hover: var(--background-color-primary);
  --inline-search__padding: 0.15rem 0.25rem;
  --inline-search__color: var(--font-color-primary);
  --inline-search__border-right: 1px solid var(--background-color-secondary);
  --inline-search__result-title__background-color: var(--background-color-tertiary);
  --inline-search__result--league__border-top: 1px solid var(--background-color-primary);
  --inline-search__result--event__padding: 0.25rem 0 0.25rem 1rem;
  --inline-search__result--league__padding: 0.25rem;
  /* Market selector */
  --market-selector__toggler--border: 1px solid var(--background-color-primary);
  --market-selector__toggler--color: inherit;
  /* Offerings */
  --offerings--contest-type-columns: 2; }

html,
body {
  color: var(--font-color-primary);
  font-size: 16px; }

react-interface {
  display: contents; }

.form-control[disabled],
fieldset[disabled] .form-control {
  background-color: var(--background-color-secondary);
  color: var(--font-color-tertiary); }

.content {
  background-color: var(--background-color-primary); }

/* Left side-bar */
/* sports-menu */
.sidebar-left {
  background-color: var(--background-color-primary);
  border-right: 1px solid var(--background-color-secondary);
  padding-top: 0; }

.sports-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 80px);
  scrollbar-color: #999999 #333333;
  scrollbar-width: thin;
  padding-bottom: 20px; }

.live-message .badge {
  background-color: var(--background-color-tertiary);
  color: var(--font-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1; }

.sidebar-top {
  overflow: hidden; }

.sidebar-right .sidebar-top .btn {
  border-radius: 0;
  height: 42px;
  min-width: 42px; }

.sidebar-right .sidebar-top .dropdown .btn {
  margin: 0; }

.sidebar-top .live-message {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.sports-menu ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.sports-menu a {
  display: flex;
  align-items: center; }

.sports-menu .title,
.sports-menu__item--upcoming > a > .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.sports-menu__item .time-range-selector .gs-dropdown__items {
  width: fit-content;
  height: fit-content;
  top: 0;
  left: 15rem; }

.sports-menu__item .time-range-selector .gs-dropdown__selected-item {
  overflow: hidden; }

.sports-menu i {
  margin-right: 0.5rem; }

.sports-menu .count {
  font-size: 0.75rem;
  margin-left: auto;
  flex-shrink: 0;
  padding-left: 0.15rem; }

.sports-menu__item {
  border-bottom: 1px solid var(--background-color-primary);
  width: 100%; }

.sports-menu__item--upcoming {
  position: relative;
  display: flex;
  align-items: center; }

.sports-menu__item--upcoming .sport-icon {
  margin-right: 0.5rem; }

.sports-menu__item--upcoming > a {
  width: 100%;
  display: flex;
  align-items: center; }

.sports-menu__item--upcoming.active > a {
  border-left: none; }

.sports-menu__item--upcoming .fa-caret-right {
  position: absolute;
  right: 0.5rem; }

.sub-menu--upcoming {
  list-style-type: none;
  padding: 0.5rem; }

.sports-menu__item-link {
  overflow: hidden;
  display: flex;
  align-items: center; }

.sports-menu__item-link > div {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.sports-menu__item--upcoming .sports-menu__item-link {
  overflow: unset; }

.sub-menu--upcoming .active > .sports-menu__item-link,
.sub-menu--upcoming .active > .sports-menu__sub-item-link,
.sub-menu--upcoming .sports-menu__sub-sub-item-link.active {
  border: 0 none; }

.sub-menu--upcoming .sports-menu__sub-item-link {
  align-items: center;
  display: flex;
  min-height: 2rem;
  padding: 0 1rem;
  white-space: nowrap; }

.sub-menu--upcoming .sports-menu__sub-item-link:hover {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.sports-menu__item .sub-menu--upcoming {
  background-color: var(--background-color-tertiary);
  left: 15rem;
  min-width: 8rem;
  position: absolute;
  top: 6px;
  width: max-content; }

@media only screen and (max-width: 30rem) {
  .sports-menu__item .sub-menu--upcoming {
    min-width: 8rem;
    width: 100%; }
  .sports-menu__item--upcoming {
    flex-direction: column; }
  .sports-menu__item--upcoming .fa-caret-right {
    transform: rotate(90deg); }
  .sports-menu__item--upcoming.active .fa-caret-right {
    transform: rotate(-90deg); } }

.sports-menu__item-link {
  background-color: var(--background-color-tertiary);
  color: var(--font-color-primary);
  cursor: pointer;
  font-size: 0.875rem;
  height: 40px;
  padding: 0 1rem 0 0.5rem;
  transition: all 0.3s; }

@media only screen and (max-width: 30rem) {
  .sports-menu__item .sub-menu--upcoming {
    min-width: 8rem;
    width: 100%; }
  .sports-menu__item--upcoming {
    flex-direction: column; }
  .sports-menu__item--upcoming .fa-caret-right {
    transform: rotate(90deg); }
  .sports-menu__item--upcoming.active .fa-caret-right {
    transform: rotate(-90deg); } }

.sports-menu__item-link {
  background-color: var(--background-color-tertiary);
  color: var(--font-color-primary);
  cursor: pointer;
  font-size: 0.875rem;
  height: 40px;
  padding: 0 1rem 0 0.5rem;
  transition: all 0.3s; }

.sports-menu__item-link:hover {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.sports-menu__sub-item:not(:last-child) {
  border-bottom: 1px solid black; }

.sports-menu__sub-item-link {
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 0.8125rem;
  height: 30px;
  padding: 0 1rem;
  transition: all 0.3s; }

.sports-menu__sub-item-link:hover {
  background-color: rgba(200, 200, 200, 0.25); }

.sports-menu__sub-item .count {
  font-size: 0.7rem; }

.sports-menu__sub-sub-item-link {
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
  height: 25px;
  padding: 0 1rem;
  transition: all 0.3s; }

.sports-menu__sub-sub-item-link:hover {
  background-color: rgba(200, 200, 200, 0.7);
  color: var(--font-color-secondary); }

.sports-menu__sub-sub-item .count {
  font-size: 0.65rem; }

.sports-menu__sub-sub-item:not(:last-of-type) {
  border-bottom: 1px solid black; }

.active > .sports-menu__item-link,
.active > .sports-menu__sub-item-link,
.sports-menu__sub-sub-item-link.active {
  border-left: 3px solid var(--brand-color-primary);
  color: var(--brand-color-primary); }

.active > .sports-menu__item-link:hover {
  background-color: black; }

.active > .sports-menu__sub-item-link {
  background-color: rgba(0, 0, 0, 0.4); }

.sports-menu__sub-sub-item-link.active:hover {
  background-color: rgba(0, 0, 0, 0.2); }

/* Remove this when refactoring offerings component */
.event-summary-details {
  display: flex;
  position: relative;
  margin-right: 5px; }

.event-summary-details p {
  margin: 0; }

/* main content top controls */
.main-top-controls {
  background-color: var(--background-color-secondary);
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 0.25rem 0.75rem;
  position: sticky;
  top: 0;
  z-index: 2; }

.main-top-controls__expand-collapse {
  flex-grow: 1; }

.main-top-controls__toggle {
  display: inline-block; }

.main-top-controls__toggle.dropdown {
  margin: 0 10px; }

.main-top-controls__toggle.dropdown.righted {
  margin-left: auto; }

.main-top-controls__toggle .dropdown button {
  margin: 0; }

#top-label {
  padding: 0 8px; }

.main-top-controls__toggle button {
  align-items: center;
  background-color: transparent;
  border: 0 none;
  color: var(--button-default-color);
  display: flex;
  outline: none;
  padding: 0;
  position: relative;
  top: 1px; }

.main-top-controls__toggle button span {
  display: none; }

.main-top-controls__toggle .btn.dropdown-toggle:hover {
  color: var(--font-color-secondary); }

.main-top-controls__toggle button:hover {
  color: var(--brand-color-primary); }

.main-top-controls__toggle span {
  font-size: 0.75rem;
  margin-right: 0.5rem; }

.main-top-controls__toggle .fa {
  margin-left: 3px; }

.main-top-controls__toggle .btn.dropdown-toggle:focus {
  border-color: transparent; }

.main-top-controls__toggle .dropdown-menu-right {
  right: auto;
  left: 0; }

@media (max-width: 768px) {
  .main-top-controls__toggle .dropdown-menu-right {
    left: 60px; } }

@media (max-width: 480px) {
  .main-top-controls__toggle .dropdown-menu-right {
    left: 0; } }

/* Ends left sidebar */
.inline-search input {
  background-color: var(--inline-search__background-color);
  outline: none;
  border: var(--inline-search__border);
  border-bottom: var(--inline-search__border-bottom);
  position: relative;
  margin-right: 10px;
  color: var(--inline-search__color);
  font-size: var(--inline-search__font-size);
  padding: var(--inline-search__padding); }

.inline-search input::placeholder {
  color: var(--inline-search__color); }

.inline-search input:focus {
  --inline-search__background-color: var(--background-color-primary); }

.inline-search input::after {
  content: '';
  border-right: var(--inline-search__border-right);
  height: 80%;
  margin-top: 10%;
  position: absolute;
  right: -5px; }

.inline-search__results {
  max-width: 300px;
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  left: auto; }

.inline-search__results div:first-child .inline-search__result--league {
  border-top: none; }

.inline-search__result {
  padding: 0.15rem 0.25rem;
  white-space: nowrap; }

.inline-search__result,
.inline-search .fa-search {
  cursor: pointer; }

.inline-search__result--event {
  padding: var(--inline-search__result--event__padding); }

.inline-search__result--league {
  padding: var(--inline-search__result--league__padding);
  border-top: var(--inline-search__result--league__border-top); }

.inline-search__result {
  background-color: var(--inline-search__background-color); }

.inline-search__result:nth-of-type(2n) {
  background-color: var(--inline-search__background-color--2n); }

.inline-search__result:hover,
.inline-search__result:nth-of-type(2n):hover {
  color: var(--highlight-color-primary);
  background-color: var(--inline-search__background-color--hover); }

.inline-search__results-title {
  background-color: var(--inline-search__result-title__background-color);
  cursor: normal; }

@media screen and (max-width: 48rem) {
  :root {
    --inline-search__result--league__padding: 0.5rem 0.5rem;
    --inline-search__result--event__padding: 0.5rem 1rem; } }

/* Market Seletor */
.market-selector-group {
  display: flex;
  justify-content: flex-end; }

.market-selector {
  font-size: 0.75rem;
  margin: 1px;
  border: var(--market-selector__toggler--border);
  color: var(--market-selector__toggler--color);
  position: relative; }

.market-selector.open {
  z-index: 1; }

.market-selector__toggler {
  border: none;
  padding: 0 0.25rem;
  padding-right: 1rem;
  background-color: transparent;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none; }

/* Double chance markets should not have the padding */
.market-2 .market-selector__toggler {
  padding-right: 0.25rem; }

.market-selector__toggler i {
  display: inline-block;
  font-size: 0.5rem;
  position: absolute;
  right: 0.25rem; }

.market-selector--disabled {
  --market-selector__toggler--border: none;
  justify-content: center; }

.market-selector--disabled span {
  max-width: none; }

.market-selector--disabled .market-selector__toggler {
  padding-right: 0; }

.market-selector__toggler[disabled] i {
  display: none; }

.market-selector--list {
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  color: var(--font-color-primary); }

.market-selector--list li {
  padding: var(--inline-search__result--league__padding);
  white-space: nowrap; }

.market-selector--list li:nth-child(2n):not(.market-selector--selected) {
  background-color: var(--background-color-lighten); }

.market-selector--selected {
  color: var(--highlight-color-primary);
  position: sticky;
  top: 0;
  bottom: 0;
  /* background-color: var(--background-color-darken); */
  background-color: var(--background-color-primary); }

.dropdown-menu.dropdown-menu-right.market-selector--list {
  min-width: 250px;
  position: absolute;
  height: auto; }

.market-selector button span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  max-width: 100%; }

/* End Market Selector*/
/* Regulates the font size on the offerings
   This will be removed soon */
.table .offerings *,
.offerings .link .odds-description,
.panel .panel-body td.more {
  font-size: 0.75rem; }

#scrollbar-main.scrollbar {
  height: calc(100vh - 100px);
  width: 100%;
  overflow: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  scrollbar-color: #999999 #333333;
  scrollbar-width: thin;
  position: relative;
  z-index: 1; }

@media only screen and (max-width: 768px) {
  #scrollbar-main.scrollbar {
    height: calc(100% - 100px); }
  #scrollbar-main.scrollbar--top,
  #scrollbar-main.scrollbar--favorite {
    height: calc(100% - 60px); } }

/* Panel component */
.panel .panel-body {
  margin: 0;
  padding: 0; }

.panel .panel-heading {
  background-color: var(--brand-color-primary); }

.panel .panel-heading.single {
  cursor: default; }

.panel .panel-heading.single button {
  display: none; }

/* Main content */
/* Home lines */
.event-summary-header {
  background-color: var(--background-color-secondary); }

.panel .panel-title,
.panel .panel-favorite {
  color: var(--font-color-secondary); }

.panel .panel-body tr:last-child {
  background-color: var(--background-color-secondary); }

.panel-body a.show-more {
  color: var(--font-color-primary);
  float: right; }

.extra-info {
  float: left;
  color: var(--highlight-color-primary); }

.panel .panel-body th {
  border-top: 1px solid var(--background-color-tertiary);
  color: var(--font-color-primary);
  font-size: 0.75rem;
  font-weight: normal;
  padding: 4px 7px; }

.offerings-empty,
.offerings-empty {
  padding: 0; }

.panel .panel-body td {
  background-color: var(--background-color-secondary);
  border: 0 none;
  padding: 0; }

.offerings .link {
  color: var(--font-color-primary);
  background-color: var(--background-color-primary);
  border: 1px solid var(--background-color-secondary);
  border-radius: 3px;
  min-height: 27px; }

.panel-body > table > tbody > tr:not(:first-child):nth-child(odd) > td {
  background-color: var(--background-color-lighten); }

.offerings .link.active {
  background-color: var(--background-color-tertiary); }

/* Event Summary Date */
.event-summary-details__date {
  display: flex;
  flex-direction: column;
  float: left;
  font-size: 0.75rem;
  height: var(--event-summary-row-height);
  justify-content: center;
  padding-left: 0.5rem;
  width: 4.5rem;
  line-height: 1.75;
  margin-right: 0.5rem; }

/* Event Summary Teams */
.event-summary-details__teams {
  display: flex;
  flex-direction: column;
  float: left;
  font-size: 0.875rem;
  height: var(--event-summary-row-height);
  justify-content: center; }

.event-summary-details:hover .event-summary-details__teams {
  color: var(--highlight-color-primary); }

.event-summary-details__pitcher {
  margin-left: 0.3rem;
  font-size: 0.65rem;
  color: var(--highlight-color-primary); }

.event-summary-details__teams p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media screen and (max-width: 48rem) {
  .event-summary-details .event-summary-details__pitcher {
    display: none; } }

/* Event Summary Score */
.event-summary-details__score {
  align-items: center;
  display: flex;
  float: right;
  font-size: 0.625rem;
  line-height: 1;
  height: var(--event-summary-row-height); }

.prematch .event-summary-details__score {
  display: none; }

.event-summary-details__score-table {
  border-collapse: collapse; }

.event-summary-details__more {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.event-summary-details__score tr td.event-summary-details__score-team-period {
  background-color: var(--background-color-primary);
  border: 1px solid var(--background-color-secondary);
  min-width: 15px;
  padding: 4px;
  text-align: center; }

@media (max-width: 30rem) {
  .event-summary-details__date {
    display: none; }
  .event-summary-details__teams {
    font-size: 0.8125rem;
    padding-left: 7px; } }

/* Right side bar */
.sidebar-right {
  float: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 3;
  background-color: var(--background-color-primary);
  border-left: 1px solid #00010d;
  width: 350px;
  transition: 0.3s ease-in-out transform;
  transform: translateX(0); }

.minimized-right-sidebar .sidebar-right {
  transform: translateX(350px); }

.sidebar-right .scrollbar {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.bet-contents {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; }

.multi-bet .bet-slip--inputs .bet-contents {
  background-color: #202020;
  padding: 5px 8px;
  margin-bottom: 0; }

.bet-contents .bet-line-dropdown .btn-default:disabled {
  border: 0;
  color: inherit;
  background: inherit; }

.bet-list {
  margin-bottom: 0.75rem; }

.bet-listed {
  margin-bottom: 0.5rem; }

.button-group {
  display: flex;
  flex-wrap: nowrap; }

.button-group > .btn {
  flex: 1 1 auto;
  margin: 0;
  border-radius: 0;
  position: static;
  float: none;
  border: 0; }

.button-group > .btn:only-child {
  border-radius: 3px; }

.button-group > .btn:first-child:not(:last-child) {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.button-group > .btn:last-child:not(:first-child) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.button-group .btn + .btn {
  margin-left: 1px; }

.bet-amount-button-group {
  margin-bottom: 0.5rem; }

.bet-amount--control {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  font-size: 0.75rem; }

.bet-amount--control:first-of-type {
  margin-right: 0.5rem; }

.bet-content--round-robin .bet-amount--control:first-of-type {
  margin-right: 0; }

.bet-content--round-robin .bet-amount--control label {
  flex-grow: 1; }

.bet-amount--control label {
  margin: 0 5px 0 0;
  white-space: nowrap; }

.bet-amount--control input {
  background-color: var(--background-color-secondary);
  border: 0 none;
  color: var(--font-color-primary);
  flex: 1 1 80px;
  height: 1.5rem;
  max-width: 105px; }

.bet-amount--control input:focus {
  border: 0 none; }

.bet-contents .bet-amount--unselected {
  font-weight: 300; }

.row.bet-amount {
  color: var(--brand-color-primary);
  margin: 5px 0; }

.input-group .do-bet {
  float: right; }

.input-group .bet-max {
  color: var(--button-default-color);
  text-decoration: none;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0; }

/* right sidebar */
/* sidebar top */
.sidebar-top {
  background-color: #000;
  flex-shrink: 0; }

.bet-slip-header {
  border-top: 2px solid black; }

.bet-slip--text {
  font-size: 0.85rem; }

.bet-slip-header-tabs {
  border-bottom: 2px solid var(--background-color-secondary);
  align-items: center;
  display: flex;
  height: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.bet-slip-options-chooser--option {
  flex: 1 1 auto;
  display: flex;
  text-align: center; }

.bet-slip-options--preferences {
  flex: 0 0 40px; }

.bet-slip-options--preferences i {
  font-size: 1.25rem; }

.sidebar-right--toggle {
  height: 41px;
  width: 40px;
  margin-left: auto;
  flex: 0 0 42px; }

.sidebar-right--toggle .bet-slip-badge {
  font-size: 0.8125rem;
  margin-right: 5px;
  padding: 1px 6px; }

.sidebar-right--toggle button {
  background-color: var(--brand-color-primary);
  border-bottom: 2px solid transparent;
  align-items: center;
  border: 0 none;
  color: var(--font-color-secondary);
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  height: 41px;
  justify-content: center;
  padding: 0;
  outline: none;
  width: 42px;
  transition: all 0.5s; }

.sidebar-right--toggle.active button,
.sidebar-right--toggle button:hover {
  background-color: #000;
  color: var(--brand-color-primary);
  border-bottom: 2px solid var(--brand-color-primary); }

.sidebar-right--toggle-mobile.sidebar-right--toggle i {
  /* color: black; */
  font-size: 0.875rem; }

.sidebar-right--toggle-mobile {
  display: none;
  width: 42px; }

.sidebar-right--toggle-mobile button {
  width: inherit; }

@media (max-width: 768px) {
  .sidebar-right--toggle-mobile {
    display: block; } }

.bet-type-chooser li {
  flex: 1 1 50%; }

.bet-slip-header-tabs li:not(.sidebar-right--toggle) button {
  background-color: transparent;
  border-bottom: 2px solid transparent;
  box-shadow: none;
  display: block;
  float: none;
  line-height: 1;
  height: 42px;
  margin: 0;
  min-width: unset;
  padding: 0;
  position: static;
  text-transform: uppercase;
  width: 100%; }

.bet-slip-header-tabs button:hover,
.bet-slip-header-tabs li.active button {
  color: var(--brand-color-primary);
  border-bottom-color: var(--brand-color-primary); }

.bet-type-chooser button i {
  margin-left: 7px; }

/* betslip */
.bet-slip--header,
.bet-slip--body {
  margin: 0.625rem 1rem; }

.bet-slip--header {
  color: var(--font-color-primary);
  background: var(--background-color-tertiary);
  position: sticky;
  top: 0;
  z-index: 7;
  padding: 5px 0;
  display: none;
  /* only show the header for partner 106 */ }

.bet-slip--row {
  color: var(--font-color-primary);
  font-size: 0.8rem; }

.bet-odds-change {
  margin: -5px -10px 5px -10px; }

.bet-odds-change button {
  align-items: center;
  background-color: #fff;
  border: 0 none;
  color: var(--button-color-secondary);
  display: flex;
  height: 32px;
  opacity: 0.75;
  width: 100%;
  transition: opacity 0.2s ease-out; }

.bet-odds-change button:hover {
  opacity: 1; }

.bet-odds-change .fa-check-circle {
  transition: opacity 0.3s ease-out;
  opacity: 0; }

.bet-odds-change i.fa-check-circle {
  margin-left: auto; }

.bet-odds-change button:hover .fa-check-circle {
  opacity: 1; }

.bet-odds-change .fa {
  margin: 0 5px;
  font-size: 1rem; }

/* bet-slip process-actions panel */
.bet-slip-controls {
  margin-top: 0.75rem; }

.bet-slip-contents .bet-odd-value {
  color: #ffcc33;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-transform: capitalize; }

.bet-odd-value span {
  color: var(--font-color-primary); }

.bet-odd-value span:first-child {
  flex-grow: 1; }

.bet-odd-value--discounted {
  text-decoration: line-through; }

span.bet-odd-value--discount {
  color: var(--brand-color-primary);
  font-weight: bold;
  margin-left: 0.25rem; }

.bet-slip-footer {
  color: white;
  margin-top: 2rem; }

.bet-slip-footer p {
  margin: 0; }

.bet-slip-footer p:first-child button {
  font-size: 0.6875rem;
  color: white;
  padding: 0;
  text-decoration: underline; }

.bet-slip-footer p:first-child button:hover {
  color: var(--brand-color-secondary); }

.bet-slip-footer p:last-child {
  font-size: 0.75rem; }

.bet-footer .input-group-addon {
  font-size: 0.8rem;
  padding: 10px;
  border: 0; }

.multi-bet-odds-changed {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column; }

.multi-bet-odds-changed .bet-odds-change {
  margin: 0 0 0.75rem 0;
  flex: 1 1 100%;
  width: 100%; }

.multi-bet-odds-changed-accept {
  flex: 1 1 100%;
  margin: 0;
  padding: 0; }

.multi-bet-odds-changed-accept i {
  font-size: 1.25rem; }

.bet-slip--inputs .multi-bet-odds-changed .bet-odds-change button,
.multi-bet-odds-changed-accept .btn,
.bet-slip__keep-bets {
  align-items: center;
  border: 0 none;
  border-radius: 3px;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  height: auto;
  margin: 0;
  text-transform: capitalize;
  width: 100%;
  padding: 0.5rem; }

.bet-slip--header {
  color: var(--font-color-primary);
  background: var(--background-color-tertiary);
  position: sticky;
  top: 0;
  z-index: 7;
  padding: 5px 0;
  /* only show the header for partner 106 */
  display: none; }

.bet-slip--row {
  color: var(--font-color-primary);
  font-size: 0.8rem; }

.bet-slip-footer {
  font-size: 13px;
  margin-top: 2rem; }

.bet-slip-footer .btn-link {
  font-size: 11px;
  color: #cccccc; }

.bet-slip-footer__message,
.my-bets-footer__message {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaa;
  margin-top: 1rem;
  padding: 0.75rem;
  font-size: 0.8rem; }

.my-bets-footer {
  padding: 1rem; }

.my-bets-footer__message {
  margin: 1rem 0; }

.my-bets-footer__message p {
  margin-bottom: 0; }

.my-bets-footer__btn {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--font-color-primary);
  color: var(--font-color-primary); }

.my-bets-footer__btn:visited {
  color: var(--font-color-primary); }

.my-bets-footer__btn:hover {
  background-color: var(--button-color-secondary);
  border: 1px solid var(--font-color-primary);
  color: var(--font-color-primary); }

.bet-slip__keep-bets {
  justify-content: flex-start;
  color: var(--font-color-secondary);
  background-color: var(--brand-color-primary);
  margin-bottom: 10px; }

.bet-slip__keep-bets i {
  margin: 0 5px;
  font-size: 1rem; }

.bet-slip__keep-bets span {
  margin-left: 0.2rem; }

.bet-slip__keep-bets__accept {
  display: flex;
  align-items: center; }

.teaser-picker {
  align-items: center;
  font-size: 0.85rem;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden; }

.teaser-picker__option {
  font-weight: normal;
  line-height: 22px;
  padding-top: 4px; }

.teaser-picker__more {
  background-color: var(--brand-color-secondary);
  color: var(--font-color-tertiary);
  width: 100%;
  font-weight: bold;
  padding: 0.25rem 0;
  outline: none; }

.teaser-picker .teaser-picker__header {
  font-size: 0.85rem;
  margin-bottom: 0.7rem; }

.teaser-picker p {
  margin-bottom: 0; }

.teaser-picker__type-selector {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; }

.teaser-picker__header__type-dropdown {
  position: relative;
  margin-left: 0.5rem; }

.teaser-picker__header__type-dropdown .dropdown-toggle {
  padding: 0.3rem;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: flex;
  align-items: center; }

.teaser-picker .dropdown-menu {
  right: 0; }

.teaser-picker li {
  padding: 0.2rem;
  padding-left: 0.6rem;
  cursor: pointer; }

.teaser-picker li:hover {
  background-color: var(--background-color-primary); }

.teaser-picker li.teaser-picker-dropdown__option-header,
.teaser-picker li.teaser-picker-dropdown__option-header:hover {
  text-transform: capitalize;
  padding-left: 0.2rem;
  background-color: transparent;
  background-color: var(--background-color-darken); }

.teaser-picker li.teaser-picker-dropdown__option-header {
  border-top: 1px solid var(--font-color-secondary); }

/* finish right side-bar */
/* My bets tab*/
section[role='my-bets'] {
  height: 100%;
  display: flex;
  flex-direction: column; }

.my-bets--body {
  border-bottom: 1px solid var(--background-color-secondary);
  padding: 0.625rem; }

.my-bets--body .bet p,
.my-bets--body .bet span {
  font-size: 0.8rem;
  font-weight: 400; }

.my-bets--body .bet .bet--header {
  margin: 0;
  color: var(--button-default-color);
  margin-bottom: 0.3rem;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase; }

.my-bets--body .bet--sides {
  text-transform: uppercase; }

.my-bets--body .sport-icon {
  width: 17px;
  height: 17px; }

.my-bets--body .sport-icon::before {
  font-size: 14px;
  position: relative;
  top: -1px; }

.my-bets--body .bet .bet--description {
  display: flex;
  flex-basis: 50%; }

.bet--description--risk {
  margin-right: 0.5rem; }

.bet--description--free {
  text-transform: uppercase; }

.my-bets--body .bet--description--risk span,
.my-bets--body .bet--description--return span,
.my-bets--body .bet--current-line span {
  color: var(--highlight-color-primary); }

.my-bets--body .bet--description--risk span.bet-description-odds--discounted,
.my-bets--body .bet--description--return span.bet--description--return--discounted {
  color: var(--font-color-primary);
  text-decoration: line-through;
  margin-right: 0.25rem; }

.bet--description--return__icon {
  display: inline-block;
  border-radius: 10px;
  height: 1rem;
  width: 1rem;
  line-height: 1;
  text-align: center;
  font-size: 0.85rem;
  color: var(--background-color-secondary);
  background-color: var(--brand-color-primary); }

.bet--description--premium {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: var(--parlays-summarize__background-color);
  font-size: var(--parlays-summarize__font-size);
  font-size: 0.85rem; }

.bet--description--premium .bet--description--return__icon {
  margin-right: 0.25rem; }

.bet--description--return__icon i {
  line-height: 1rem;
  color: var(--background-color-secondary); }

.my-bets--body .my-bets--body-line {
  margin-bottom: 0.4rem;
  line-height: normal;
  display: flex;
  align-items: center; }

.bet--description--premium .bet--description--return__icon i {
  color: var(--parlays-summarize__background-color); }

.bet--description--premium .my-bets--body-line:not(:first-child) {
  font-size: var(--parlays-summarize__bottom__font-size);
  color: var(--parlays-summarize__bottom__color);
  margin-bottom: 0; }

.bet--description--premium .my-bets--body-line .emphasis {
  color: var(--brand-color-primary);
  margin: 0 0.15rem;
  font-size: unset; }

.my-bets--body .bet--current-line {
  margin-bottom: 7px;
  background-color: var(--background-color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-top: 0.25rem; }

.my-bets--body .my-bets--body-line > * {
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal; }

.my-bets--body .bet .bet--current-line label {
  flex-grow: 1;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 0.85rem; }

.my-bets--body .bet .bet--current-line .bet--current-line--figure {
  margin-right: 0.2rem; }

.my-bets--messages {
  color: var(--highlight-color-primary);
  font-size: 0.85rem;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid var(--background-color-secondary);
  padding: 0.625rem; }

.my-bets--action-bar {
  padding: 0.35rem 0.625rem;
  border-bottom: 1px solid var(--background-color-secondary);
  display: flex;
  align-items: flex-start; }

.my-bets--action-bar--items--options {
  appearance: none;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 0.875rem;
  outline: none;
  color: var(--brand-color-primary);
  display: flex;
  align-items: center; }

.my-bets--action-bar--items--options p {
  color: var(--button-default-color);
  margin: 0;
  margin-left: 0.5rem; }

.my-bets--action-bar--refresh {
  appearance: none;
  border: none;
  background: transparent;
  color: var(--brand-color-primary);
  transition: color 0.3s;
  cursor: pointer;
  outline: none; }

.my-bets--action-bar--refresh:hover {
  color: var(--font-color-primary); }

.my-bets--action-bar--items {
  display: flex;
  flex: 1 1 18.75rem; }

.my-bets--action-bar--items p {
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-right: 0.35rem; }

.my-bets--action-bar--items ul {
  list-style-type: none;
  background-color: var(--background-color-primary); }

.my-bets--action-bar--toggler-container {
  display: flex;
  line-height: 1; }

.bet--cashout {
  display: flex;
  flex-flow: column;
  position: relative; }

.bet--cashout .btn {
  float: none;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s; }

.bet--cashout--actions {
  display: flex;
  position: relative; }

.bet--cashout--actions .bet--cashout--actions--spinner {
  display: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  color: var(--brand-color-primary); }

.bet--cashout--loading .bet--cashout--actions--spinner {
  display: flex; }

.bet--cashout .btn:hover {
  background-color: var(--font-color-primary);
  border-color: transparent;
  color: var(--font-color-secondary); }

.bet--cashout .bet--cashout-do {
  flex-grow: 1;
  position: relative;
  transition: all 0.2s;
  border-color: transparent; }

.bet--cashout .bet--cashout-do span {
  width: auto; }

.bet--cashout .bet--cashout-do > div {
  align-items: center;
  display: flex;
  justify-content: center; }

.bet--cashout .bet--cashout-do i {
  display: none;
  color: var(--brand-color-primary);
  font-size: 1.2rem; }

.bet--cashout .bet--cashout-cancel {
  background-color: var(--theme-color-danger);
  border-color: var(--theme-color-danger);
  color: var(--font-color-primary);
  flex-grow: 1;
  display: none;
  position: relative; }

.bet--cashout--confirm-cashout .bet--cashout-cancel {
  display: block;
  margin: 0; }

.my-bets--straight-disclaimer {
  padding: 0.25rem 0.625rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  font-style: italic; }

.bet--cashout--loading .bet--cashout-do i {
  display: block;
  position: absolute;
  left: 50%;
  top: 5px;
  z-index: 10; }

.bet--cashout .bet--cashout-perc {
  display: flex;
  flex-basis: 60px;
  position: relative; }

.bet--cashout--confirm-cashout .bet--cashout-perc {
  display: none; }

.bet--cashout .bet--cashout-perc input {
  -moz-appearance: textfield;
  background-color: var(--background-color-secondary);
  border: 0 none;
  color: var(--font-color-primary);
  border-radius: 3px;
  font-size: 0.85rem;
  outline: none;
  padding: 0 10px;
  width: 100%; }

.bet--cashout .bet--cashout-perc input::-webkit-inner-spin-button,
.bet--cashout .bet--cashout-perc input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.bet--cashout .bet--cashout-perc label {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 0.85rem;
  font-weight: bold; }

.bet--cashout--loading .bet--cashout--actions::after {
  content: '';
  display: block;
  cursor: progress;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #555;
  color: #444;
  z-index: 1; }

.bet--cashout-message--confirm {
  width: 100%;
  font-size: 0.875rem;
  margin-bottom: 7px;
  color: var(--font-color-primary); }

.bet--cashout-message--confirm--amount,
.bet--cashout-message--confirm--pctg {
  color: var(--highlight-color-primary); }

.bet--cashout-message {
  width: 100%;
  text-align: left;
  border-radius: 3px;
  font-size: 0.875rem;
  margin-top: 7px;
  margin-bottom: 0;
  padding: 3px 5px;
  color: #444;
  justify-content: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center; }

.bet--cashout-message.bet--cashout-message--success {
  background-color: #f6ffed;
  border-color: #b7eb8f; }

.bet--cashout-message.bet--cashout-message--failure {
  background-color: #fff1f0;
  border: 1px solid #ffa39e; }

.bet--cashout-message--body {
  margin: 0;
  flex-grow: 1;
  line-height: 1.1; }

.bet--cashout-message--close {
  appearance: none;
  border: none;
  background: none; }

.bet--cashout-disclaimer {
  margin-bottom: 0;
  margin-top: 7px;
  font-style: italic;
  font-size: 0.7rem; }

.bet-parlay .bet--header {
  flex-grow: 1;
  margin-bottom: 0;
  font-weight: bold;
  text-align: left;
  color: var(--button-default-color); }

.bet--component-bets {
  padding: 0 12px;
  margin-top: 0.35rem;
  font-size: 0.85rem;
  transition: max-height 0.3s;
  overflow: hidden;
  padding-top: 0.35rem; }

.lowercase {
  text-transform: lowercase; }

.bet--component-bets--bet:first-child {
  padding-bottom: 0.5rem; }

.bet--component-bets--bet:last-child {
  padding-top: 0.5rem; }

.bet--component-bets--bet:not(:first-child):not(:last-child) {
  padding: 0.5rem 0; }

.bet--component-bets--bet:not(:last-child) {
  border-bottom: 1px solid var(--background-color-secondary); }

.bet-parlay {
  transition: height 0.3s; }

.bet-parlay .component-bets--bet--body-line p {
  margin-bottom: 0; }

.bet-parlay .component-bets--bet--body-line span {
  color: var(--highlight-color-primary); }

/* finish right side-bar */
/* Settings component */
.settings--bet-amounts {
  display: flex; }

.settings--bet-amounts :first-child {
  flex-shrink: 2;
  align-self: center;
  margin: 2px; }

.settings--bet-amounts .form-control {
  margin: 2px;
  min-width: 0; }

.default-risk {
  display: flex; }

.default-risk .form-control {
  margin-left: 4px;
  border-radius: 4px; }

/* single event */
/* single event header */
.event--container {
  position: relative; }

.single-event-header-container {
  background-image: url(../img/sportCovers/generic.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 64px;
  position: sticky;
  top: -30px;
  z-index: 2;
  overflow: hidden; }

.event--container__stat-score .single-event-header-container {
  top: 0; }

.event--container__stat-score .single-event-header-container,
.event--container__stat-score .single-event-header-container > div {
  min-height: 0;
  height: 80px; }

.sport-soccer .single-event-header-container {
  background-image: url(../img/sportCovers/soccer.jpg); }

.sport-tennis .single-event-header-container {
  background-image: url(../img/sportCovers/tennis.jpg); }

.sport-basketball .single-event-header-container {
  background-image: url(../img/sportCovers/basketball.jpg); }

.sport-football .single-event-header-container {
  background-image: url(../img/sportCovers/football.jpg); }

.sport-rugby .single-event-header-container {
  background-image: url(../img/sportCovers/rugby.jpg); }

.sport-aussie-rules .single-event-header-container {
  background-image: url(../img/sportCovers/australian-rules.jpg); }

.sport-cricket .single-event-header-container {
  background-image: url(../img/sportCovers/cricket.jpg); }

.sport-fighting .single-event-header-container {
  background-image: url(../img/sportCovers/fighting.jpg); }

.sport-golf .single-event-header-container {
  background-image: url(../img/sportCovers/golf.jpg); }

.sport-baseball .single-event-header-container {
  background-image: url(../img/sportCovers/baseball.jpg); }

.sport-volleyball .single-event-header-container {
  background-image: url(../img/sportCovers/volleyball.jpg); }

.sport-hockey .single-event-header-container {
  background-image: url(../img/sportCovers/hockey.jpg); }

.sport-table-tennis .single-event-header-container {
  background-image: url(../img/sportCovers/table-tennis.jpg); }

.sport-beach-volleyball .single-event-header-container {
  background-image: url(../img/sportCovers/beach-volleyball.jpg); }

.sport-handball .single-event-header-container {
  background-image: url(../img/sportCovers/handball.jpg); }

.sport-bandy .single-event-header-container {
  background-image: url(../img/sportCovers/bandy.jpg); }

.sport-darts .single-event-header-container {
  background-image: url(../img/sportCovers/darts.jpg); }

.sport-field-hockey .single-event-header-container {
  background-image: url(../img/sportCovers/field-hockey.jpg); }

.sport-motor-racing .single-event-header-container {
  background-image: url(../img/sportCovers/motor-racing.jpg); }

.sport-politics .single-event-header-container {
  background-image: url(../img/sportCovers/politics.jpg); }

.sport-squash .single-event-header-container {
  background-image: url(../img/sportCovers/squash.jpg); }

/* e-sports */
.brawl-stars .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/brawl-stars.jpg); }

.clash-royale .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/clash-royale.jpg); }

.cs-go .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/counter-strike.jpg); }

.dota-2 .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/dota-2.jpg); }

.king-of-glory .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/king-of-glory.jpg); }

.league-of-legends .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/league-of-legends.jpg); }

.overwatch .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/overwatch.jpg); }

.rainbow-six .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/rainbow-6.jpg); }

.rocket-league .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/rocket-league.jpg); }

.smite .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/smite.jpg); }

.starcraft .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/starcraft.jpg); }

.age-of-empires .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/age-of-empires.jpg); }

.arena-of-valor .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/arena-of-valor.jpg); }

.pes .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/pes.jpg); }

.red-alert .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/red-alert.jpg); }

.call-of-duty .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/call-of-duty.jpg); }

.spike .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/spike.jpg); }

.world-of-warcraft .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/world-of-warcraft.jpg); }

.hearthstone .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/hearthstone.jpg); }

.heroes-of-the-storm .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/heroes-of-the-storm.jpg); }

.unreal .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/unreal.jpg); }

.valorant .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/valorant.jpg); }

.mlb .single-event-header-container {
  background-image: url(../img/sportCovers/e-sports/mlb.jpg); }

.sport-golf .single-event-header-container {
  height: 62px;
  top: 0; }

.prematch .single-event-body__tabs,
.sport-golf .single-event-body__tabs {
  top: 34px; }

.sport-golf .single-event-header-container .single-event-header__scoreBox {
  justify-content: center; }

.sport-golf .single-event-header-container .single-event-header__scoreBox-misc {
  display: inline-block;
  flex-grow: 0; }

.single-event-header {
  background-color: rgba(1, 1, 1, 0.6);
  height: 100px;
  padding: 0 10px; }

.prematch .single-event-header {
  height: 64px; }

.single-event-header__top {
  align-items: center;
  display: flex;
  height: 30px;
  margin: 0 auto;
  max-width: 600px; }

.single-event-header__top > div {
  display: flex;
  align-items: center; }

.single-event-header__top-sport {
  display: inline-block;
  line-height: 1;
  margin-right: 6px; }

.single-event-header__top-sport .sport-icon {
  background: transparent;
  box-shadow: none;
  color: #fff;
  height: auto;
  line-height: 1;
  margin: 0;
  padding: 0;
  width: auto; }

.single-event-header__top-sport .sport-icon:before {
  font-size: 14px; }

.single-event-header__top-league {
  display: inline-block;
  position: relative;
  top: -1px;
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden; }

.single-event-header__top-labels {
  margin-left: auto; }

.single-event-header__top-labels .label {
  border-radius: 3px;
  font-weight: normal;
  font-size: 0.75rem;
  margin-right: 5px;
  padding: 1px 3px;
  position: static;
  width: auto; }

.single-event-header__box {
  display: flex;
  height: 70px;
  margin: 0 auto;
  max-width: 600px; }

.prematch .single-event-header__box {
  height: 30px; }

.single-event-header__box-info {
  height: 70px; }

.single-event-header__time {
  align-items: center;
  display: flex;
  height: 24px; }

.single-event-header__time time {
  background-color: var(--brand-color-primary);
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.3;
  padding: 0 3px;
  margin-right: 10px;
  text-transform: uppercase; }

.single-event-header__time span {
  font-size: 0.8rem; }

.single-event-header__teams {
  color: var(--brand-color-primary);
  font-size: 0.8rem;
  line-height: 1;
  height: 46px; }

.prematch .single-event-header__teams p {
  margin-bottom: 0; }

.single-event-header__teams > div {
  align-items: center;
  display: flex;
  align-items: baseline; }

.single-event-header__teams > div i {
  display: none; }

.single-event-header__teams > div.active-side i {
  display: inline-block;
  position: relative;
  top: 1px;
  color: white;
  margin-right: 5px; }

.single-event-header__box-scores {
  margin-left: auto;
  height: 70px; }

.single-event-header__box-scores * {
  line-height: 1; }

.single-event-header__box-scores th,
.single-event-header__box-scores td {
  font-size: 0.8rem;
  padding: 0 8px;
  text-align: center; }

.single-event-header__box-scores th:last-child,
.single-event-header__box-scores td:last-child {
  padding-right: 0; }

.single-event-header__box-scores th {
  color: var(--brand-color-primary);
  height: 24px; }

.single-event-header__box-scores td {
  height: 20px; }

.prematch .single-event-header__box-info,
.prematch .single-event-header__teams {
  display: flex;
  position: relative;
  height: 24px;
  align-items: center; }

.prematch .single-event-header__teams div:first-child::after {
  display: block;
  content: '/';
  margin: 0 6px; }

@media (max-width: 48rem) {
  .prematch .single-event-header__teams {
    flex-direction: column;
    align-items: flex-start; }
  .prematch .single-event-header__teams div:first-child::after {
    content: ''; }
  .single-event-header__teams > div:nth-child(2) {
    margin-top: 0.3rem; }
  .single-event-header__top,
  .single-event-header__box {
    max-width: unset; } }

@media (max-width: 40rem) {
  .single-event-header__box-scores th,
  .single-event-header__box-scores td {
    padding: 0 4px; } }

@media (max-width: 25rem) {
  .single-event-header__box-scores th,
  .single-event-header__box-scores td {
    padding: 0 2px; }
  .single-event-header__top-league {
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    top: 4px;
    white-space: nowrap; } }

/* single event body */
.single-event-body {
  background-color: var(--background-color-primary);
  position: relative;
  z-index: 1; }

.single-event-body__period-tabs {
  display: contents; }

.single-event-body__tabs {
  background-color: var(--background-color-primary);
  display: flex;
  position: sticky;
  top: 70px;
  z-index: 1; }

/* the stat score tracker is 20px larger than having no tracker */
.event--container__stat-score .single-event-body__tabs {
  top: 78px; }

.single-event-body__tab {
  align-items: center;
  background-color: var(--background-color-secondary);
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  font-size: 0.8rem;
  height: 2.25rem;
  justify-content: center;
  padding: 0 1rem;
  transition: all 0.2s; }

.single-event-body__tab span {
  line-height: 1; }

.single-event-body__tab:hover {
  background-color: #505050; }

.single-event-body__tab.selected {
  background-color: var(--background-color-primary);
  border-bottom: 1px solid var(--brand-color-secondary);
  color: var(--brand-color-secondary); }

.single-event-body__content {
  column-count: auto;
  column-gap: 0.5rem;
  column-fill: balance;
  padding: 0.75rem;
  column-width: 23rem; }

.single-event-body__content > .panel {
  display: list-item;
  margin-bottom: 0.125rem;
  width: auto;
  break-inside: avoid;
  list-style-type: none;
  column-span: all; }

body.prematch .single-event-body__content > .panel:nth-last-child(n + 4),
body.prematch .single-event-body__content > .panel:nth-last-child(n + 4) ~ .panel {
  column-span: none; }

.single-event-body__content .panel-body {
  background-color: var(--background-color-secondary);
  padding: 5px; }

@media (max-width: 60rem) {
  .event--container__stat-score .single-event-body__tabs {
    top: 80px; } }

@media (max-width: 30rem) {
  .single-event-body__content {
    column-gap: 0;
    padding: 0; }
  .single-event-body__content .panel {
    margin-bottom: 1px; }
  .single-event-body__tab {
    font-size: 0.7;
    text-align: center;
    padding: 0 3px; } }

@media (max-width: 23.75rem) {
  .single-event-body__tab {
    font-size: 0.65rem;
    padding: 0 1px; } }

/* animations */
.history.ng-enter .scrollbar,
.history.ng-leave.ng-leave-active .scrollbar {
  display: none; }

.history.ng-enter,
.history.ng-leave {
  transition: 0.3s ease-in-out all; }

.history.ng-enter,
.history.ng-leave.ng-leave-active {
  height: 0%; }

.history.ng-leave,
.history.ng-enter.ng-enter-active {
  height: 100%; }

.odd {
  transition: all 200ms ease;
  background: transparent;
  position: relative; }

.panel-body.ng-leave {
  max-height: 500px;
  transform-origin: top;
  transition: max-height 200ms ease-in-out;
  overflow-y: hidden; }

.panel-body.ng-leave.ng-leave-active {
  max-height: 0;
  overflow-y: hidden; }

.panel-body.ng-enter {
  max-height: 0;
  transform-origin: top;
  transition: max-height 200ms ease-in-out;
  overflow-y: hidden; }

.panel-body.ng-enter.ng-enter-active {
  max-height: 500px;
  overflow-y: hidden; }

/* animation of left side bar items */
.sub-menu.ng-enter {
  max-height: 0;
  transform-origin: top;
  transition: max-height 200ms ease-in-out; }

.sub-menu.ng-enter.ng-enter-active {
  max-height: 500px;
  overflow: hidden; }

.sub-menu.ng-leave {
  max-height: 500px;
  transform-origin: top;
  transition: max-height 200ms ease-in-out;
  overflow: hidden; }

.sub-menu.ng-leave.ng-leave-active {
  max-height: 0; }

.sub-menu__actions {
  position: sticky;
  display: flex;
  align-items: stretch;
  z-index: 1;
  background-color: var(--background-color-tertiary);
  height: 100%; }

.sub-menu__actions--right {
  right: 0;
  margin-left: auto;
  box-shadow: -5px 0 15px 0 var(--background-color-tertiary); }

.sub-menu__actions--left {
  left: 0;
  box-shadow: 5px 0 15px 0 var(--background-color-tertiary); }

.sub-menu__arrow {
  min-height: 38px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  background-color: var(--background-color-tertiary); }

.sub-menu__arrow i {
  margin-right: 0; }

.sub-menu__toggle {
  display: flex;
  position: sticky;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 0rem 0.25rem;
  white-space: nowrap;
  font-size: 0.75rem;
  color: var(--button-default-color); }

.sub-menu__toggle__text {
  border: 1px solid var(--button-default-color);
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  cursor: pointer; }

/* Rules page */
.rules__header {
  display: flex;
  height: 3.75rem; }

.rules__header h1 {
  align-items: center;
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary);
  display: flex;
  flex: 0 0 12.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  height: inherit;
  justify-content: center;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase; }

.rules__main-nav {
  background-color: var(--background-color-secondary);
  display: flex;
  flex: 0 0 calc(100% - 12.5rem);
  height: inherit; }

.rules__main-nav ul {
  display: flex;
  list-style-type: none;
  height: inherit;
  margin: 0;
  padding: 0;
  width: 100%; }

.rules__main-nav li {
  align-items: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 0.8rem;
  height: inherit;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s; }

.rules__main-nav li.active {
  background-color: var(--background-color-primary);
  border-bottom: 3px solid var(--brand-color-primary);
  color: var(--brand-color-primary); }

.rules__main-nav li:hover {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.rules__main-nav li.active:hover {
  background-color: var(--background-color-primary);
  color: var(--brand-color-primary); }

.rules .modal-main .modal-section {
  background-color: var(--background-color-primary); }

.rules__sports-nav {
  background-color: var(--background-color-darken);
  padding: 1rem 0;
  transition: transform 1s linear 0.5s;
  flex-shrink: 0;
  height: calc(78vh - 7rem);
  overflow: auto; }

.rules__sports-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 1rem; }

.rules__sports-section.active .rules__sports-nav {
  transform: translateX(0); }

.rules__sports-section.active .rules__sports-main {
  flex-grow: 1;
  height: calc(78vh - 7rem);
  width: calc(80vw - 13rem);
  overflow: auto;
  padding: 0 1.5rem;
  padding-top: 1rem; }

.rules__sports-nav ul li {
  cursor: pointer;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  padding-right: 1rem;
  border-right: 3px solid transparent; }

.rules__sports-nav li.active,
.rules__sports-nav li.active .sport-icon:before {
  color: var(--brand-color-primary); }

.rules__sports-nav li.active {
  border-right: 3px solid var(--brand-color-primary); }

.rules__sports-nav .sport-icon {
  margin-right: 0.5rem; }

.rules__sports-nav .sport-icon:before {
  font-size: 12px; }

.rules .rules-emphasis {
  text-decoration: underline;
  font-weight: 700; }

@media only screen and (max-width: 48rem) {
  .history-premiums-modal .modal-body {
    width: 100%; }
  .rules__header {
    flex-direction: column;
    height: 5rem; }
  .rules__header h1 {
    font-size: 0.75rem;
    flex: 1 1 2rem; }
  .rules__main-nav {
    flex-basis: 3rem;
    height: 3rem;
    flex-grow: 1; }
  .rules__main-nav ul {
    height: 100%; }
  .rules__main-nav li {
    font-size: 0.7rem; }
  .rules__sports-nav {
    display: flex;
    position: static;
    height: 3rem;
    overflow-y: hidden;
    padding: 0;
    width: 100%;
    margin: 1px 0 1rem; }
  ::-webkit-scrollbar {
    height: 7px;
    /* height of horizontal scrollbar ← You're missing this */ }
  .rules__sports-nav ul {
    display: flex;
    width: 100%;
    padding: 0 10px;
    align-items: center; }
  .rules__sports-nav ul li {
    border-right: 0 none;
    display: flex;
    flex-direction: column;
    font-size: 0.7rem;
    line-height: 1.2;
    margin: 0 0.6rem 0 0;
    flex: 0 0 auto; }
  .rules__sports-nav .sport-icon {
    margin: 0 0 3px 0; }
  .rules__sports-nav li.active {
    border-right: 0 none; }
  .rules__sports-section.active .rules__sports-main {
    width: 100%;
    margin: 0;
    padding: 0 1.5rem;
    box-sizing: border-box;
    height: 100%; } }

@media (max-width: 480px) {
  .rules .modal-main {
    padding: 0; } }

/* Tertiary pages */
/* History Page */
.history {
  z-index: 1041; }

.history-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem; }

.history-table caption {
  color: var(--font-color-primary);
  font-size: 1.25rem; }

.history-table > table > tbody:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1); }

.history-table th:nth-child(2) {
  width: 60px; }

.history-table span {
  display: block; }

@media (max-width: 48rem) {
  .history .modal-dialog {
    max-width: 90%; } }

.history-table .label {
  display: inline; }

.history-table thead th {
  background-color: black;
  position: sticky;
  top: -15px; }

.history-table th {
  font-weight: normal;
  font-size: 0.8rem;
  padding: 0.5rem; }

.history-table td {
  font-size: 0.8125rem;
  padding: 0 5px; }

.history-table .history-empty {
  padding-top: 2rem;
  text-align: center; }

.history-bet-type {
  text-transform: capitalize;
  text-decoration: underline; }

.history-date {
  display: block; }

.history-time .fa {
  display: inline;
  color: var(--brand-color-primary); }

.history-details table {
  margin: 0; }

.history-details td {
  padding: 5px; }

.history-details td:first-child {
  width: 60%; }

.history-details td:first-child {
  padding-right: 10px; }

.history-details tr:not(:last-child) {
  border-bottom: 1px solid #666; }

.history-bets .label {
  float: right; }

.history-bets span {
  display: inline; }

.history-table td:last-child .label {
  float: right;
  font-size: 0.8125rem; }

.history__open-modal {
  font-size: 0.7rem;
  height: auto;
  margin: 0.5rem 0 0 0;
  padding: 3px 5px;
  position: static; }

.history__parlay-sizes span {
  display: inline; }

/* Date range picker component */
.daterangepicker {
  background-color: var(--background-color-secondary);
  border: 1px solid var(--font-color-secondary);
  border-radius: 0;
  top: 121px !important;
  left: 85px; }

.daterangepicker.opensright:after,
.daterangepicker.opensright:before {
  display: none; }

.history .date-picker {
  border-radius: 2px; }

.history .date-picker:hover,
.history .date-picker:focus {
  background-color: var(--brand-color-primary);
  border-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.history .premium-details {
  display: flex; }

.history .premium-details span {
  display: inline-block; }

.history .premium-details__icon {
  cursor: pointer;
  text-align: center;
  background-color: var(--parlays-summarize__top__odds__icon__background-color);
  color: var(--parlays-summarize__top__odds__icon__color);
  border-radius: 30px;
  height: 1.15rem;
  width: 1.15rem;
  margin-left: 0.25rem; }

.history-premiums-modal .modal-dialog > div {
  min-width: 300px; }

.history-premiums-modal .modal-body {
  --round-robins-summarize__background-color: transparent;
  --round-robins-summarize__margin-bottom: 0;
  --parlays-summarize__background-color: transparent;
  --parlays-summarize__margin-bottom: 0;
  background-color: var(--background-color-primary);
  padding: 0.5rem; }

.daterangepicker .ranges li:not(:last-child) {
  border-bottom: 1px solid var(--font-color-secondary); }

.daterangepicker .ranges li:hover {
  color: var(--font-color-secondary);
  background-color: var(--font-color-primary); }

.daterangepicker .ranges li.active {
  color: var(--font-color-secondary);
  background-color: var(--brand-color-primary); }

.combinations {
  padding: 0.5rem; }

.combinations table {
  width: 100%; }

.combinations__size {
  background-color: var(--background-color-secondary);
  font-size: 0.8rem;
  text-align: left;
  padding: 0.25rem 1rem;
  margin-bottom: 1px; }

/* Toggler start */
.toggler {
  display: inline-flex;
  align-items: center;
  cursor: pointer; }

.toggler .toggler__controller {
  position: relative;
  width: 35px;
  height: 17.5px;
  border-radius: 8.75px;
  border: 1px solid var(--brand-color-primary);
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }

.toggler .toggler__controller__label {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: var(--brand-color-primary);
  font-size: 0.45rem; }

.toggler .toggler__controller::after {
  content: '';
  position: absolute;
  height: 15.5px;
  width: 15.5px;
  border-radius: 50%;
  background-color: var(--background-color-lighten);
  border: var(--brand-color-primary);
  right: 17.5px;
  top: 0;
  transition: right 0.3s, background-color 0.3s; }

.toggler--on .toggler__controller::after {
  right: 0;
  background-color: var(--brand-color-primary); }

.toggler--disabled {
  cursor: not-allowed;
  filter: grayscale(1); }

.toggler .toggler__label {
  margin-left: 0.35rem;
  flex-grow: 1; }

.toggler .toggler__label__text {
  margin: 0;
  font-size: 0.75rem;
  text-transform: uppercase; }

toggler:first-child .toggler.toggler--button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

toggler:last-child {
  position: relative;
  left: -1px; }

toggler:last-child .toggler.toggler--button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.toggler.toggler--button {
  border: 1px solid var(--background-color-lighten);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  height: 100%;
  padding: 5px;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s; }

.toggler.toggler--on.toggler--button {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.toggler.toggler--button .toggler__label {
  margin-left: 0; }

.toggler.toggler--button .toggler__controller {
  display: none; }

/* Toggler end */
/* Stream */
.stream-player {
  width: 100%;
  height: 250px;
  background-color: black; }

.stream-player--widget iframe {
  height: 100%;
  width: 100%; }

.stream-target {
  display: none;
  padding: 10px; }

.stream-target.stream-target--streaming {
  display: block; }

.stream-handler {
  display: flex; }

.stream-handler .btn {
  margin: 0;
  margin-left: 0.5rem; }

.stream-handler span i {
  margin-right: 0.15rem; }

.stream-handler__stop {
  position: relative; }

.stream-handler__stop::after {
  content: '';
  border-top: 1px solid var(--button-color-secondary);
  transform: rotate(45deg);
  display: block;
  width: 100%;
  position: absolute;
  top: 0.3rem;
  left: -0.05rem; }

.stream-handler__channel__dropdown {
  display: inline-block; }

.stream-handler__channel__dropdown li.stream-handler__channel__dropdown__header {
  padding: 2.5px 5px; }

.stream-handler__channel__dropdown li.stream-handler__channel__dropdown__header p {
  margin: 0; }

.stream-handler__channel__dropdown > .btn {
  margin-left: 0; }

.stream-handler__channel__selected {
  color: var(--brand-color-primary); }

@media screen and (min-width: 480px) {
  .stream-player {
    height: 325px; } }

@media screen and (min-width: 768px) {
  .stream-player {
    height: 400px; } }

@media screen and (max-width: 768px) {
  .stream-handler span i {
    margin-right: 0; }
  .stream-handler__button-text {
    display: none; } }

/* Stream end*/
.skin-toggler__label {
  text-transform: capitalize; }

/* new alerts base css, can be removed from bootstrap */
.alert {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  color: #333;
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 1; }

.alert-na {
  background-color: rgba(255, 255, 255, 0.9);
  border: 3px solid var(--brand-color-primary);
  margin: 1rem; }

.alert-na i {
  color: #000;
  margin-right: 0.5rem;
  position: relative;
  top: 2px; }

.alert-na span {
  font-size: 0.85rem; }

.inner-padding-alerts {
  padding: 20px; }

/* loading animation */
.loading {
  border-top: 1.1em solid rgba(255, 255, 255, 0.25);
  border-right: 1.1em solid rgba(255, 255, 255, 0.25);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.25);
  border-left: 1.1em solid var(--brand-color-primary);
  height: 45px;
  width: 45px;
  margin: auto;
  font-size: 10px;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear; }

.loading,
.loading:after {
  border-radius: 50%; }

.loading div {
  display: none; }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loading-live {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background-color: var(--background-color-primary);
  transition: all 200ms ease;
  opacity: 0; }

.loading-live.active {
  opacity: 1; }

.square {
  position: absolute;
  height: 20px;
  width: 20px;
  background: #fc3;
  opacity: 0.2;
  animation: fadeIn 2s infinite; }

.state1a {
  top: 0;
  left: 0; }

.state2a {
  top: 0;
  left: 25px;
  animation-delay: 500ms; }

.state3a {
  top: 25px;
  left: 25px;
  animation-delay: 1000ms; }

.state4a {
  top: 25px;
  left: 0;
  animation-delay: 1500ms; }

@keyframes fadeIn {
  0% {
    opacity: 0.2; }
  10% {
    opacity: 1; } }

.scroller {
  white-space: pre-line;
  height: 100px;
  overflow-y: hidden;
  position: relative; }

.fa.fa-angle-down {
  transition: transform 200ms ease-in-out; }

.fa-rotate-neg-90 {
  filter: none;
  transform: rotate(-90deg); }

/* States */
.-hidden {
  display: none; }

/* --------------------------------------------- */
/* Extra flags, not included in the flag library */
/* --------------------------------------------- */
/* Kosovo */
.flag-icon-xk {
  background-image: url(../img/extraFlags/kosovo-xk.svg); }

/* International */
/* Public domain */
/* https://commons.wikimedia.org/wiki/File%3AInternational_Flag_of_Planet_Earth.svg */
.flag-icon-ii {
  background-image: url(../img/extraFlags/international-ii.svg); }

.flag-icon-es-virtual {
  background-image: url(../img/extraFlags/international-ii.svg); }

/* Temporary until https://github.com/maxlibin/lite-flag-icon-css/pull/5 is merged */
.flag-icon-cr {
  background-image: url(../img/extraFlags/costa-rica-cr.svg); }

/* E-sport icons */
.flag-icon-es-dota2 {
  background-image: url(../img/sportIcons/eSport/dota2.svg); }

.flag-icon-es-nba2k {
  background-image: url(../img/sportIcons/eSport/nba2k.svg); }

.flag-icon-es-fifa {
  background-image: url(../img/sportIcons/eSport/fifa.svg); }

.flag-icon-es-cs {
  background-image: url(../img/sportIcons/eSport/counter-strike.svg); }

.flag-icon-es-lol {
  background-image: url(../img/sportIcons/eSport/league-of-legends.svg); }

.flag-icon-es-ow {
  background-image: url(../img/sportIcons/eSport/overwatch.svg); }

.flag-icon-es-sc {
  background-image: url(../img/sportIcons/eSport/starcraft.svg); }

.flag-icon-es-rl {
  background-image: url(../img/sportIcons/eSport/rocket-league.svg); }

.flag-icon-es-nhl {
  background-image: url(../img/sportIcons/eSport/nhl.svg); }

.flag-icon-es-r6 {
  background-image: url(../img/sportIcons/eSport/rainbow6.svg);
  height: 24px; }

.flag-icon-es-sm {
  background-image: url(../img/sportIcons/eSport/smite.png);
  height: 20px; }

.flag-icon-es-kog {
  background-image: url(../img/sportIcons/eSport/king-of-glory.png);
  height: 20px; }

.flag-icon-es-bs {
  background-image: url(../img/sportIcons/eSport/brawl-stars.png);
  height: 18px; }

.flag-icon-es-cr {
  background-image: url(../img/sportIcons/eSport/clash-royale.png);
  height: 18px; }

.flag-icon-es-av {
  background-image: url(../img/sportIcons/eSport/arena-of-valor.png);
  height: 18px; }

.flag-icon-es-ra {
  background-image: url(../img/sportIcons/eSport/red-alert.png);
  height: 16px; }

.flag-icon-es-aoe {
  background-image: url(../img/sportIcons/eSport/age-of-empires.png);
  height: 14px; }

.flag-icon-es-pes {
  background-image: url(../img/sportIcons/eSport/pro-evolution-soccer.png);
  height: 16px; }

.flag-icon-es-cod {
  background-image: url(../img/sportIcons/eSport/call-of-duty.png);
  height: 17px; }

.flag-icon-es-spike {
  background-image: url(../img/sportIcons/eSport/spike-volleyball.png);
  height: 15px; }

.flag-icon-es-wow {
  background-image: url(../img/sportIcons/eSport/world-of-warcraft.png);
  height: 15px; }

.flag-icon-es-hst {
  background-image: url(../img/sportIcons/eSport/hearthstone.png);
  height: 15px; }

.flag-icon-es-hots {
  background-image: url(../img/sportIcons/eSport/heroes-of-the-storm.png);
  height: 15px; }

.flag-icon-es-ut {
  background-image: url(../img/sportIcons/eSport/unreal.png);
  height: 15px; }

.flag-icon-es-val {
  background-image: url(../img/sportIcons/eSport/valorant.png);
  height: 10px; }

.flag-icon-es-mlb {
  background-image: url(../img/sportIcons/eSport/mlb.png);
  height: 14px; }

.flag-icon-es-madden {
  background-image: url(../img/sportIcons/eSport/madden.png);
  height: 13px; }

.flag-icon-es-aot {
  background-image: url(../img/sportIcons/eSport/ao-tennis.png);
  height: 16px; }

/* Motor Racing icons */
.flag-icon-r-nascar {
  background-image: url(../img/sportIcons/motorRacing/nascar.svg); }

.flag-icon-r-f1 {
  background-image: url(../img/sportIcons/motorRacing/f1.svg);
  background-size: cover;
  height: 16px; }

.flag-icon-r-motorbikes {
  background-image: url(../img/sportIcons/motorRacing/motorbikes.svg); }

.flag-icon-r-supercars {
  background-image: url(../img/sportIcons/motorRacing/supercars.svg); }

/* Golf icons */
.flag-icon-g-pga {
  background-image: url(../img/sportIcons/golf/pga.svg); }

.flag-icon-g-lpga {
  background-image: url(../img/sportIcons/golf/lpga.svg); }

.flag-icon-g-euro {
  background-image: url(../img/sportIcons/golf/euro-tour.svg); }

.flag-icon-g-seniors {
  background-image: url(../img/sportIcons/golf/pga.svg); }

.flag-icon-g-majors {
  background-image: url(../img/sportIcons/golf/pga.svg); }

.flag-icon-g-korn-ferry {
  background-image: url(../img/sportIcons/golf/korn-ferry.svg); }

.flag-icon-g-lpga-korea {
  background-image: url(../img/sportIcons/golf/lpga-korea.png); }

.flag-icon-g-challenge {
  background-image: url(../img/sportIcons/golf/euro-tour.svg); }

.flag-icon-g-other {
  background-image: url(../img/sportIcons/golf/other-golf.svg); }

/* Tennis icons */
.flag-icon-t-atp {
  background-image: url(../img/sportIcons/tennis/atp.svg); }

.flag-icon-t-atp-challengers {
  background-image: url(../img/sportIcons/tennis/atp-challengers.svg); }

.flag-icon-t-atp-challengers-mixed-doubles {
  background-image: url(../img/sportIcons/tennis/atp-challengers.svg); }

.flag-icon-t-wta {
  background-image: url(../img/sportIcons/tennis/wta.svg); }

.flag-icon-t-grand-slam {
  background-image: url(../img/sportIcons/tennis/grand-slam.svg); }

.flag-icon-t-itf {
  background-image: url(../img/sportIcons/tennis/itf.svg); }

.flag-icon-t-atp-qualification {
  background-image: url(../img/sportIcons/tennis/qualification.svg); }

.flag-icon-t-davis-cup {
  background-image: url(../img/sportIcons/tennis/davis-cup.svg); }

.flag-icon-t-fed-cup {
  background-image: url(../img/sportIcons/tennis/fed-cup.svg); }

.flag-icon-t-laver-cup {
  background-image: url(../img/sportIcons/tennis/laver-cup.svg); }

.flag-icon-t-exhibition {
  background-image: url(../img/sportIcons/tennis/exhibition.svg); }

.flag-icon-t-lta {
  background-image: url(../img/sportIcons/tennis/lta.svg); }

/* Entertainment icons */
.flag-icon-ent-aa {
  background-image: url(../img/sportIcons/entertainment/ent-aa.svg); }

.flag-icon-ent-cca {
  background-image: url(../img/sportIcons/entertainment/ent-cca.svg); }

.flag-icon-ent-ga {
  background-image: url(../img/sportIcons/entertainment/ent-ga.svg); }

.flag-icon-ent-gg {
  background-image: url(../img/sportIcons/entertainment/ent-gg.svg); }

.flag-icon-ent-sag {
  background-image: url(../img/sportIcons/entertainment/ent-sag.svg); }

.flag-icon-ent-ta {
  background-image: url(../img/sportIcons/entertainment/ent-ta.svg); }

.flag-icon-ent-wgaw {
  background-image: url(../img/sportIcons/entertainment/ent-wgaw.svg); }

.flag-icon-ent-isa {
  background-image: url(../img/sportIcons/entertainment/ent-isa.svg); }

/* fighting icons */
.flag-icon-f-boxing {
  background-image: url(../img/sportIcons/fighting/boxing.svg); }

.flag-icon-f-mma {
  background-image: url(../img/sportIcons/fighting/mma.svg); }

/* Specific media queries for mobile devices */
@media (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 640px), (min-resolution: 192dpi) and (max-device-width: 640px) {
  .panel .panel-heading {
    height: 36px; }
  .main-top-controls {
    padding: 0.5rem 0.75rem; }
  .main-top-controls i {
    font-size: 24px; } }

/* Utilities */
.capitalize {
  text-transform: capitalize; }

/* Flaticon sport icon css generated patches*/
.sports-menu [class^='flaticon-'],
.sports-menu [class*=' flaticon-'],
.time-range-selector [class^='flaticon-'],
.time-range-selector [class*=' flaticon-'] {
  color: var(--brand-color-primary); }

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-size: var(--sport-icon__font-size);
  margin-left: 0; }

.panel-heading [class^='flaticon-']:before,
.panel-heading [class*=' flaticon-']:before,
.panel-heading [class^='flaticon-']:after,
.panel-heading [class*=' flaticon-']:after {
  --sport-icon__font-size: 16px;
  color: var(--font-color-secondary);
  margin-right: 0.4rem; }

.sports-menu__item-link:hover > [class^='flaticon-']:before,
.sports-menu__item-link:hover > [class*=' flaticon-']:before {
  color: var(--font-color-secondary); }

.sports-menu__item-link > [class^='flaticon-']:before,
.sports-menu__item-link > [class*=' flaticon-']:before {
  --sport-icon__font-size: 18px; }

.active .sports-menu__item-link:hover > [class^='flaticon-']:before,
.active .sports-menu__item-link:hover > [class*=' flaticon-']:before {
  color: var(--brand-color-primary); }

.flaticon-college-basketball:before {
  --sport-icon__font-size: 16px;
  top: 2px;
  position: relative; }

.flaticon-boxing:before {
  content: '\f108'; }

.flaticon-australian:before,
.flaticon-australian-rules:before {
  content: '\f106'; }

.flaticon-rugby-union:before,
.flaticon-rugby-league:before {
  content: '\f105'; }

.flaticon-rugby-union:after {
  content: 'U';
  font-family: sans-serif !important;
  --sport-icon__font-size: 12px;
  font-weight: bold;
  display: inline-block;
  color: var(--font-color-secondary);
  position: absolute;
  top: 6px;
  left: 5px; }

.flaticon-rugby-league:after {
  content: 'L';
  font-family: sans-serif !important;
  --sport-icon__font-size: 12px;
  font-weight: bold;
  display: inline-block;
  color: var(--font-color-secondary);
  position: absolute;
  top: 5px;
  left: 5px; }

.sports-menu__item-link:hover .flaticon-rugby-union:after,
.sports-menu__item-link:hover .flaticon-rugby-league:after {
  color: var(--brand-color-primary); }

.active .sports-menu__item-link:hover .flaticon-rugby-union:after,
.active .sports-menu__item-link:hover .flaticon-rugby-league:after {
  color: var(--font-color-secondary); }

/* E sports SVG icons */
.e-sports-icon {
  display: flex;
  width: 24px;
  align-items: center;
  height: 30px; }

.partner-content-container {
  width: 100%;
  display: flex; }

.partner-content-container > iframe {
  border: 0;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center; }

div[partner-content-location='main-menu'] > .partner-content-container > iframe {
  width: 930px; }

.horizontal.clearfix {
  min-width: 100px; }

@media only screen and (max-width: 960px) and (min-width: 590px) {
  .event-summary-details {
    display: block;
    position: static; }
  .event-summary-details__teams {
    /*Using IMPORTANT until inline style is fixed*/
    width: calc(75% - 66px) !important; } }

/* Bet-slip animation overwrite*/
.bet-list .panel,
.bet-list .panel .panel-heading,
.bet-list .panel .btn-primary {
  transition: filter 0.4s ease-in, transform 0.4s ease-in; }

.bet-list .panel.-bet-added {
  transform: scale(1.0125);
  filter: brightness(1.3);
  transition: border-color 0.4s ease-in, transform 0.4s ease-in; }

.bet-list .panel.-bet-added .panel-heading,
.bet-list .panel.-bet-added .btn-primary {
  transition: filter 0.4s ease-in, transform 0.4s ease-in; }

/* Dropdown */
.gs-dropdown {
  position: relative;
  z-index: 999; }

.gs-dropdown__items {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  position: absolute;
  top: 100%;
  width: 100%; }

.gs-dropdown__chevron {
  fill: white; }

.gs-dropdown__selected-item {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left; }

.gs-dropdown-item.sports-menu__sub-item-link {
  background-color: transparent;
  justify-content: flex-start; }

.gs-dropdown__items--open {
  opacity: 1;
  pointer-events: all; }

.gs-dropdown-item {
  cursor: pointer;
  text-transform: capitalize; }

.gs-dropdown-item--selected {
  width: 100%; }

.gs-dropdown__items .gs-dropdown-item.gs-dropdown-item--selected {
  color: var(--brand-color-primary); }

.time-range-selector .gs-dropdown__items {
  top: 1.9rem;
  left: -1rem;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color-secondary); }

.time-range-selector .gs-dropdown__items--open {
  border: 1px solid var(--button-color-secondary);
  background-color: var(--background-color-secondary); }

.time-range-selector .gs-dropdown-item {
  color: var(--font-color-primary);
  display: flex;
  justify-content: flex-start;
  align-items: center; }

/* Multi event view - Event list */
:root {
  --event-list--color: white;
  --event-list--border: none;
  --event-list--font-size: 0.875rem;
  --event-list__header--color: inherit;
  --event-list__footer--color: inherit;
  --event-list__header--background-color: var(--background-color-secondary);
  --event-list__footer--background-color: var(--background-color-secondary);
  --event-list__header--border-top: 1px solid var(--background-color-tertiary);
  --event-list__footer--border-top: 1px solid var(--background-color-tertiary);
  --event-list__item-odd--background-color: var(--background-color-lighten);
  --event-list__item-even--background-color: var(--background-color-secondary);
  --event-list__footer__extra--color: var(--highlight-color-primary);
  --event-scores--font-size: 0.625rem;
  --event-scores--color: inherit;
  --event-scores__emphasis--color: var(--highlight-color-primary);
  --event-scores__emphasis__font-weight: normal;
  --event-scores__team-period--background-color: var(--background-color-primary);
  --event-scores__team-period--border: 1px solid var(--background-color-secondary);
  --event-scores__team-period-odd--border: var(--event-scores__team-period--border);
  --event-scores__team-period-even--border: var(--event-scores__team-period--border);
  --event-scores__team-period--border-radius: 3px;
  --event-list__item__details__teams__team--color: inherit;
  --event-list__item__details__date__live-period--color: inherit;
  --event-list__item__details__more--width: 35px; }

.event-list {
  border: var(--event-list--border);
  background-color: var(--background-color-lighten);
  display: flex;
  flex-direction: column;
  color: var(--event-list--color); }

.event-list__header,
.event-list__footer {
  display: flex;
  align-items: center; }

.event-list__header {
  background-color: var(--event-list__header--background-color);
  color: var(--event-list__header--color);
  border-top: var(--event-list__header--border-top); }

.event-list__footer {
  background-color: var(--event-list__footer--background-color);
  color: var(--event-list__footer--color);
  border-top: var(--event-list__footer--border-top); }

.event-list__header a,
.event-list__footer a,
.event-list__header a:hover,
.event-list__footer a:hover {
  color: inherit; }

.event-list__header__desc,
.event-list__footer {
  flex-grow: 1;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  overflow: hidden; }

.event-list__header__desc .flag-icon {
  background-size: cover; }

.event-list__header__desc > span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; }

.event-list__item {
  display: flex;
  padding: 3px 0; }

.event-list-item--circled .event-list__item__details {
  background-color: var(--event-list__item__details__background-color--circled, transparent); }

.event-list__item__details__circled {
  padding: var(--event-list__item__details__circled__padding);
  font-size: var(--event-list__item__details__circled__font-size);
  text-transform: var(--event-list__item__details__circled__text-transform);
  border: var(--event-list__item__details__circled__border-width) solid var(--event-list__item__details__circled__border-color);
  background-color: var(--event-list__item__details__circled__background-color);
  border-radius: var(--event-list__item__details__circled__border-radius);
  color: var(--event-list__item__details__circled__color);
  margin: 0 0.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.event-list__item__details {
  flex-grow: 1;
  display: flex;
  padding-right: 5px;
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: center; }

@media screen and (max-width: 30rem) {
  .prematch .event-list__item.event-list__item--circled {
    position: relative;
    padding-top: 20px; }
  .event-list__item__details__circled {
    position: absolute;
    top: 5px;
    left: 0;
    margin: 0; } }

.event-list__item__details__teams {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  overflow: hidden; }

.event-list__item__details__teams__team {
  color: var(--event-list__item__details__teams__team--color);
  font-size: var(--event-list--font-size);
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.event-list__item__details__date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.75rem;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
  width: 4rem; }

.event-list__item__details__date p {
  color: var(--event-list__item__details__date__live-period--color);
  margin: 0; }

.event-list__header__more {
  width: var(--event-list__item__details__more--width);
  flex-shrink: 0; }

.event-list__item__details__more {
  width: var(--event-list__item__details__more--width);
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-evenly;
  flex-shrink: 0; }

.event-list__item__details__more a {
  color: var(--event-list__item__details__more--color); }

.event-list__item__details__more i {
  color: var(--event-list__item__details__more__icons--color); }

.event-list__item:nth-child(odd) {
  background-color: var(--event-list__item-odd--background-color); }

.event-list__item:nth-child(even) {
  background-color: var(--event-list__item-even--background-color); }

.event-list__footer__extra {
  color: var(--event-list__footer__extra--color); }

.event-list__footer__show-more {
  flex-grow: 1;
  text-align: right; }

.event-scores {
  display: grid;
  align-content: center;
  justify-items: center;
  font-size: var(--event-scores--font-size);
  line-height: var(--event-scores--font-size);
  color: var(--event-scores--color); }

.event-scores__team {
  display: flex; }

.event-list__item:nth-child(odd) .event-scores__team-period {
  border: var(--event-scores__team-period-odd--border); }

.event-list__item:nth-child(even) .event-scores__team-period {
  border: var(--event-scores__team-period-even--border); }

.event-scores__team-period {
  background-color: var(--event-scores__team-period--background-color);
  min-width: 15px;
  padding: 4px;
  text-align: center;
  border-radius: var(--event-scores__team-period--border-radius);
  flex-grow: 1;
  width: 100%; }

.event-scores__team-period.emphasis {
  color: var(--event-scores__emphasis--color);
  font-weight: var(--event-scores__emphasis__font-weight); }

/* EVENT LIST OFFERINGS */
.event-list .offerings,
.event-list .offerings-header {
  width: var(--offering-width);
  flex-shrink: 0; }

.event-list .offerings {
  display: flex;
  align-items: center; }

.event-list .offerings > div {
  width: 100%; }

.market--two-row {
  --offering-width: 80px; }

.market--three-col {
  --offering-width: 140px; }

.market-3,
.market-33 {
  --offering-width: 70px; }

.market-30,
.market-237 {
  --offering-width: 150px;
  --offering-description-width: 100px; }

.offerings-header.market-30,
.offerings-header.market-237 {
  /*
    Market ID === 30 is an outright market, since it's a single offering,
    we need the header to occupy the same amount of space as the lines
  */
  --offerings-to-show: 1;
  width: calc(var(--offering-width) * var(--offerings-to-show)); }

.offerings.market-30:nth-of-type(3),
.offerings.market-30:nth-of-type(2),
.offerings.market-237:nth-of-type(3),
.offerings.market-237:nth-of-type(2) {
  display: none; }

.offerings .link .odds-description {
  width: var(--offering-description-width); }

@media only screen and (min-width: 992px) {
  .offerings-header.market-237,
  .offerings-header.market-30 {
    --offerings-to-show: 3; }
  .market--two-row {
    --offering-width: 120px; }
  .market--three-col {
    --offering-width: 200px; }
  .market-3,
  .market-33 {
    --offering-width: 80px; }
  .market-237,
  .market-30 {
    --offering-width: 160px;
    --offering-description-width: unset; }
  .offerings.market-30:nth-of-type(3),
  .offerings.market-30:nth-of-type(2),
  .offerings.market-237:nth-of-type(3),
  .offerings.market-237:nth-of-type(2) {
    display: flex; } }

/* END EVENT LIST OFFERINGS */
@media only screen and (min-width: 992px) {
  :root {
    --event-list__item__details__more--width: 50px; } }

@media (max-width: 30rem) {
  .event-list__item__details__date {
    display: none; }
  .event-list__item__details__teams {
    --event-list--font-size: 0.8125rem;
    padding-left: 7px; } }

@media screen and (min-width: 30rem) {
  :root {
    --offerings--contest-type-columns: auto-fit; } }

.time-range-selector {
  display: contents; }

@media screen and (max-width: 30rem) {
  .time-range-selector .gs-dropdown__items .gs-dropdown-item {
    min-width: 140px;
    text-align: left;
    padding-left: 0.5rem; } }

.sidebar-left--horizontal {
  position: static;
  display: flex;
  align-items: flex-start;
  min-height: auto; }

.sidebar-left.sidebar-left--horizontal {
  width: 100%; }

.sidebar-left--horizontal .sports-menu {
  --sport-icon__font-size: 18px;
  display: flex;
  width: 100%;
  height: 60px;
  box-sizing: content-box;
  background-color: var(--background-color-tertiary);
  overflow-x: scroll;
  padding: 0; }

.sidebar-left--horizontal .sports-menu.sports-menu--top,
.sidebar-left--horizontal .sports-menu.sports-menu--favorite {
  padding-bottom: 0; }

.sidebar-left--horizontal .sports-menu::-webkit-scrollbar {
  display: none; }

.sidebar-left--horizontal .sports-menu__item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 0.85rem;
  border-left: none; }

.sidebar-left--horizontal .sports-menu__item.active .sports-menu__item-link {
  background-color: var(--brand-color-primary);
  color: var(--font-color-secondary); }

.sidebar-left--horizontal .sports-menu__item {
  border-bottom: none;
  --sport-icon__font-size: 20px; }

.sidebar-left--horizontal .sports-menu__item.active .sport-icon {
  filter: brightness(0); }

.sidebar-left--horizontal .sports-menu__item-link i {
  margin-right: 0; }

.sidebar-left--horizontal .sports-menu__sub-item-link {
  border-radius: 100px;
  margin: 0 0.25rem; }

.sidebar-left--horizontal .sports-menu__sub-item-link span {
  font-size: 0.75rem; }

.sidebar-left--horizontal .sports-menu__sub-item.active .sports-menu__sub-item-link {
  color: var(--button-default-color);
  background-color: var(--background-color-secondary);
  border-left: none; }

.sidebar-left--horizontal .sports-menu__item-link .count,
.sidebar-left--horizontal .sports-menu__sub-item-link .count {
  display: none; }

.sidebar-left--horizontal .sub-menu {
  position: absolute;
  left: 0;
  min-width: 100%;
  width: 100%;
  background-color: var(--background-color-tertiary);
  display: flex;
  height: 2.75rem;
  align-items: center;
  overflow-x: scroll; }

.sidebar-left--horizontal .sub-menu::-webkit-scrollbar {
  display: none; }

.sidebar-left--horizontal .sub-menu .sub-menu {
  display: none; }

.sidebar-left--horizontal .sports-menu__item {
  width: auto; }

.sidebar-left--horizontal .sports-menu__item .sub-menu--upcoming {
  left: 0;
  min-width: 100vw;
  display: flex;
  align-items: center;
  height: 40px;
  top: 3.75rem;
  z-index: 10;
  background-color: var(--background-color-primary); }

.sidebar-left--horizontal .sports-menu__item--upcoming .title {
  display: none; }

.sidebar-left--horizontal .sports-menu__item--upcoming .sports-menu__item-link {
  background-color: var(--background-color-secondary); }

.sidebar-left--horizontal .sports-menu__item .sub-menu--upcoming .sports-menu__sub-item-link {
  min-width: 8rem;
  justify-content: center; }

.sidebar-left--horizontal .sports-menu__item--upcoming .fa {
  display: none; }

.sidebar-left--horizontal .sports-menu__sub-item:not(:last-child) {
  border-bottom: none; }

.sidebar-left--horizontal .sports-menu__sub-item-link {
  padding: 0 0.85rem; }

.sidebar-left--horizontal .sports-menu--featured {
  margin-bottom: 0.25rem; }

.sports-menu__sub-item--all {
  display: none; }

.sidebar-left--horizontal .sports-menu__sub-item--all {
  display: inline; }

.sidebar-left--additional {
  z-index: 110; }

.sidebar-left--additional .sports-menu {
  height: calc(100vh - 42px); }

.minimized-left-sidebar .sidebar-left--additional {
  width: 0; }

.sidebar-left--horizontal .sidebar-top {
  display: none; }

.minimized-left-sidebar .wrapper {
  margin-left: 0; }

@media only screen and (max-width: 990px) {
  .sidebar-left--horizontal .sports-menu {
    padding-bottom: 2.75rem; } }

@media only screen and (max-width: 768px) {
  .sidebar-left {
    padding-top: 0px; }
  .wrapper,
  .minimized-left-sidebar .wrapper {
    margin-left: 0; }
  .live .live {
    position: relative;
    background-color: var(--background-color-primary);
    padding: 0px 5px 0px 15px;
    border-radius: 2px;
    display: flex;
    align-items: center; }
  .live .live::before {
    position: absolute;
    left: 0.3rem;
    content: ' ';
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: var(--brand-color-primary);
    animation: pulse 3s infinite ease; }
  .live .event-list__item__details__date {
    margin-right: 0.75rem;
    align-items: center; }
  @keyframes pulse {
    0% {
      opacity: 1; }
    20% {
      opacity: 0.2; }
    40% {
      opacity: 1; } }
  .overflow {
    z-index: 3; } }

body {
  font-family: var(--body__font-family); }

.bet-alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  background: var(--bet-alert__background-color);
  padding: 0 0.5rem; }
  .bet-alert + .bet-alert {
    border-top: 1px solid var(--bet-alert__border-top--border-color); }
  .bet-alert__text {
    font-size: 0.75rem;
    color: var(--bet-alert__text__color);
    margin-left: 0.75rem;
    line-height: 100%; }
    .bet-alert__text span {
      color: inherit;
      transition: color 0.3s;
      font-weight: bold;
      cursor: pointer; }
      .bet-alert__text span:hover {
        text-decoration: underline; }
  .bet-alert__icon {
    color: var(--bet-alert__icon__color);
    font-size: 0.9rem; }
  .bet-alert--info {
    --bet-alert__border-top--border-color: var(--bet-alert__border-top--border-color--info);
    --bet-alert__background-color: var(--bet-alert__background-color--info);
    --bet-alert__text__color: var(--bet-alert__text__color--info);
    --bet-alert__icon__color: var(--bet-alert__icon__color--info); }

.notification {
  position: absolute;
  z-index: 10000;
  width: 97.5%;
  margin: 0.75rem 1.25%;
  border-radius: 5px;
  box-sizing: border-box;
  top: 0;
  background-color: var(--alert__background-color);
  color: var(--alert__color);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.75); }
  .notification__content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem; }
    @media screen and (min-width: 768px) {
      .notification__content {
        padding: 1rem 1.5rem; } }
  .notification__icon {
    color: var(--alert__icon__color);
    margin-right: 0.25rem;
    font-size: 1.2rem; }
    @media screen and (min-width: 768px) {
      .notification__icon {
        margin-right: 0.5rem; } }
  .notification__text {
    margin: 0 0.75rem; }
    @media screen and (min-width: 768px) {
      .notification__text {
        margin: 0 1.5rem; } }
  .notification__dismiss {
    right: 0;
    padding: 0.5rem;
    appearance: none;
    background-color: transparent;
    border: transparent;
    outline: none;
    font-size: 1.2rem; }
    @media screen and (min-width: 768px) {
      .notification__dismiss {
        position: absolute;
        padding: 0.5rem 2rem; } }
  .notification--warning {
    --alert__background-color: var(--alert__background-color--warning);
    --alert__icon__color: var(--alert__icon__color--warning);
    --alert__color: var(--alert__color--warning); }
  .notification--info {
    --alert__background-color: var(--alert__background-color--info);
    --alert__icon__color: var(--alert__icon__color--info);
    --alert__color: var(--alert__color--info); }
  .notification --error {
    --alert__background-color: var(--alert__background-color--error);
    --alert__icon__color: var(--alert__icon__color--error);
    --alert__color: var(--alert__color--error); }

.bet--tentatively-accepted--addon {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; }

.bet-state-badge {
  padding: 0.15rem 0.25rem;
  border-radius: 3px;
  flex-shrink: 0;
  display: inline-block; }
  .bet-state-badge i {
    margin-right: 0.25rem; }
  .bet-state-badge--tentatively-accepted {
    background-color: #448be0;
    color: white; }

.bet .countdown-timer {
  text-align: right; }

.bet-slip__notification__tentatively-accepted-bets {
  display: flex;
  align-items: center;
  background-color: #d3e4f8;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  color: var(--bet-slip__notification__tentatively-accepted__color);
  margin-bottom: 1rem;
  line-height: 1.2; }
  .bet-slip__notification__tentatively-accepted-bets i {
    color: #448be0;
    margin-right: 0.5rem; }
  .bet-slip__notification__tentatively-accepted-bets span[role='link'] {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold; }

.my-bets-bet__extras {
  margin-bottom: 0.5rem;
  line-height: 1; }
  .my-bets-bet__extras__tentatively-accepted {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
  .my-bets-bet__extras .bet-state-badge {
    padding: 0.15rem 0.25rem;
    border-radius: 3px;
    flex-shrink: 0;
    display: inline-block;
    font-size: 0.8rem; }
    .my-bets-bet__extras .bet-state-badge i {
      margin-right: 0.25rem; }
    .my-bets-bet__extras .bet-state-badge--tentatively-accepted {
      background-color: #448be0;
      color: white; }
  .my-bets-bet__extras .countdown-timer {
    text-align: right; }
  .my-bets-bet__extras .tentatively-accepted-timer {
    text-align: right; }

body[class*='production'] .bet--cashout, body[class*='production']
.my-bets--action-bar, body[class*='production']
.history-table tr > *:nth-child(8) {
  display: none !important; }
